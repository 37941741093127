.m19 {
  &.show-right-side {
    padding-top: 50px;
    .m19-quote {
      padding-left: 170px;
      @include mobile {
        padding-left: 0;
      }
      @include tablet {
        padding-left: 50px;
      }
      &-left {
        width: 760px;
        @include mobile {
          width: 100%;
        }
      }
      &-text {
        max-width: 600px;
        width: 100%;
        @include mobile {
          max-width: 100%;
        }
        &:after {
          bottom: 35px;
          right: -53px;
          @include mobile {
            top: -30px;
            bottom: unset;
          }
        }
      }
      &-author {
        bottom: 0;
        p {
          left: 0;
        }
      }
    }
  }
  &-quote {
    position: relative;
    padding: 20px 0 40px 0;

    > .row {
      align-items: flex-end;
      display: flex;

      @include tablet {
        align-items: inherit;
        display: inherit;
      }

      @include mobile {
        align-items: inherit;
        display: inherit;
      }
    }

    &-text {
      position: relative;
      max-width: 572px;
      text-align: justify;

      &:after {
        content: '';
        width: 37px;
        height: 20px;
        display: inline-block;
        background: url('./images/quotationsmarks.svg') no-repeat center;
        background-size: cover;
        position: absolute;
        right: -40px;
        bottom: 10px;
      }
      p {
        max-width: 100%;
        font-size: 22px;
        font-weight: normal;
        font-style: italic;
        line-height: em(36, 22);
        padding: 0;
      }

      @include mobile {
        max-width: 100%;

        &:after {
          right: inherit;
          bottom: inherit;
          top: -20px;
          left: 0;
        }
      }

      @include tablet {
        max-width: 100%;

        &:after {
          right: inherit;
          bottom: inherit;
          top: -20px;
          left: 0;
        }
      }
    }

    &-author {
      position: relative;
      bottom: -30px;
      p {
        max-width: 100%;
        text-align: left;
        margin: 0;
        left: -30px;
        position: relative;
        padding: 0 !important;

        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
      }

      @include mobile {
        bottom: 0;
        p {
          left: 0;
        }
      }

      @include tablet {
        bottom: 0;
        p {
          left: 0;
        }
      }
    }
  }
}
