body {
  font-family: 'Noah Text';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.001em;
}
a {
  text-decoration: none;
  outline: 0;
  &:hover {
    text-decoration: inherit;
    color: inherit;
  }
}
.main_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.align-start {
    align-items: flex-start;
  }
  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
  @include tablet {
    flex-direction: column;
    align-items: flex-start;
  }
  &__right {
    @include mobile {
      margin-top: 24px;
    }
    @include tablet {
      margin-top: 24px;
    }
  }
  span {
    font-size: 12px;
    color: #747474;
    line-height: em(16, 12);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    line-height: em(54, 40);
    letter-spacing: -0.1px;
    color: #1b1b1b;
    margin-bottom: 0;
    @include mobile {
      font-size: 30px;
      line-height: em(42, 30);
    }
    @include mobile {
      font-size: 32px;
      line-height: em(42, 32);
    }
  }
}
.btn_link_with_arrow {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  line-height: em(35, 16);
  letter-spacing: 0.3px;
  color: #2a6ebb;
  display: flex;
  align-items: center;
  @include mobile {
    font-size: 15px;
    line-height: em(33, 15);
  }
  &:hover {
    color: #2a6ebb;
    &:before {
      margin-right: 8px;
    }
  }
  &:before {
    content: '';
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 12px;
    border: 1px solid #2a6ebb;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
  }
}

.text-link {
  display: inline-block;
  font-weight: 800;
  font-size: 15px;
  line-height: em(16, 15);
  color: #2a6ebb;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 3px;
  border-bottom: 2px solid #2a6ebb;
  transition: $transition1;
  &:hover {
    color: #0d1e5a;
    border-color: #0d1e5a;
  }
}

// radio
/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: em(24, 16);
  font-weight: 500;

  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #000000;
}

// /* On mouse-over, add a grey background color */
// .radio-container:hover input ~ .checkmark {
//   // background-color: #ccc;
// }

// /* When the radio button is checked, add a blue background */
// .radio-container input:checked ~ .checkmark {
//   // background-color: #2196f3;
// }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000000;
}

// input

.form-group,
.gfield {
  position: relative;
  margin-bottom: 10px;

  &--error {
    .form-input {
      border-bottom-color: #ef4a4a;
    }

    .form-feedback {
      display: inherit;
    }
  }

  &.gfield_error {
    select,
    textarea,
    input {
      border-bottom-color: #ea0f0f;
      // color: #ea0f0f;
      // @include placeholder {
      //   color: #ea0f0f;
      // }
    }

    .validation_message {
      display: inherit;
    }
  }
}

.form-feedback,
.validation_message {
  position: absolute;
  display: none;
  left: 0;
  margin-top: 5px;
  color: #ef4a4a;
  font-size: 12px;
  font-weight: 500;
  line-height: em(18, 12);
  letter-spacing: 0.01px;
}

.form-input,
.ginput_container input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #959595;
  font-size: 16px;
  line-height: em(24, 16);
  font-weight: 500;

  @include placeholder {
    font-size: 16px;
    line-height: em(24, 16);
    font-weight: 500;
    color: #959595;
  }

  &:focus {
    outline: none;
  }
}

.form-select {
  font-size: 16px;
  line-height: em(24, 16);
  font-weight: 800;
  color: #959595;

  padding-right: 26px;

  background-image: url('./images/icon-down.svg');
  background-position: calc(100% - 5px);
  background-size: 15px;
  background-repeat: no-repeat;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.form-textarea {
  min-height: 141px;
}

.file-uploader {
  &__dropzone {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #959595;
    border-radius: 8px;

    &--error {
      border-color: #ef4a4a;
      .file-uploader__dropzone__feedback {
        display: inherit;
      }
    }

    p {
      font-size: 16px;
      line-height: em(24, 16);
      font-weight: 800;
      color: #959595;
      background-image: url('./images/upload_icon.svg');
      background-position: left;
      background-size: 21px;
      background-repeat: no-repeat;
      padding-left: 36px;
    }

    a {
      color: #2a6ebb;
      &:hover {
        color: #2363ac;
      }
    }

    &__feedback {
      display: none;
      position: absolute;
      left: 0;
      bottom: -20px;
      margin-top: 5px;
      color: #ef4a4a;
      font-size: 12px;
      font-weight: 500;
      line-height: em(18, 12);
      letter-spacing: 0.01px;
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
  }
}
.ginput_preview,
.file-uploader__file {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  min-height: 34px;
  padding-left: 40px;
  margin-bottom: 28px;
  border-bottom: 2px solid #0d1e5a;
  font-weight: 500;

  background-image: url('./images/file-icon.svg');
  background-position: 0 0;
  background-size: 22px;
  background-repeat: no-repeat;
  a {
    display: none;
  }
  b {
    display: none;
  }
  strong {
    font-weight: bold;
    margin-bottom: 2px;
  }
  img {
    cursor: pointer;
  }

  &--error {
    margin-bottom: 34px;
    .file-uploader__file__progress {
      background: #ef4a4a;
    }
    .file-uploader__file__feedback {
      display: inherit;
    }
  }

  &--uploaded {
    .file-uploader__file__progress {
      background: #0d1e5a;
    }
  }

  &--uploading {
    .file-uploader__file__progress {
      background: #ffca00;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    color: #0d1e5a;
    line-height: em(24, 16);
    margin-bottom: 7px;
  }

  &__size {
    font-weight: 800;
    font-size: 16px;
    color: #959595;
    line-height: em(24, 16);
    margin-left: 24px;
    margin-bottom: 7px;
  }

  &__progress {
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: #dcdcdc;
  }

  &__feedback {
    display: none;
    position: absolute;
    left: 0;
    bottom: -20px;
    margin-top: 5px;
    color: #ef4a4a;
    font-size: 12px;
    line-height: em(18, 12);
    letter-spacing: 0.01px;
  }
  .gform_delete,
  &__remove {
    content: url('./images/file-remove.svg');
    position: absolute;
    right: 0;
    bottom: 8px;
    width: 21px;
    height: 21px;
    cursor: pointer;

    background-image: url('./images/file-remove.svg');
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;

    &:hover {
      opacity: 0.8;
    }
  }
}
.article {
  &_header {
    width: 100%;
    &__inner {
      width: 100%;
      height: 470px;
      background: #2a6ebb;
      display: flex;
      align-items: flex-end;
      padding-bottom: 45px;
      @include mobile {
        padding-bottom: 40px;
        height: 330px;
      }
      @include tablet {
        height: 330px;
      }
    }
    h1 {
      max-width: 970px;
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 32px;
      line-height: em(42, 32);
      color: #fff;
      @include mobile {
        font-size: 26px;
        line-height: em(38, 26);
      }
    }
    &__bottom {
      width: 100%;
      margin-top: 100px;
      overflow: hidden;
      @include mobile {
        margin-top: 60px;
      }
      @include tablet {
        margin-top: 80px;
      }
      .container {
        display: flex;
        align-items: flex-start;
        @include mobile {
          flex-direction: column-reverse;
        }
        @include tablet {
          flex-direction: column-reverse;
        }
      }
    }
    p {
      width: calc(100% - 200px);
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.05px;
      color: #000000;
      padding-right: 100px;
      @include mobile {
        width: 100%;
        padding-right: 0;
        font-size: 22px;
        line-height: em(32, 22);
      }
      @include tablet {
        width: 100%;
        padding-right: 0;
      }
    }
    &__info {
      width: 200px;
      padding: 24px 16px;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      position: relative;
      @include mobile {
        width: 100%;
        background-color: transparent;
        margin-bottom: 40px;
        padding: 0;
      }
      @include tablet {
        width: 100%;
        background-color: transparent;
        flex-direction: row;
        padding: 0;
        margin-bottom: 40px;
      }
      span {
        padding-left: 26px;
        font-size: 12px;
        font-weight: 500;
        line-height: em(18, 12);
        background-repeat: no-repeat;
        background-position: left center;

        &.date {
          color: #959595;
          background-image: url('./images/icon-calendar-gray.svg');
          background-size: 16px;
        }
        &.category {
          color: #2a6ebb;
          margin-top: 17px;
          background-image: url('./images/icon-medicin.svg');
          background-size: 13px;
          // @include mobile {
          //   margin-top: 0;
          //   border-left: 1px solid #959595;
          //   margin-left: 18px;
          //   padding-left: 38px;
          //   background-position: 15px center;
          // }
          @include tablet {
            margin-top: 0;
            border-left: 1px solid #959595;
            margin-left: 18px;
            padding-left: 38px;
            background-position: 15px center;
          }
        }
      }
      &:after {
        content: '';
        background-color: #f5f5f5;
        height: 100%;
        width: 100vw !important;
        position: absolute;
        left: 100%;
        max-width: none;
        z-index: 3;
        top: 0;
        @include mobile {
          display: none;
        }
      }
    }
  }
  &_main {
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      padding: 0 16px;

      //===
      max-width: 670px;
      margin: 20px auto;

      @include tablet {
        max-width: 400px;
      }

      @include mobile {
        max-width: 100%;
        text-align: left;
      }
      a {
        font-weight: 600;
        text-decoration: underline;
        color: #2363ac;
      }
    }
  }
}

.filters {
  width: 100%;
  display: flex;
  color: hsl(212, 63%, 45%);
  &.is--open {
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    .filters_modal {
      opacity: 1;
      visibility: visible;
      overflow: visible;
    }
  }
  &_inner {
    width: 100%;
    position: relative;
    display: flex;
    @include mobile {
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  &__btn {
    font-size: 20px;
    line-height: em(30, 20);
    font-weight: 500;
    padding-right: 20px;
    margin-right: 40px;
    cursor: pointer;
    background-image: url('./images/icon-arrow-down-gray.svg');
    background-repeat: no-repeat;
    background-position: right center;
    @include mobile {
      margin-right: 15px;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    @include mobile {
      width: calc(100% - 90px);
      flex-wrap: wrap;
    }
    span {
      display: flex;
      border: 1px solid #2a6ebb;
      border-radius: 16px;
      padding: 8px 16px 8px 33px;
      font-size: 16px;
      font-weight: 500;
      line-height: 0.91em;
      margin-right: 24px;
      transition: $transition1;
      background-image: url('./images/icon-close-blue.svg');
      background-repeat: no-repeat;
      background-position: 13px;
      cursor: pointer;
      @include mobile {
        margin-bottom: 10px;
        margin-right: 15px;
      }
      &:hover {
        background-image: url('./images/icon-close-white.svg');
        background-color: #2a6ebb;
        color: #fff;
      }
    }
  }
  &_modal {
    position: absolute;
    left: 0;
    top: calc(100% + 22px);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 47px 40px 40px;
    border-radius: 8px;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    @include mobile {
      width: 100%;
      padding: 40px 20px 40px;
    }
    &_wrapper {
      display: flex;
      @include mobile {
        flex-wrap: wrap;
      }
      @include tablet {
        flex-wrap: wrap;
      }
    }
    &__close {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 13px;
      top: 13px;
      background: url('./images/icon-close-blue.svg') no-repeat;
      cursor: pointer;
      z-index: 5;
    }
    &__item {
      margin-right: 40px;
      margin-bottom: 26px;
      &:last-of-type {
        margin-right: 0;
        .form-group-custom {
          padding-right: 0;
          border-right: 0;
        }
      }
      h5 {
        display: inline-block;
        font-size: 18px;
        line-height: em(24, 18);
        font-weight: bold;
        letter-spacing: 0.05px;
        color: #000;
      }
    }
  }
}

.form-group-custom {
  min-height: calc(100% - 40px);
  position: relative;
  margin-top: 18px;
  border-right: 2px solid #dcdcdc;
  padding-right: 40px;
  @include mobile {
    padding-right: 0;
    border-right: 0;
  }
  .checkbox_btns {
    display: flex;
    flex-direction: column;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    input[type='checkbox'] {
      display: none;
      &:disabled {
        ~ label {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
      &:checked {
        ~ label {
          color: #2a6ebb;
          font-weight: bold;
          &:before {
            border-color: #2a6ebb;
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
  .label-checkbox {
    font-size: 20px;
    font-weight: 500;
    line-height: em(34, 20);
    color: #000000;

    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: $transition1;
    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 2px solid #505050;
      margin-right: 16px;
    }
    &:after {
      content: '';
      opacity: 0;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: #2a6ebb;
      position: absolute;
      left: 6px;
    }
  }
}

.article_content {
  color: #000000;
  font-size: 16px;
  line-height: em(24, 16);
  font-weight: 500;

  p {
    margin-bottom: 25px;
  }

  figcaption {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 1.32em;
  }

  ul {
    list-style: none;
    padding-left: 40px;
    margin-bottom: 20px;
    li {
      margin-bottom: 5px;
      position: relative;
      &:before {
        content: '';
        background-color: #2a6ebb;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        position: absolute;
        left: -23px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  table {
    margin: 0 auto;
    margin-top: 20px;
    border-left: 1px solid #ebf0f3;
    border-top: 1px solid #ebf0f3;
  }

  th,
  td {
    padding: 10px 15px;
    border-right: 1px solid #ebf0f3;
    border-bottom: 1px solid #ebf0f3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Noah Head';
    font-weight: 800;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 38px;
    line-height: em(48, 38);
    @include mobile {
      font-size: 32px;
      line-height: em(40, 32);
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: em(40, 32);
    @include mobile {
      font-size: 26px;
      line-height: em(32, 26);
    }
  }

  h3 {
    font-size: 26px;
    font-weight: 500;
    line-height: em(32, 26);
    @include mobile {
      font-size: 22px;
      line-height: em(28, 22);
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: em(28, 20);
    @include mobile {
      font-size: 18px;
      line-height: em(24, 18);
    }
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: em(24, 18);
    @include mobile {
      font-size: 16px;
      line-height: em(20, 16);
    }
  }

  h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: em(20, 14);
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
    margin-bottom: 20px;

    li {
      margin-bottom: 20x;
      font-size: 16px;
      font-weight: 500;
      font-weight: normal;
      color: #000000;
    }
  }

  img {
    width: auto;
    text-align: center;
    height: auto;
    margin-bottom: 20px;
  }

  iframe {
    margin-bottom: 20px !important;
    text-align: center;

    @include mobile {
      width: 100% !important;
      max-width: 100% !important;
      height: auto;
      min-height: 210px;
    }

    @include tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .fb_iframe_widget {
    float: left;
    width: 100%;
    margin-bottom: 35px;

    @include mobile {
      width: 100% !important;
      max-width: 100% !important;
    }

    @include tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  a {
    font-weight: inherit;
    color: #2a6ebb;
    position: relative;
    &:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #2a6ebb;
      transition: $transition1;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
}

.slick-slider:not(.slick-initialized) {
  overflow: hidden;
}

.default-section-margin-bottom {
  margin-bottom: 150px;
  @include mobile {
    margin-bottom: 120px;
  }

  @include tablet {
    margin-bottom: 100px;
  }
}

.default-section-margin-top {
  margin-top: 150px;
  @include mobile {
    margin-top: 120px;
  }

  @include tablet {
    margin-top: 100px;
  }
}

hr.wp-block-separator {
  width: 100%;
  position: relative;
  max-width: 1210px;
  margin: 0 auto;
}

.single-beitrage{
  .article_header__info span.date{
    white-space: nowrap;
  }
}