.m36 {
  width: 100%;
  .page_banner {
    &__thumb {
      width: 100%;
      height: 470px;
      background-size: cover;
      background-position: center;
      background-color: #2a6ebb;
      @include mobile {
        height: 230px;
      }
      @include tablet {
        height: 320px;
      }
    }
    &__bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include mobile {
        flex-direction: column-reverse;
      }
      @include tablet {
        flex-direction: column-reverse;
      }
    }
    &__box {
      width: 100%;
      max-width: 512px;
      display: block;
      margin-left: auto;
      margin-right: 0;
      // transform: translateY(-50%);
      background-color: #2a6ebb;
      padding: 40px 52px 32px 40px;
      color: #fff;
      @include mobile {
        max-width: 100%;
        padding: 50px 18px;
      }
      @include tablet {
        max-width: 100%;
        padding: 50px 76px;
      }
      h5 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: em(16, 12);
        letter-spacing: 1.15px;
        margin-bottom: 15px;
      }
      h3 {
        font-weight: normal;
        font-size: 22px;
        line-height: em(34, 22);
        letter-spacing: 0.05px;
        margin-bottom: 7px;
        @include mobile {
          font-size: 20px;
          line-height: em(28, 20);
          margin-bottom: 15px;
        }
      }
      h4 {
        font-size: 14px;
        font-weight: 500;
        line-height: em(20, 14);
        margin-bottom: 0;
      }
    }
    &__left {
      width: 380px;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      h1 {
        hyphens: auto;
      }
    }
    &__right {
      width: calc(100% - 380px);
      padding-left: 16px;
      padding-right: 170px;
      @include mobile {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      @include tablet {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__content {
      margin-top: 146px;
      margin-bottom: 0;
      display: flex;
      @include mobile {
        flex-direction: column;
        margin-top: 70px;
      }
      @include tablet {
        flex-direction: column;
        margin-top: $vs-t;
      }
      h1 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 48px;
        line-height: em(62, 48);
        letter-spacing: -0.1px;
        color: #1b1b1b;
        margin-bottom: 10px;
        overflow-wrap: break-word;
        @include mobile {
          font-size: 36px;
          line-height: em(38, 36);
          margin-bottom: 20px;
        }
        @include tablet {
          font-size: 40px;
          line-height: em(62, 40);
          margin-bottom: 20px;
        }
      }
      a {
        font-weight: 600;
        color: #2a6ebb;
        position: relative;
        &:before {
          content: '';
          width: 0;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #2a6ebb;
          transition: $transition1;
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
      .excerpt {
        p {
          font-weight: normal;
          font-size: 24px;
          line-height: em(40, 24);
          letter-spacing: 0.05px;
          color: #000;
          margin-top: 0;
          @include mobile {
            font-size: 22px;
            line-height: em(32, 22);
          }
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: em(24, 16);
        color: #000000;
        margin-top: 25px;
      }
    }
  }
}
