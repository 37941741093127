.m46 {
  padding: 150px;
  @include mobile {
    padding: 100px;
  }
  @include tablet {
    padding: 120px;
  }
  h1 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    line-height: em(54, 40);
    margin-bottom: 30px;
    @include mobile {
      font-size: 30px;
      line-height: em(42, 30);
      letter-spacing: -0.1px;
      margin-bottom: 32px;
    }
    @include tablet {
      font-size: 32px;
      line-height: em(42, 32);
      margin-bottom: 32px;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: em(24, 16);
    margin-bottom: 35px;

    @include mobile {
      letter-spacing: 2.5px;
      margin-bottom: 57px;
    }

    @include tablet {
      letter-spacing: 5px;
      margin-bottom: 26px;
    }
  }

  .block-of-links {
    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: em(32, 22);
      margin-top: 87px;
      margin-bottom: 24px;
      @include tablet {
        margin-top: 32px;
        margin-bottom: 24px;
      }
    }
    span {
      display: block;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
      @include mobile {
      }
      @include tablet {
      }
    }

    .link_with_arrow {
      font-weight: 800;
      font-size: 20px;
      line-height: em(35, 16);
      letter-spacing: 0.3px;
      color: #2a6ebb;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @include tablet {
        margin-bottom: 8px;
        line-height: em(28, 20);
      }

      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50px;
        margin-left: 0;
        background: #2a6ebb no-repeat center center;
        transition: $transition1;
        margin-right: 16px;
      }

      @include mobile {
        font-size: 15px;
        line-height: em(33, 15);
      }
      &:hover {
        color: #2a6ebb;
        &:after {
          margin-left: 16px;
        }
      }
      &:after {
        content: '';
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        margin-left: 12px;
        background: url('./images/icon-arrow-right.svg') no-repeat center center;
        transition: $transition1;
        @include tablet {
          height: 16px;
        }
      }
    }
  }
}
