.error404-content {
  width: 100%;
  background-color: #2a6ebb;
  padding: 379px 0 194px;
  color: #fff;

  @include tablet {
    padding: 267px 0 220px;
  }
  @include mobile {
    padding: 200px 0 64px;
    img {
      margin-bottom: 30px;
      max-width: 192px;
    }
    .hidden-desktop.hidden-tab.column-mob-12 {
      text-align: center;
    }
  }
  .row {
    display: flex;
    align-items: center;
    @include mobile {
      display: block;
    }
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: em(16, 12);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: em(48, 40);
    margin-bottom: 7px;
    @include mobile {
      font-size: 24px;
      line-height: em(34, 24);
      margin-bottom: 27px;
      padding-right: 10px;
      text-align: left;
    }
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: em(24, 16);
    @include mobile {
      margin-bottom: 20px;
    }
    @include tablet {
      padding-right: 100px;
    }
  }
  a {
    margin-top: 25px;
    float: left;
    padding: 8px 20px 10px 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    letter-spacing: 0.3px;
    color: #2a6ebb;
    text-transform: uppercase;
    transition: $transition1;
    @include mobile {
      margin-top: 0;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
      margin-right: 16px;
      transition: $transition1;
    }
    &:hover {
      background-color: #2a6ebb;
      color: #fff;
      &:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
    }
    &.m04__btn-outline {
      margin-left: 20px;
      background-color: transparent;
      color: #fff;
      @include mobile {
        clear: both;
        margin-left: 0;
      }
      &:before {
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
      &:hover {
        background-color: #fff;
        color: #2a6ebb;
        &:before {
          background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
        }
      }
    }
  }
}
