.m04 {
  width: 100%;
  background-color: #2a6ebb;
  padding: $vs-d 0;
  color: #fff;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: em(16, 12);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 26px;
    line-height: em(38, 26);
    margin-bottom: 7px;
    @include mobile {
      font-size: 24px;
      line-height: em(36, 24);
      margin-bottom: 20px;
    }
    @include mobile {
      font-size: 24px;
      line-height: em(34, 24);
      margin-bottom: 16px;
      padding-right: 10px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    @include mobile {
      margin-bottom: 20px;
    }
    @include tablet {
      padding-right: 100px;
    }
  }
  a {
    margin-top: 25px;
    float: left;
    // padding: 10px ​20px 9px 15px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 9px;
    padding-left: 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #fff;
    font-weight: 800;
    font-size: 16px;
    line-height: em(24, 16);
    letter-spacing: 0.3px;
    color: #2a6ebb;
    text-transform: uppercase;
    transition: $transition1;
    @include mobile {
      margin-top: 15px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
      margin-right: 16px;
      transition: $transition1;
    }
    &:hover {
      background-color: #2a6ebb;
      color: #fff;
      &:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
    }
    &.m04__btn-outline {
      margin-left: 20px;
      background-color: transparent;
      color: #fff;
      @include mobile {
        clear: both;
        margin-left: 0;
      }
      &:before {
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
      &:hover {
        background-color: #fff;
        color: #2a6ebb;
        &:before {
          background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
        }
      }
    }
  }
  img {
    float: right;
    @include tablet {
      float: none;
      margin-top: 60px;
    }
  }
}
