.m30 {
  width: 100%;
  padding: $vs-d 0;
  position: relative;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  .container {
    position: static;
  }
  &_wrapper {
    width: 100%;
    margin-top: 50px;
    @include mobile {
      margin-top: 40px;
    }
    .filters {
      margin-bottom: 22px;
    }
    .column-tab-6 {
      @include tablet {
        &:nth-child(2n + 1) {
          clear: left;
        }
      }
    }
  }
  &_disabled {
    width: 100%;
    max-width: 770px;
    font-size: 24px;
    font-weight: normal;
    line-height: em(40, 24);
    letter-spacing: 0.05px;
    color: #000;
    margin-top: -30px;
    @include mobile {
      font-size: 20px;
      line-height: em(32, 20);
      margin-top: -20px;
    }
  }
  &_box {
    width: 100%;
    display: flex;
    padding-top: 50px;
    margin-bottom: 50px;
    border-top: 1px solid #dcdcdc;
    @include mobile {
      padding-top: 30px;
      margin-bottom: 20px;
      flex-direction: column;
    }
    @include tablet {
      padding-top: 40px;
      margin-bottom: 40px;
    }
    &__left {
      width: 280px;
      display: flex;
      flex-direction: column;
      @include mobile {
        width: 100%;
        margin-bottom: 20px;
      }
      @include tablet {
        width: 190px;
      }
      span {
        &:first-child {
          font-family: 'Noah Head';
          font-size: 40px;
          line-height: em(54, 40);
          font-weight: 800;
          letter-spacing: -0.1px;
          color: #2a6ebb;
          margin-bottom: 8px;
          @include mobile {
            font-size: 30px;
            line-height: 1.1em;
            margin-bottom: 5px;
          }
        }
        &:last-child {
          font-size: 16px;
          line-height: em(24, 16);
          font-weight: 500;
          letter-spacing: -0.1px;
          color: #000;
          @include mobile {
            letter-spacing: -0.02px;
          }
        }
      }
    }
    &__right {
      width: calc(100% - 280px);
      min-height: 210px;
      padding-left: 16px;
      padding-right: 190px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        width: 100%;
        min-height: 320px;
        padding-left: 0;
        padding-right: 0;
      }
      @include tablet {
        width: calc(100% - 190px);
        padding-right: 0;
      }
      h2 {
        font-family: 'Noah Head';
        font-size: 26px;
        line-height: em(38, 26);
        font-weight: 500;
        color: #000;
        margin-bottom: 25px;
        @include mobile {
          font-size: 24px;
          line-height: em(34, 24);
          margin-bottom: 15px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000;
        margin-bottom: 20px;
        @include mobile {
          margin-bottom: 20px;
        }
      }
    }
    &__bottom {
      display: flex;
      @include mobile {
        flex-wrap: wrap;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        padding-right: 16px;
        border-right: 1px solid #959595;
        margin-left: 16px;
        @include mobile {
          margin-bottom: 10px;
        }
        &:first-child {
          margin-left: 0;
          @include mobile {
            width: 100%;
            border-right: 0;
            padding-right: 0;
          }
        }
        &:nth-child(2n) {
          @include mobile {
            margin-left: 0;
          }
        }
        &:last-child {
          border-right: 0;
          padding-right: 0;
        }
      }
    }
  }
  .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    &:hover {
      &:before {
        margin-right: 12px;
      }
    }
    &:before {
      transform: rotate(90deg);
    }
  }
}
