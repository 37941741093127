.m22 {
  width: 100%;
  padding: $vs-d 0px;
  background-color: #f5f5f5;
  @include mobile {
    padding: $vs-m 0px;
  }
  @include tablet {
    padding: $vs-t 0px;
  }
  @include tablet-big {
    padding: $vs-t 0px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      flex-direction: column;
    }
    &__left {
      max-width: 70%;
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 21px;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 21px;
      }
      @include tablet {
        font-size: 32px;
        line-height: em(42, 32);
        letter-spacing: 0.01px;
        margin-bottom: 21px;
      }
      @include tablet-big {
        font-size: 32px;
        line-height: em(42, 32);
        letter-spacing: 0.01px;
        margin-bottom: 21px;
      }
    }
    p {
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.5px;
      color: #000;
      @include mobile {
        font-size: 12px;
        line-height: em(16, 12);
        letter-spacing: 0.15px;
        margin-bottom: 15px;
      }
    }
  }
  &__wrapper {
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 54px;
    @include mobile {
      flex-direction: column-reverse;
      margin-top: 50px;
    }
    @include tablet {
      flex-direction: column-reverse;
      margin-top: 50px;
    }
    @include tablet-big {
      flex-direction: column-reverse;
      margin-top: 50px;
    }
  }
  &__content {
    width: calc(100% - 670px);
    padding-right: 40px;
    padding-top: 80px;
    @include mobile {
      width: 100%;
      padding-right: 0;
      padding-top: 50px;
    }
    @include tablet {
      width: 100%;
      padding-right: 0;
      padding-top: 50px;
    }
    @include tablet-big {
      width: 100%;
      padding-right: 0;
      padding-top: 50px;
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 500;
      font-size: 26px;
      line-height: em(38, 26);
      color: #000000;
      margin-bottom: 26px;
      @include mobile {
        font-size: 24px;
        line-height: em(34, 24);
        margin-bottom: 30px;
      }
      @include tablet {
        font-size: 24px;
        line-height: em(34, 24);
        margin-bottom: 18px;
      }
      @include tablet-big {
        font-size: 24px;
        line-height: em(34, 24);
        margin-bottom: 18px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      color: #000;
      margin-bottom: 62px;
      @include mobile {
        margin-bottom: 33px;
      }
      @include tablet {
        margin-bottom: 43px;
      }
      @include tablet-big {
        margin-bottom: 43px;
      }
    }
    &__bottom {
      h4 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 18px;
        line-height: em(24, 18);
        margin-bottom: 6px;
      }
      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: em(20, 14);
        color: #505050;
        @include tablet {
          font-size: 16px;
          line-height: em(24, 16);
        }
      }
    }
  }
  &__image {
    width: 670px;
    height: 470px;
    @include mobile {
      width: 100%;
      height: 225px;
    }
    @include tablet {
      width: 100%;
      height: 407px;
    }
    @include tablet-big {
      width: 100%;
      height: 407px;
    }
    img {
      @extend %imgCropped;
    }
  }
}
