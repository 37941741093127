.m08 {
  width: 100%;
  padding: $vs-d 0;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  &_wrapp {
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    &__image {
      width: 670px;
      height: 448px;
      @include mobile {
        width: 100%;
        height: 225px;
        margin-top: 30px;
      }
      @include tablet {
        width: 100%;
        height: 406px;
        margin-top: 60px;
      }
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      width: calc(100% - 670px);
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      @include mobile {
        width: 100%;
        padding-left: 0;
      }
      @include tablet {
        width: 100%;
        padding-left: 0;
      }
      h5 {
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        line-height: em(16, 12);
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 0;
        @include mobile {
          margin-bottom: 7px;
        }
        @include tablet {
          margin-bottom: 4px;
        }
      }
      h3 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 40px;
        color: #1b1b1b;
        line-height: em(54, 40);
        letter-spacing: -1px;
        margin-bottom: 0;
        @include mobile {
          font-size: 30px;
          line-height: em(42, 30);
          margin-bottom: 14px;
        }
        @include tablet {
          font-size: 27px;
          line-height: em(42, 27);
          margin-bottom: 14px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000;
        margin-top: 25px;
      }
    }
  }
}
