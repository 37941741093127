.m15 {
  width: 100%;
  padding: $vs-d 0px;
  @include mobile {
    padding: $vs-m 0px;
  }
  @include tablet {
    padding: $vs-t 0px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 96px;
    @include mobile {
      flex-direction: column;
      margin-bottom: 0;
    }
    @include tablet {
      flex-direction: column;
      margin-bottom: 0;
    }
    &__left {
      max-width: 70%;
      @include mobile {
        max-width: 100%;
      }
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 21px;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 20px;
      }
      @include tablet {
        font-size: 27px;
        line-height: em(42, 27);
        margin-bottom: 20px;
      }
    }
    p {
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.5px;
      color: #000;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
    }
  }
  .row {
    margin-left: -68px;
    margin-right: -68px;
    @include tablet {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .column-4 {
    border-right: 1px solid #dcdcdc;
    padding-left: 68px;
    padding-right: 68px;
    min-height: 300px;
    display: flex;
    align-items: center;
    &:nth-child(3n) {
      border-right: 0;
    }
    &:last-child {
      .m15_box {
        @include mobile {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    @include mobile {
      border-right: 0;
      min-height: auto;
    }
    @include tablet {
      padding-left: 0;
      padding-right: 0;
      border-right: 0;
      min-height: auto;
    }
  }
  &_box {
    @include mobile {
      padding-bottom: 50px;
      margin-top: 50px;
      border-bottom: 1px solid #dcdcdc;
    }
    @include tablet {
      padding-bottom: 50px;
      padding-right: 40%;
      margin-top: 50px;
      border-bottom: 1px solid #dcdcdc;
    }
    h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: em(32, 22);
      letter-spacing: 0.5px;
      color: #1b1b1b;
      margin-bottom: 29px;
      @include mobile {
        margin-bottom: 19px;
      }
    }
    p {
      font-size: 17px;
      font-weight: 500;
      line-height: em(27, 17);
      color: #000000;
      margin-bottom: 32px;
      @include mobile {
        font-size: 16px;
        line-height: em(24, 16);
        letter-spacing: 0.02px;
        margin-bottom: 50px;
      }
    }
  }
}
