.m38 {
  &-banner {
    width: 100%;
    height: 470px;
    background-color: #2a6ebb;

    display: flex;
    align-items: flex-end;

    padding-bottom: 40px;

    h1 {
      font-family: 'Noah Head';
      max-width: 572px;
      font-size: 32px;
      font-weight: 800;
      line-height: em(42, 32);
      color: #fff;

      @include tablet {
      }
    }
  }

  &-content {
    padding-top: 123px;
    &__left {
      &-mobile {
        display: none !important;
        max-width: 100%;
        @include tablet {
          display: block !important;
          padding: 0 !important;
        }
        @include mobile {
          padding: 0 !important;
          display: block !important;
        }
        .m38-txt-blue.m38-left-external {
          @include mobile {
            display: none;
          }
        }
      }

      @include tablet {
        display: none;
      }
      @include mobile {
        display: none;
      }

      h1 {
        font-family: 'Noah Head';
        max-width: 572px;
        font-size: 38px;
        font-weight: 800;
        line-height: em(54, 38);
        letter-spacing: -0.1px;
        color: #2a6ebb;

        @include tablet {
          font-size: 32px;
          line-height: em(42, 32);
        }

        @include mobile {
          br {
            display: none;
          }
        }
      }
      img {
        position: relative;
        margin-right: 5px;
      }
    }

    &__right {
      position: relative;
      max-width: 700px;
      margin-left: 100px;

      @include tablet {
        margin-left: 0;
        padding: 0 !important;
      }
      @include mobile {
        margin-left: 0;
      }
    }
  }

  &-left-external {
    @include tablet {
      display: none;
    }
    @include mobile {
      display: none;
    }
  }

  &-link {
    display: flex;
    align-items: center;
  }

  &-year {
    margin-top: 6px;
    margin-bottom: 30px;
  }

  &-txt-blue {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -0.1px;
    color: #2a6ebb;
    display: flex;
    align-items: center;
  }

  &-txt-grey {
    font-size: 16px;
    font-weight: 800;
    line-height: em(24, 16);
    letter-spacing: -0.1px;
    color: #000;
    font-weight: 500;
  }

  &-right-top {
    margin-bottom: 15px;

    @include tablet {
      display: flex;
      justify-content: space-between;
    }

    @include mobile {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include tablet {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 231px;
      }

      @include mobile {
        flex-wrap: wrap;
        justify-content: flex-start;
        .m38-page-external {
          width: 100%;
        }
      }

      li {
        &::after {
          content: '';
          width: 1px;
          height: 14px;
          background: #d8d8d8;
          display: inline-block;
          margin-left: 30px;
        }

        &:last-child::after,
        &:nth-last-child(2)::after {
          display: none;
        }

        &.m38-event-link-mobile {
          display: none;
          img {
            position: relative;
            bottom: -5px;
            margin-right: 3px;
            @include mobile {
              bottom: 0;
            }
          }
          @include tablet {
            display: block;
            width: 100%;
          }
          @include mobile {
            width: 100%;
            display: block;
          }
        }
      }

      @include tablet {
        li:first-child {
          width: 100%;
          &::after {
            display: none;
          }
        }

        li:nth-child(3) {
          margin-left: 20px;
          &::after {
            display: none;
          }
        }
      }

      @include mobile {
        li {
          margin-bottom: 10px;
        }

        li:first-child {
          width: 100%;
          &::after {
            display: none;
          }
        }

        li:nth-child(3) {
          margin-left: 20px;
          &::after {
            display: none;
          }
        }

        li:nth-child(4) {
          width: 100%;
        }
      }
    }
  }

  &-page-external {
    img {
      margin-right: 4px;
    }
  }

  &-arrow {
    @include tablet {
      display: none;
    }
    @include mobile {
      display: none;
    }

    cursor: pointer;
    position: absolute;
    transform: rotate(93deg);
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0px;
    z-index: 2;
    right: 0;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
}
