.m34 {
  width: 100%;
  padding: $vs-d 0;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  .main_title {
    p {
      font-size: 25px;
      line-height: em(38, 25);
      color: #000;
      font-weight: 500;
      margin-top: 64px;
      @include mobile {
        font-size: 24px;
        line-height: em(34, 24);
        margin-top: 55px;
      }
    }
  }
  &_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    @include mobile {
      flex-direction: column;
      margin-top: 24px;
    }
    @include tablet {
      flex-direction: column;
    }
  }
  &_left {
    width: 265px;
    font-size: 16px;
    line-height: em(24, 16);
    font-weight: 500;
    color: #000;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 500px;
    }
    p {
      margin-bottom: 16px;
      &:first-child {
        margin-bottom: 40px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      display: inline-block;
      color: #2a6ebb;
      transition: $transition1;
      &:hover {
        color: #0d1e5a;
      }
    }
  }
  &_right {
    width: calc(100% - 265px);
    padding-left: 115px;
    @include mobile {
      width: 100%;
      padding-left: 0;
      margin-top: 40px;
    }
    @include tablet {
      width: 100%;
      padding-left: 0;
      margin-top: 50px;
    }
    .gform_body {
      @include mobile {
        margin-top: 0;
      }
      @include tablet {
        margin-top: 0;
      }
    }
    .gform_wrapper {
      margin-bottom: 0;
    }
    .gform_heading {
      display: none;
    }
    .gfield_label {
      display: none;
    }
    .validation_error {
      display: none;
    }
    .ginput_container {
      .validation_message {
        display: none;
      }
    }
    .gform_ajax_spinner {
      display: none;
    }
    .validation_message {
      padding: 0 8px;
      margin-top: 2px;
      @include mobile {
        padding: 0;
      }
    }
    .gfield {
      padding: 0 8px;
      margin-bottom: 29px;
      @include mobile {
        padding: 0;
      }
    }
    .gfield:not(.m34_right li:last-child) {
      float: left;
      width: 50%;
      display: inline-block;
      clear: left;
      @include mobile {
        width: 100%;
        padding-right: 0 !important;
      }
      @include tablet {
        width: 100%;
        padding-right: 0 !important;
      }
    }
    li {
      margin-top: 0 !important;
      &:last-child {
        float: none;
        width: 50%;
        display: inline-block;
        padding-right: 0 !important;
        @include mobile {
          width: 100%;
        }
        @include tablet {
          width: 100%;
        }
      }
      .gfield_required {
        display: none;
      }
      input {
        border: 0;
        outline: none;
        width: 100% !important;
        border-bottom: 1px solid #959595;
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        padding: 0 !important;
        padding-bottom: 2px !important;
        @include placeholder {
          color: #959595;
          font-size: 16px;
          font-weight: 500;
        }
      }
      input[type="checkbox"] {
        width: initial!important;
      }
      select {
        border: 0;
        outline: none;
        width: 100% !important;
        border-bottom: 1px solid #959595;
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        padding: 0 !important;
        padding-bottom: 6px !important;
        option {
          color: #959595;
          font-size: 16px;
          font-weight: 500;
          padding: 0 !important;
          margin-left: 0 !important;
        }
      }
      textarea {
        border: 0;
        outline: none;
        width: 100%;
        height: 194px !important;
        font-size: 16px;
        font-weight: 500;
        color: #959595;
        border-bottom: 1px solid #959595;
        padding: 0;
        @include placeholder {
          font-weight: 500;
          font-size: 16px;
          color: #959595;
        }
        @include mobile {
          height: 120px !important;
        }
        @include tablet {
          height: 120px !important;
        }
      }
    }
    .gform_footer {
      display: flex !important;
      justify-content: flex-end !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    .gform_button {
      margin-top: 0 !important;
      float: right;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 9px;
      padding-left: 43px;

      border: 1px solid #2a6ebb;
      border-radius: 22px;
      background: url('./images/icon-arrow-right-white.svg') no-repeat 15px
        center;
      background-size: 14px;
      background-color: #2a6ebb;

      font-weight: 800;
      font-size: 16px;
      line-height: em(24, 16);
      letter-spacing: 0.3px;
      color: #fff;
      text-transform: uppercase;
      transition: $transition1;
      outline: none;
      margin-right: 0 !important;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        color: #2a6ebb;
        background-image: url('./images/icon-arrow-right-blue.svg');
      }
      @include mobile {
        width: auto !important;
        margin-bottom: 0 !important;
        line-height: em(24, 16) !important;
        border-radius: 23px !important;
      }
    }
  }
  .m31-button-reset {
    display: flex;
    align-items: center;
    margin-right: 24px;
    display: none;
  }
}
