.m06 {
  width: 100%;
  .page_banner {
    &__thumb {
      width: 100%;
      height: 470px;
      background-size: cover;
      background-position: center;
      background-color: #2a6ebb;
      @include mobile {
        height: 230px;
      }
      @include tablet {
        height: 320px;
      }
    }
    &__bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include mobile {
        flex-direction: column-reverse;
        padding-bottom: $vs-m;
      }
      @include tablet {
        flex-direction: column-reverse;
        padding-bottom: $vs-t;
      }
    }
    &__box {
      width: 100%;
      max-width: 512px;
      display: block;
      margin-left: auto;
      margin-right: 0;
      // transform: translateY(-50%);
      background-color: #2a6ebb;
      padding: 40px 52px 32px 40px;
      color: #fff;
      @include mobile {
        max-width: 100%;
        padding: 50px 18px;
      }
      @include tablet {
        max-width: 100%;
        padding: 50px 76px;
      }
      h5 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        line-height: em(16, 12);
        letter-spacing: 1.15px;
        margin-bottom: 15px;
      }
      h3 {
        font-weight: normal;
        font-size: 22px;
        line-height: em(34, 22);
        letter-spacing: 0.05px;
        margin-bottom: 7px;
        @include mobile {
          font-size: 20px;
          line-height: em(28, 20);
          margin-bottom: 15px;
        }
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: em(20, 14);
        margin-bottom: 0;
      }
    }
    &__content {
      margin-top: 32px;
      margin-bottom: 150px;
      @include mobile {
        margin-top: $vs-m;
        margin-bottom: 65px;
      }
      @include tablet {
        margin-top: 88px;
        margin-bottom: 88px;
      }
      h1 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 48px;
        line-height: em(62, 48);
        letter-spacing: -0.1px;
        color: #1b1b1b;
        margin-bottom: 10px;
        @include mobile {
          font-size: 36px;
          line-height: em(38, 36);
          margin-bottom: 20px;
        }
        @include tablet {
          font-size: 40px;
          line-height: em(62, 40);
          margin-bottom: 20px;
        }
      }
      p {
        font-weight: normal;
        font-size: 24px;
        line-height: em(32, 24);
        letter-spacing: 0.417px;
        color: #000;
        a {
          font-weight: 800;
          color: #2a6ebb;
          position: relative;
          // &:before {
          //   content: '';
          //   width: 0;
          //   height: 2px;
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   background-color: #2a6ebb;
          //   transition: $transition1;
          // }
          &:hover {
            text-decoration: underline;
            // &:before {
            //   width: 100%;
            // }
          }
        }
      }
    }
  }

  .page_banner__thumb {
    &.align-flex-bottom {
      display: flex;
      align-items: flex-end;
      padding-bottom: 40px;

      h1 {
        font-family: 'Noah Head';
        max-width: 572px;
        font-size: 32px;
        font-weight: 800;
        line-height: em(42, 32);
        color: #fff;
      }
    }
  }

  .page_banner__content:only-child {
    margin-top: 155px;
    @include mobile {
      margin-top: $vs-m;
      margin-bottom: 0;
    }
    @include tablet {
      margin-top: $vs-t;
      margin-bottom: 0;
    }
  }
  .go_section_bottom {
    cursor: pointer;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 1px solid #2a6ebb;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    position: absolute;
    bottom: 30px;
    right: 0;
    transform: rotate(90deg);
    transition: $transition1;
    &:hover {
      transform: rotate(90deg) scale(1.1);
    }
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }
  }
}
