.m29 {
  width: 100%;
  background-color: #f5f5f5;
  padding: $vs-d 0;
  position: relative;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  .container {
    position: static;
  }
  .posts-holder{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    @include tablet {
      grid-template-columns: 1fr 1fr;
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
  &_wrapper {
    width: 100%;
    margin-top: 50px;
    @include mobile {
      margin-top: 40px;
    }
    .filters {
      margin-bottom: 22px;
    }
    .row {
      @include tablet {
        display: flex;
        flex-wrap: wrap;
      }
      @include tablet-big {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .column-4 {
      @include desktop {
        &:nth-child(3n + 1) {
          clear: left;
        }
      }
    }
    .column-tab-6 {
      @include tablet {
        &:nth-child(2n + 1) {
          clear: left;
        }
      }
      @include tablet-big {
        &:nth-child(3n + 1) {
          clear: left;
        }
      }
    }
  }
  &_box {
    width: 100%;
    margin-bottom: 32px;
    height: calc(100% - 32px);
    @include mobile {
      margin-bottom: 24px;
      height: calc(100% - 24px);
    }
    a {
      width: 100%;
      height: 100%;
      min-height: 252px;
      display: block;
      background-color: #fff;
      border-radius: 8px;
      padding: 32px 24px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transition: $transition1;
      &:hover {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
      }
      @media only screen and (max-width: 340px) {
        padding: 32px 18px;
      }
    }
    span {
      font-size: 12px;
      font-weight: 500;
      line-height: em(18, 12);
      color: #959595;
      letter-spacing: -0.21px;
      margin-right: 13px;
      &:last-child {
        padding-left: 40px;
        border-left: 1px solid #959595;
        color: #2a6ebb;
        background: url('./images/icon-category.svg') no-repeat 15px center;
        background-size: 14px;
      }
    }
    h4 {
      font-weight: 800;
      font-size: 22px;
      line-height: em(32, 22);
      letter-spacing: 0.05px;
      color: #000;
      margin-top: 30px;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      color: #000;
      margin-top: 9px;
      margin-bottom: 0;
    }
  }
  .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    &:hover {
      &:before {
        margin-right: 12px;
      }
    }
    &:before {
      transform: rotate(90deg);
    }
  }
}
