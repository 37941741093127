.m01 {
  width: 100%;
  position: relative;
  &--images {
    img {
      @extend %imgCropped;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: $transition1;
    }
    .is--active {
      opacity: 1;
    }
  }
  .homepage_slider {
    height: 100vh;
    min-height: -webkit-stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 64px;
    @include mobile {
      padding-bottom: 50px;
	}
    &__content {
      max-width: 492px;
      margin-bottom: 12px;
      @include mobile {
        margin-bottom: 15px;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        line-height: em(16, 12);
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 1px;
      }
      h2 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 48px;
        line-height: em(62, 48);
        letter-spacing: -0.1px;
        color: #1b1b1b;
        margin-bottom: 0;
        @include mobile {
          font-size: 30px;
          line-height: em(42, 30);
        }
      }
	}
	
	&.dark-background {
		.homepage_slider__content {
			span {
				color: #eaeaea;
			}
			h2 {
				color: #ffffff;
			}
		}
	}
    .homepage-slider {
      max-height: 500px;
      opacity: 0;
      &.slick-initialized{
        opacity: 1;
      }
      @include mobile {
        margin-bottom: 0;
      }
      @include tablet {
        margin-bottom: 0;
      }
    }
    .slick-list {
      margin: 0 -12px;
      @include mobile {
        margin: 0 -16px;
      }
      @include tablet {
        margin: 0 -46px;
      }
    }
    .slick-track {
      @include mobile {
        margin-left: -17px;
      }
      @include tablet {
        margin-left: -106px;
      }
    }
    .slick-dots {
      text-align: left;
      position: static;
      margin-top: -8px;
      li {
        margin: 0;
        width: 14px;
        height: 14px;
        margin-right: 14px;
        &.slick-active {
          button {
            width: 12px;
            height: 12px;
          }
        }
        button {
          width: 6px;
          height: 6px;
          background-color: #fff;
          border-radius: 12px;
          margin-right: 0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
          transition: $transition1;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .homepage_slide {
    padding: 0 12px;
    outline: 0;
    @include mobile {
      padding: 0 8px 15px;
    }
    @include tablet {
      padding: 0 12px 32px;
    }
    &.is--hovered {
      .homepage_slide_box a {
        opacity: 1;
      }
    }
    &_box {
      width: 100%;
      a {
        display: flex;
        background-color: #fff;
        padding: 32px 27px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        opacity: 0.7;
        outline: 0;
        transition: $transition1;
        @include mobile {
          padding: 28px 33px 28px 23px;
        }
        &:hover {
          opacity: 1;
        }
      }
      &__icon {
        width: 65px;
        @include mobile {
          width: 45px;
        }
      }
      &__content {
        width: calc(100% - 65px);
        padding-left: 26px;
        @include mobile {
          width: calc(100% - 45px);
          padding-left: 16px;
        }
        h4 {
          font-weight: 800;
          font-size: 22px;
          line-height: em(32, 22);
          letter-spacing: 0.05px;
          color: #1b1b1b;
          margin-bottom: 2px;
          @media only screen and (max-width: 340px) {
            font-size: 18px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: em(24, 16);
          color: #000000;
          margin-bottom: 2px;
          @media only screen and (max-width: 340px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -32px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 0;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
  .slick-next {
    left: 43px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-disabled {
    opacity: 0.3;
  }
}
