.m51 {
  width: 100%;
  padding: $vs-d 0px;
  background: #f5f5f5;
  @include mobile {
    padding: $vs-m 0px;
  }
  @include tablet {
    padding: $vs-t 0px;
  }
  @include tablet-big {
    padding: $vs-t 0px;
  }

  &__inner {
    width: 100%;
    display: flex;
  }

  &__left {
    width: 768px;
    padding-left: 40px;
    @include mobile {
      width: 100%;
      padding-left: 0;
      padding-top: 140px;
    }
    @include tablet {
      width: 100%;
      padding-left: 0;
      padding-top: 50px;
    }
  }

  &__right {
    width: calc(100% - 768px);
    @include mobile {
      width: 100%;
      margin-left: -100%;
    }
    @include tablet {
      width: 100%;
      margin-left: -100%;
    }

    ul {
      float: right;
      width: 280px;
      padding: 32px 24px 40px 24px;
      background-color: #f1f1f1;
      position: -webkit-sticky;
      position: sticky;
      top: 130px;
      @include mobile {
        width: calc(100% + 30px);
        margin-right: -16px;
        display: flex;
        padding: 0px 16px;
        overflow-x: scroll;
        z-index: 4;
        top: 0;
      }
      @include tablet {
        width: 100vw;
        margin-right: -42px;
        display: flex;
        padding: 0px 42px;
        overflow-x: scroll;
        z-index: 4;
        top: 0;
      }

      &:after {
        content: '';
        background-color: #f1f1f1;
        height: 100%;
        width: 100vw !important;
        position: absolute;
        left: 100%;
        max-width: none;
        z-index: 3;
        top: 0;
        @include mobile {
          display: none;
        }
        @include tablet {
          display: none;
        }
      }

      li {
        width: 100%;
        display: flex;
        position: relative;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: em(24, 18);
        color: #2a6ebb;
        @include mobile {
          margin-bottom: 0;
          padding: 30px 0 37px 0;
          margin-right: 48px;
        }
        @include tablet {
          margin-bottom: 0;
          padding: 30px 0 37px 0;
          margin-right: 48px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: #0d1e5a;
        }

        &:before {
          content: '';
          width: 8px;
          height: 100%;
          background-color: #ffca00;
          position: absolute;
          left: -27px;
          top: 0;
          opacity: 0;
          transition: $transition1;
          @include mobile {
            height: 8px;
            width: 100%;
            top: unset;
            bottom: -4px;
            left: 0;
          }
          @include tablet {
            height: 8px;
            width: 100%;
            top: unset;
            bottom: -4px;
            left: 0;
          }
        }

        &.is--active {
          color: #000000;

          &:before {
            opacity: 1;
          }
        }

        a {
          color: inherit;
          transition: $transition1;
          @include mobile {
            white-space: nowrap;
          }
          @include tablet {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .faq-category {
    margin-bottom: 150px;
  }

  &-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 78px;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }

    &__left {
      h2 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 40px;
        color: #1b1b1b;
        line-height: 1.35em;
        letter-spacing: -1px;
        margin-top: 7px;
        @include mobile {
          font-size: 27px;
          margin-bottom: 20px;
        }
        @include tablet {
          font-size: 32px;
          margin-bottom: 20px;
        }
      }

      span {
        font-size: 12px;
        line-height: em(16, 12);
        font-weight: 500;
        color: #747474;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }
  }

  .accordion {
    @include mobile {
      margin-bottom: 75px;
    }
    @include tablet {
      margin-bottom: 75px;
    }

    h2 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 26px;
      line-height: em(38, 26);
      color: #000;
      padding-bottom: 26px;
      border-bottom: 1px solid #dcdcdc;
      @include mobile {
        font-size: 20px;
      }
    }

    .accordion-section-title {
      display: block;
      width: 100%;
      font-weight: 800;
      font-size: 22px;
      line-height: em(36, 22);
      font-weight: bold;
      color: #1b1b1b;
      padding: 27px 0;
      border-bottom: 1px solid #dcdcdc;
      background: url('./images/icon-down-arrow.svg') no-repeat right;
      background-size: 22px;
      padding-right: 40px;
      padding-right: 40px;
      @include mobile {
        font-size: 18px;
        line-height: em(26, 20);
        padding: 25px 0;
        padding-right: 40px;
        background: url('./images/icon-down-arrow.svg') no-repeat right 28px;
        background-size: 22px;
      }

      &:last-child() {
        border-bottom: 0 !important;
      }

      &.active {
        font-weight: 500;
        background: url('./images/icon-up-arrow.svg') no-repeat right;
        background-size: 22px;
        border-bottom: 0;
        @include mobile {
          background: url('./images/icon-up-arrow.svg') no-repeat right 28px;
          background-size: 22px;
        }
      }
    }

    .accordion-section-content {
      padding-bottom: 28px;
      display: none;
      border-bottom: 1px solid #dcdcdc;
      &:first-child {
        display: block;
      }

      &:last-child {
        border-bottom: 0;
      }

      p {
        font-weight: 500;
        color: #1b1b1b;
        font-size: 16px;
        line-height: em(24, 16);
        @include mobile {
          font-family: 'Noah Text';
        }
        @include tablet {
          font-size: 18px;
        }

        a {
          font-weight: 500;
          font-size: 16px;
          line-height: em(24, 16);
          color: #2a6ebb;
          @include mobile {
            font-family: 'Noah Text';
            font-weight: 800;
          }
        }
      }
    }
  }

  p {
    &.excerpt {
      max-width: 866px;
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.05px;
      color: #000;
      margin-top: 24px;

      @include mobile {
        font-size: 22px;
        line-height: em(32, 22);
      }
    }
  }
  .btn_link_with_arrow {
    margin-top: 20px;
    &:before {
      transform: rotate(90deg);
    }
  }
}

@include mobile {
  .m82 {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
