.m40 {
  width: 100%;
  padding: 80px 0 150px;
  @include mobile {
    padding: 50px 0;
  }
  @include tablet {
    padding: 50px 0 120px;
  }
  &_wrapper {
    width: 100%;
    display: flex;
    @include mobile {
      flex-direction: column-reverse;
    }
    @include tablet {
      flex-direction: column-reverse;
    }
    @include tablet-big {
      flex-direction: column-reverse;
    }
  }
  &_left {
    width: calc(100% - 840px);
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
      width: 100%;
      flex-direction: column-reverse;
      padding-right: 0;
      margin-top: 25px;
    }
    @include tablet {
      width: 100%;
      flex-direction: row-reverse;
      padding-right: 0;
      margin-top: 25px;
    }
    @include tablet-big {
      width: 100%;
      flex-direction: row-reverse;
      padding-right: 0;
      margin-top: 25px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: em(20, 14);
      color: #505050;
    }
    &__image {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  &_right {
    width: 840px;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
    @include tablet-big {
      width: 100%;
    }
    img {
      @include tablet {
        width: 100%;
      }
      @include tablet-big {
        width: 100%;
      }
    }
  }
}
