.main_header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 50px;
  left: 0;
  padding: 0 24px;
  z-index: 999;
  transition: all 1s ease-in-out;
  @include mobile {
    height: 56px;
    top: 24px;
    padding: 0 16px;
  }
  @include tablet {
    height: 68px;
    top: 40px;
    padding: 0 32px;
  }
  @include tablet-big {
    height: 68px;
    top: 40px;
    padding: 0 32px;
  }
  &:before {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: $transition1;
  }
  &.hide {
    top: -100%;
  }
}
.menu--toggled {
  // overflow: hidden;
  .main_header {
    &:before {
      opacity: 1;
      visibility: visible;
      overflow: visible;
    }
  }
  .headerWrap {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.headerWrap {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  transition: $transition1;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  float: none;
  @include mobile {
    padding-left: 14px;
    padding-right: 20px;
    border-radius: 4px;
  }
  @include tablet {
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 6px;
  }
  @include tablet-big {
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 6px;
  }
}
.header_left {
  display: flex;
  @include mobile {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  @include tablet {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  @include tablet-big {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
.header_right {
  display: flex;
  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
  @include tablet-big {
    display: none;
  }
}
.logo {
  display: flex;
  align-items: center;
  @include mobile {
    width: 137px;
  }
  @include tablet {
    width: 150px;
  }
  @include tablet-big {
    width: 150px;
  }
  @include desktop {
    width: 193px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1280px) {
    width: 170px;
  }
  a {
    width: 100%;
  }
}
.nav_wrapper {
  display: flex;
  align-items: center;
  margin-left: 50px;
  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
  @include tablet-big {
    display: none;
  }
  @include desktop-small {
    margin-left: 20px;
    min-width: 620px;
  }
  > ul {
    height: 100%;
    list-style: none;
    display: flex;
    // position: relative;
    @include desktop-small {
      width: 100%;
      justify-content: space-between;
    }
    > li {
      padding-right: 55px;
      @include desktop-small {
        padding-right: 35px;
      }
      > a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.19em;
        color: #3a3a3d;
        padding-top: 4px;
        border-bottom: 4px solid transparent;
        transition: $transition1;
        &:hover {
          //   border-bottom-color: #ffca00;
        }
      }
    }
  }

  .menu-items-tracker {
    display: none;
    background: red;
    height: 4px;
    width: 0;
    background-color: #ffca00;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: $transition1;
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }
  }
}
.sub_menu {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  border-top: 1px solid #dcdcdc;
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 48px 92px 55px;
  display: flex;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: $transition1;
  &__info {
    width: 250px;
    margin-right: 100px;
    h4 {
      font-weight: 800;
      font-size: 24px;
      color: #3a3a3d;
      line-height: 1.19em;
      margin-bottom: 20px;
    }
    p {
      font-size: 17px;
      font-weight: 500;
      color: #000;
      line-height: 1.58em;
      margin-bottom: 22px;
    }
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.15em;
      color: #1b1b1b;
      padding-right: 24px;
      background: url(./images/icon-arrow-right.svg) no-repeat right 4px;
      transition: $transition1;
      &:hover {
        padding-right: 30px;
      }
    }
  }
  &__close {
    width: 24px;
    height: 24px;
    background: url(./images/icon-close.svg) no-repeat center;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: $transition1;
    &:hover {
      transform: scale(0.8);
    }
  }
}
.sub-menu {
  width: 650px;
  margin-top: -20px;
  li {
    float: left;
    width: calc(50% - 50px);
    margin-right: 47px;
    border-bottom: 1px solid #dedede;
    padding: 20px 0;
  }
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.15em;
    color: #1b1b1b;
    padding-right: 24px;
    background: url(./images/icon-arrow-right.svg) no-repeat right 5px;
    transition: $transition1;
    &:hover {
      padding-right: 30px;
      color: #2a6ebb;
      background-image: url(./images/icon-arrow-right-secondary.svg);
    }
  }
}
.sub-menu--open {
  .sub_menu {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
  > a {
    // border-bottom-color: #ffca00 !important;
  }
}
.header_btn {
  padding: 28px 5px;
  min-width: 150px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19em;
  color: #1b1b1b;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition1;
  span {
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    margin-right: 12px;
  }
  &.therapy-btn {
    width: 208px;
    background-color: #ffca00;
    span {
      background-image: url('./images/icon-form.svg');
    }
    &:hover {
      background-color: darken(#ffca00, 5%);
    }
  }
  &.lang-btn {
    span {
      background-image: url('./images/icon-globe.svg');
    }
  }
}
.lang {
  display: flex;
  position: relative;
  &.is--toggled {
    @include mobile {
      ul {
        height: 80px;
        visibility: visible;
        overflow: visible;
        li {
          opacity: 1;
          transition: $transition2;
        }
      }
    }
    @include tablet {
      ul {
        height: 80px;
        visibility: visible;
        overflow: visible;
        li {
          opacity: 1;
          transition: $transition2;
        }
      }
    }
    @include tablet-big {
      ul {
        height: 80px;
        visibility: visible;
        overflow: visible;
        li {
          opacity: 1;
          transition: $transition2;
        }
      }
    }
  }
  &:hover {
    ul {
      @include desktop {
        height: fit-content;
        visibility: visible;
        overflow: visible;
        li {
          opacity: 1;
          transition: $transition2;
        }
      }
    }
  }
  ul {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: calc(100% - 18px);
    left: 0;
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-left: 64px;
    box-shadow: 0px 12px 15px 0px rgba(0, 0, 0, 0.075);
    transition: $transition2;
  }
  li {
    padding: 8px 0;
    opacity: 0;
    transition: $transition1;
    a {
      font-size: 16px;
      font-weight: 500;
      color: rgba(#1b1b1b, 0.6);
      transition: $transition1;
      &:hover {
        color: rgba(#1b1b1b, 0.8);
      }
    }
  }
}
.hamburger {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 20px;
  height: 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  span {
    width: 100%;
    height: 1px;
    background-color: #0d1e5a;
    transition: $transition1;
  }
  @include mobile {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
  @include tablet {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
  @include tablet-big {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
  &.is--toggled {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translateX(7px) translateY(6px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translateX(6px) translateY(-6px);
      }
    }
  }
}
.header__mobile {
  display: none;
  // height: 0;
  // overflow: hidden;
  // visibility: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: #fff;
  padding: 0 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  // transition: $transition2;
  @include tablet {
    padding: 0 40px;
    border-top: 1px solid #dedede;
  }
  @include tablet-big {
    padding: 0 40px;
    border-top: 1px solid #dedede;
  }
  &--inner {
    padding: 20px 0;
    @include mobile {
      border-top: 1px solid #dedede;
    }
    @include tablet {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
    }
    @include tablet-big {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
    }
  }
  .header_btn {
    padding: 16px 5px;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      float: left;
    }
    @include tablet-big {
      float: left;
    }
    &.therapy-btn {
      justify-content: flex-start;
      padding: 16px;
      border-radius: 4px;
    }
  }
  .lang {
    justify-content: flex-end;
    flex-direction: column;
    @include tablet {
      float: left;
    }
    @include tablet-big {
      float: left;
    }
    .header_btn {
      @include mobile {
        border-top: 1px solid #dedede;
        justify-content: flex-start;
        padding-bottom: 5px;
      }
    }
    ul {
      position: static;
      box-shadow: none;
      padding-bottom: 0;
      @include mobile {
        padding-left: 41px;
      }
      @include tablet {
        margin-top: -10px;
      }
      @include tablet-big {
        margin-top: -10px;
      }
    }
    &:hover {
      ul {
        @include desktop {
          height: auto;
        }
      }
    }
  }
  .nav_wrapper {
    display: block;
    width: 100%;
    margin-left: 0;
    background: #fff;
    padding: 40px 0;
    @include tablet {
      padding: 0 0 30px 10px;
    }
    @include tablet-big {
      padding: 0 0 30px 10px;
    }
    > ul {
      width: 100%;
      flex-direction: column;
      overflow: hidden;
      > li {
        padding-right: 0;
        > a {
          font-size: 18px;
          font-weight: 500;
          border-bottom: 0;
          line-height: 1em;
          padding: 16px 0;
          @include mobile {
            font-size: 20px;
            padding: 13px 0;
          }
        }
      }
    }
  }
  &.second-menu--open {
    .nav_wrapper {
      position: relative;
      padding-bottom: 0;
      > ul {
        > li {
          &:not(.sub-menu--open) {
            display: none;
          }
          > a {
            display: none;
          }
        }
      }
    }
    .lang {
      @include mobile {
        height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
      }
    }
  }
  .sub_menu {
    height: 0;
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    border-top: 0;
    flex-direction: column;
    transform: translateX(-100%) !important;
    @include tablet {
      flex-direction: row;
    }
    @include tablet-big {
      flex-direction: row;
    }
    &--item {
      @include tablet {
        width: 100%;
        padding-right: 15px;
        padding-bottom: 30px;
        margin-right: 50px;
        margin-bottom: 50px;
        border-right: 1px solid #f1f1f1;
      }
      @include tablet-big {
        width: 100%;
        padding-right: 15px;
        padding-bottom: 30px;
        margin-right: 50px;
        margin-bottom: 50px;
        border-right: 1px solid #f1f1f1;
      }
    }
    &__close {
      width: auto;
      height: auto;
      position: static;
      background: url('./images/icon-arrow-left.svg') no-repeat left 5px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 12px;
      color: #2a6ebb;
      line-height: 1.4em;
    }
    &__info {
      width: 100%;
      margin-right: 0;
      h4 {
        font-size: 22px;
        font-weight: 500;
        @include mobile {
          margin-top: 10px;
          margin-bottom: 4px;
        }
        @include tablet {
          margin-top: 24px;
          margin-bottom: 15px;
        }
        @include tablet-big {
          margin-top: 24px;
          margin-bottom: 15px;
        }
      }
      p {
        font-size: 16px;
        @include mobile {
          display: none;
        }
      }
      a {
        @include mobile {
          font-weight: 500;
          font-size: 12px;
          padding-right: 14px;
          background-size: 8px;
        }
      }
    }
  }
  .sub-menu {
    width: 100%;
    margin-top: 0;
    @include mobile {
      margin-top: 35px;
    }
    @include tablet {
      margin-top: 8px;
    }
    li {
      width: 100%;
      margin-right: 0;
      border-bottom: 0;
      @include mobile {
        border-top: 1px solid #dedede;
      }
      @include tablet {
        padding: 0;
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 20px;
        }
      }
      @include tablet-big {
        padding: 0;
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  .sub-menu--animate {
    transform: translateX(-100%) !important;
    transition: $transition2;
  }
  .sub-menu--open {
    .sub_menu {
      height: auto;
      transform: translateX(0) !important;
    }
  }
}
