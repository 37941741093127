.m26 {
  width: 100%;
  padding: $vs-d 0;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  .column-6 {
    @include desktop {
      &:nth-child(2n + 1) {
        clear: left;
      }
    }
    @include tablet-big {
      &:nth-child(2n + 1) {
        clear: left;
      }
    }
    @include tablet {
      &:nth-child(2n + 1) {
        clear: left;
      }
    }
  }
  .column-mob-12 {
    &:nth-last-of-type(-n + 2) {
      .m26_team {
        @include desktop {
          margin-bottom: 0;
        }
        @include tablet-big {
          margin-bottom: 0;
        }
        @include tablet {
          margin-bottom: 0;
        }
      }
    }
    &:last-of-type {
      .m26_team {
        margin-bottom: 0;
        @include tablet {
          margin-bottom: 0;
        }
      }
    }
  }
  &_team {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 114px;
    @include mobile {
      margin-bottom: 100px;
      align-items: flex-start;
    }
    @include tablet {
      margin-bottom: 80px;
      align-items: flex-start;
      flex-direction: column;
    }
    &__image {
      width: 240px;
      height: 312px;
      @include mobile {
        width: 160px;
        height: 210px;
      }
      @include tablet {
        width: 190px;
        height: 250px;
        margin-bottom: 16px;
      }
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      width: calc(100% - 240px);
      padding-left: 24px;
      @include mobile {
        width: calc(100% - 160px);
        padding-left: 14px;
      }
      @include tablet {
        width: 100%;
        padding-left: 0;
      }
      h3 {
        font-size: 18px;
        font-weight: 800;
        line-height: em(24, 18);
        letter-spacing: 0.05px;
        color: #000;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: em(20, 14);
        color: #505050;
        margin-top: 8px;
      }
      ul {
        width: 100%;
        margin-top: 30px;
        @include mobile {
          margin-top: 18px;
        }
        @include tablet {
          margin-top: 20px;
        }
        li {
          font-size: 12px;
          font-weight: 500;
          line-height: em(18, 12);
          letter-spacing: 0.01px;
          color: #000;
          margin-bottom: 3px;
          b {
            font-weight: 800;
          }
          a {
            color: #2a6ebb;
            transition: $transition1;
            &:hover {
              color: #2a6ebb;
            }
          }
        }
      }
      .text-link {
        margin-top: 30px;
        @include tablet {
          margin-top: 12px;
        }
      }
    }
  }
}
