.m13 {
  background-color: #f5f5f5;
  padding: $vs-d 0;

  @include mobile {
    padding: $vs-m 0;
  }

  @include tablet {
    padding: $vs-t 0;
  }

  @include tablet-big {
    padding: $vs-t 0;
  }

  &-head {
    &__top-txt {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: em(16, 12);
      letter-spacing: 1.5px;
      margin-bottom: 9px;
      text-transform: uppercase;
    }
    &__title {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 20px;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 14px;
      }
      @include tablet {
        font-size: 27px;
        line-height: em(42, 27);
        margin-bottom: 14px;
      }
    }
  }

  &-body {
    &-slider {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;

      @include mobile {
        overflow: hidden;
        padding-left: 20px;
      }

      .slick-list {
        @include tablet {
          margin: 0 -16px;
        }
      }

      .slick-track {
        @include mobile {
          margin-left: 0;
        }

        @include tablet {
          margin-left: -243px;
        }
        @include desktop {
          margin-left: -467px;
        }
      }

      &_box {
        width: 256px;
        margin: 40px 35px 64px 0;

        @include tablet {
          width: 192px;
        }

        @include mobile {
          width: 202px;
          margin-right: 14px;
        }

        &__img {
          width: 100%;
          height: 331px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        a {
          width: 100%;
          display: block;
        }

        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: em(32, 22);
          letter-spacing: 0.05px;
          color: #1b1b1b;
          margin-top: 13px;
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: #505050;
        }
      }
    }

    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      bottom: 0;
      z-index: 2;
      transform: none;
      top: unset;
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center
        center;
      transition: $transition1;
      &:before {
        display: none;
      }
    }
    .slick-prev {
      left: 126px;
      transform: rotate(180deg);
      &:hover {
        transform: rotate(180deg) scale(1.1);
      }

      @include mobile {
        left: 20px;
      }
      @include tablet {
        left: 136px;
      }
    }
    .slick-next {
      left: 166px;
      &:hover {
        transform: scale(1.1);
      }

      @include mobile {
        left: 68px;
      }
      @include tablet {
        left: 180px;
      }
    }
    .slick-disabled {
      opacity: 0.3;
    }
  }
}
