.m11 {
  width: 100%;
  padding: $vs-d 0px;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  @include tablet-big {
    padding: $vs-t 0;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mobile {
      flex-direction: column;
    }
    &__left {
      max-width: 70%;
    }
    h5 {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: em(16, 12);
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 0;
      @include mobile {
        margin-bottom: 7px;
      }
      @include tablet {
        margin-bottom: 4px;
      }
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 0;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 14px;
      }
      @include tablet {
        font-size: 27px;
        line-height: em(42, 27);
        margin-bottom: 14px;
      }
    }
  }
  &__wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    @include mobile {
      overflow: hidden;
      padding-left: 20px;
    }

    .slick-list {
      @include tablet {
        margin: 0 -16px;
      }
    }

    .slick-track {
      @include mobile {
        margin-left: 0;
      }

      @include tablet {
        margin-left: -220px;
      }
      @include desktop {
        margin-left: -455px;
      }
    }
  }
  &_box {
    width: 278px;
    margin: 58px 16px 82px 0;

    @include mobile {
      width: 248px;
      margin: 33px 15px 0 0;
    }

    @include tablet {
      width: 244px;
      margin: 45px 15px 0 0;
    }

    &__img {
      width: 100%;
      height: 270px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      @include mobile {
        height: 170px;
      }
      @include tablet {
        height: 194px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__content {
      width: 100%;
      padding: 20px 24px;
      background-color: rgba($color: #0d1e5a, $alpha: 0.9);
      margin-top: -70px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      position: relative;
      z-index: 3;
      @include mobile {
        background-color: #152761;
        margin-top: 0;
      }
      @include tablet {
        margin-top: 0;
      }
    }
    a {
      width: 100%;
      display: block;
      text-decoration: none;
      color: #fff;
    }
    h4 {
      font-weight: 800;
      font-size: 18px;
      line-height: em(24, 18);
      letter-spacing: 0.05px;
      color: #fff;
      margin-bottom: 13px;
    }
    span {
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: em(18, 12);
      letter-spacing: 0.1px;
      color: #fff;
      margin-bottom: 14px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: em(24, 16);
      color: #fff;
    }
  }

  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 126px;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }

    @include mobile {
      left: 20px;
    }
    @include tablet {
      left: 136px;
    }
  }
  .slick-next {
    left: 166px;
    &:hover {
      transform: scale(1.1);
    }

    @include mobile {
      left: 68px;
    }
    @include tablet {
      left: 180px;
    }
  }
  .slick-disabled {
    opacity: 0.3;
    pointer-events: none !important;
  }
}
