.m32 {
  overflow: hidden;
  padding: 150px 0;
  &-text {
    font-size: 18px;
    letter-spacing: 0.05;
    line-height: em(24, 18);
    font-weight: bold;
  }

  .container {
    @include tablet {
      margin-left: 0 !important;
    }

    @include mobile {
      margin-left: 0 !important;
    }
  }

  &-slider {
    max-width: 1440px;
    width: 100vw;
    margin-top: 23px;

    .slick-slide {
      margin-right: 50px;
    }
  }
}
