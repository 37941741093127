.m33 {
  width: 100%;
  padding: $vs-d 0;
  background-color: #f1f1f1;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  &_top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    &__left {
      width: 710px;
      @include mobile {
        width: 100%;
        margin-bottom: 24px;
      }
      @include tablet {
        width: 100%;
        margin-bottom: 24px;
      }
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: em(24, 18);
      letter-spacing: 0.05px;
      color: #000;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: em(24, 18);
      color: #000;
    }
  }
  &_quote {
    width: 100%;
    padding-left: 100px;
    @include mobile {
      padding-left: 54px;
    }
    &__text {
      position: relative;
      max-width: 670px;
      text-align: justify;
      @include tablet {
        max-width: 400px;
      }

      &:after {
        content: '';
        width: 37px;
        height: 20px;
        display: inline-block;
        background: url('./images/quotationsmarks.svg') no-repeat center;
        background-size: cover;
        position: absolute;
        top: 10px;
        left: -55px;
      }
      p {
        max-width: 100%;
        font-size: 22px;
        font-weight: normal;
        font-style: italic;
        line-height: em(36, 22);
        padding: 0;
        @include mobile {
          font-size: 20px;
          line-height: em(28, 20);
        }
      }
    }
  }
  &_author {
    max-width: 320px;
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    &__info {
      width: calc(100% - 120px);
      padding-right: 40px;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: em(24, 16);
        color: #000;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__avatar {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
      img {
        @extend %imgCropped;
      }
    }
  }
}
