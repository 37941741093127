.m43 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 35px;
  margin-bottom: 110px;
  @include mobile {
    margin-bottom: 90px;
  }
  &_wrapper {
    max-width: 770px;
    width: 100%;
    margin-left: auto;
  }
  p {
    font-size: 16px;
    line-height: em(24, 16);
    font-weight: 500;
    color: #000000;
  }
  &__right {
    width: calc(100% - 450px);
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 80%;
    }
    &.m43-slider {
      margin-right: -50%;
      width: 100vw !important;
      position: relative;
      margin-left: -50%;
      left: 50%;
      max-width: none;
      padding-bottom: 24px;
    }
    .slick-slide {
      width: 760px;
      outline: 0;
      margin-right: 15px;
      @include mobile {
        width: 320px;
      }
      @include tablet {
        width: 500px;
        margin-right: 23px;
      }
    }
    img {
      width: 100%;
      height: 480px;
      object-fit: cover;
      @include mobile {
        height: 190px;
      }
      @include tablet {
        height: 295px;
      }
    }
    &__bottom {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      line-height: em(18, 12);
      color: #747474;
      margin-top: 15px;
      justify-content: space-between;
      span {
        font-weight: 500;
        white-space: nowrap;
        margin-left: 15px;
        color: #0d1e5a;
      }
    }
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      bottom: 57px;
      z-index: 2;
      transform: none;
      top: unset;
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center
        center;
      transition: $transition1;
      &:before {
        display: none;
      }
      @include mobile {
        display: none !important;
      }
      @include tablet {
        display: none !important;
      }
    }
    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }
    .slick-prev {
      left: -92px;
      transform: rotate(180deg);
      &:hover {
        transform: rotate(180deg) scale(1.1);
      }
    }
    .slick-next {
      left: -48px;

      &:hover {
        transform: scale(1.1);
      }
    }
    .slick-disabled {
      opacity: 0.3;
    }
  }
}
.single-events {
  .m43 {
    margin-top: 120px;
    @include mobile {
      margin-top: 50px;
    }
    @include tablet {
      margin-top: 80px;
    }
  }
  .m43__right.m43-slider {
    padding-bottom: 60px;
    @include mobile {
      padding-bottom: 0;
    }
    @include tablet {
      padding-bottom: 0;
    }
  }
  .m43_wrapper {
    max-width: 890px;
  }

  .slick-prev,
  .slick-next {
    bottom: 0;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    left: 40px;
  }
}
