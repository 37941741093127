.m10 {
  width: 100%;
  padding: $vs-d 0px;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    &__left {
      max-width: 70%;
      @include mobile {
        max-width: 100%;
      }
      @include tablet {
        max-width: 100%;
      }
    }
    h5 {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: em(16, 12);
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 0;
      @include mobile {
        margin-bottom: 7px;
      }
      @include tablet {
        margin-bottom: 4px;
      }
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 0;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 14px;
      }
      @include tablet {
        font-size: 27px;
        line-height: em(42, 27);
        margin-bottom: 14px;
      }
    }
  }
  &_box {
    width: 100%;
    margin: 42px 0 0;
    @include mobile {
      margin: 30px 0 0;
    }
    @include tablet {
      margin: 40px 0 0;
    }
    a {
      width: 100%;
      display: flex;
      background-color: #fff;
      border-radius: 8px;
      padding: 32px 27px 60px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transition: $transition1;
      @include mobile {
        flex-direction: column;
        padding: 32px 22px;
      }
      @include tablet {
        flex-direction: column;
        padding: 32px 22px;
      }
      @include tablet-big {
        flex-direction: column;
        padding: 32px 22px;
      }
      &:hover {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.31);
      }
      @media only screen and (max-width: 340px) {
        padding: 32px 18px;
      }
    }
    &__icon {
      width: 55px;
      @include mobile {
        width: 46px;
        margin-bottom: 10px;
      }
      @include tablet {
        margin-bottom: 6px;
        width: 46px;
      }
      @include tablet {
        margin-bottom: 10px;
      }
    }
    &__content {
      width: calc(100% - 45px);
      padding-left: 16px;
      @include mobile {
        width: calc(100% - 25px);
        padding-left: 0px;
      }
      @include tablet {
        padding-left: 0px;
      }
      @include tablet-big {
        padding-left: 0px;
      }
      h4 {
        font-weight: 800;
        font-size: 22px;
        line-height: em(32, 22);
        letter-spacing: 0.05px;
        color: #1b1b1b;
        margin-bottom: 16px;
        min-height: 64px;
        @include mobile {
          margin-bottom: 12px;
          line-height: em(32, 22);
          align-items: flex-start;
          flex-direction: column;
          display: flex;
          justify-content: center;
        }
        @include tablet {
          min-height: unset;
          margin-bottom: 8px;
        }
        @include tablet-big {
          min-height: unset;
          margin-bottom: 8px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000000;
        margin-bottom: 2px;
        @media (min-width: 1200px) {
          height: 120px;
        }
      }
    }
  }
  .benefits-slider {
    .slick-list {
      padding-bottom: 64px !important;
    }
    .slick-track {
      @include mobile {
        margin-left: -40px;
      }
      @include tablet {
        margin-left: -350px;
      }
    }
    .column-4 {
      @include tablet {
        // width: 350px !important;
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 8px;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
  .slick-next {
    left: 50px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-disabled {
    opacity: 0.3;
    pointer-events: none !important;
  }
}
