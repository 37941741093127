.m18 {
  width: 100%;
  padding: $vs-d 0;
  background-color: #2a6ebb;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  @include tablet-big {
    padding: $vs-t 0;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    @include tablet-big {
      flex-direction: column;
    }
  }
  &__left {
    width: calc(100% - 240px);
    padding-right: 172px;
    @include mobile {
      width: 100%;
      padding-right: 0;
    }
    @include tablet {
      width: 100%;
      padding-right: 0;
    }
    @include tablet-big {
      width: 100%;
      padding-right: 0;
    }
    h5 {
      font-size: 12px;
      font-weight: 500;
      line-height: em(16, 12);
      letter-spacing: 0.15px;
      color: rgba(#ffffff, 0.6);
      text-transform: uppercase;
      margin-bottom: 16px;
      @include mobile {
        font-size: 8px;
        line-height: em(16, 8);
        margin-bottom: 10px;
      }
      @include tablet {
        font-size: 8px;
        line-height: em(16, 8);
        margin-bottom: 10px;
      }
      @include tablet-big {
        font-size: 8px;
        line-height: em(16, 8);
        margin-bottom: 10px;
      }
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 26px;
      line-height: em(38, 26);
      color: #fff;
      margin-bottom: 25px;
      @include mobile {
        font-size: 21px;
        line-height: em(28, 21);
        margin-bottom: 30px;
      }
      @include tablet {
        font-size: 21px;
        line-height: em(28, 21);
        letter-spacing: 0.7px;
        margin-bottom: 30px;
      }
      @include tablet-big {
        font-size: 21px;
        line-height: em(28, 21);
        letter-spacing: 0.7px;
        margin-bottom: 30px;
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: em(24, 16);
      color: #fff;
      @include mobile {
        font-size: 12px;
        line-height: em(18, 12);
        margin-bottom: 50px;
      }
      @include tablet {
        font-size: 12px;
        line-height: em(18, 12);
        margin-bottom: 40px;
      }
      @include tablet-big {
        font-size: 12px;
        line-height: em(18, 12);
        margin-bottom: 40px;
      }
    }
  }
  &__right {
    width: 240px;
    @include mobile {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    @include tablet {
      width: 100%;
      display: flex;
    }
    @include tablet-big {
      width: 100%;
      display: flex;
    }
    &__image {
      width: 150px;
      height: 150px;
      @include mobile {
        width: 100px;
        height: 100px;
      }
      border: 2px solid #fff;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 13px;
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      @include mobile {
        width: calc(100% - 110px);
      }
      @include tablet {
        padding-left: 24px;
      }
      @include tablet-big {
        padding-left: 24px;
      }
      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: em(24, 18);
        letter-spacing: 0.05px;
        color: #fff;
        margin-bottom: 5px;
        @include mobile {
          font-size: 22px;
          line-height: em(32, 22);
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #fff;
        margin-bottom: 2px;
      }
      a {
        display: block;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #fff;
      }
    }
  }

  &.gray {
    background-color: #f1f1f1;
    h3,
    h4,
    p,
    a {
      color: #000 !important;
    }
    h5 {
      color: rgba(#000, 0.6);
    }
  }
}
