.main_footer {
  padding: 70px 24px 45px;
  background-color: #0d1e5a;
  @include mobile {
    padding: 100px 16px 97px;
  }
  @include tablet {
    padding: 117px 32px 60px;
  }
}
.footerWrap {
  max-width: 1220px;
  margin: 0 auto;
  float: none;
}
.footer__main {
  @include desktop {
    padding-bottom: 20px;
  }
}
.footer__about {
  padding-right: 20px;
  @include mobile {
    padding-right: 0;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: em(21, 14);
    @include tablet {
      max-width: 65%;
    }
  }
}
.footer__bottom {
  width: 100%;
  padding-top: 17px;
  border-top: 1px solid #c2d1d9;
  display: flex;
  justify-content: space-between;
  @include mobile {
    padding-top: 42px;
    flex-direction: column;
    border-top-color: rgba(255, 255, 255, 0.3);
  }
  @include tablet {
    width: auto;
    padding-top: 0;
    border-top: 0;
    display: inline-block;
    float: right;
    margin-top: -50px;
  }
  a {
    font-size: 12px;
    font-weight: 500;
    line-height: em(18, 12);
    color: #d9d9d9;
    transition: $transition1;
    &:hover {
      opacity: 0.8;
    }
    @include tablet {
      display: none;
    }
  }
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: em(18, 12);
    color: #fff;
    letter-spacing: -0.21px;
    @include mobile {
      margin-top: 20px;
    }
  }
}
.footer_menu {
  width: 100%;
  @include mobile {
    margin-bottom: 47px;
  }
  @include tablet {
    margin-bottom: 30px;
  }
  h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: em(24, 18);
    color: #fff;
    margin-bottom: 29px;
  }
  li {
    width: 100%;
    a {
      &:after {
        content: '';
        width: 18px;
        height: 8px;
        display: inline-block;
        background: url('./images/icon-arrow-right-white-2.svg') no-repeat right
          center;
        background-size: 9px;
        opacity: 0;
        transition: $transition1;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  a {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: em(21, 14);
    color: #d9d9d9;
    padding-right: 16px;
    margin-bottom: 21px;
    transition: $transition1;
    &:hover {
      color: #fff;
    }
  }
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: em(21, 14);
    color: #fff;
  }
}
.footer__social {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  @include mobile {
    padding-bottom: 48px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  @include tablet {
    margin-top: 13px;
    padding-bottom: 50px;
    margin-bottom: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  a {
    margin-right: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
}

#cookie-notice a {
  color: white;
  text-decoration: underline;
}