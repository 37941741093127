.m24 {
  width: 100%;
  padding: $vs-d 0;
  @include mobile {
    padding: 0 0 $vs-m;
  }
  @include tablet {
    padding: 0 0 $vs-t;
  }
  &__inner {
    width: 100%;
    display: flex;
  }
  &__left {
    width: 670px;
    padding-left: 40px;
    @include mobile {
      width: 100%;
      padding-left: 0;
      padding-top: 140px;
    }
    @include tablet {
      width: 100%;
      padding-left: 0;
      padding-top: 50px;
    }
  }
  &__item {
    width: 100%;
    padding-top: 40px;
    margin-bottom: 96px;
    @include mobile {
      padding-top: 50px;
      margin-bottom: 0;
    }
    @include tablet {
      padding-top: 120px;
      margin-bottom: 0;
    }
    &__inner {
      width: 100%;
      position: relative;
      padding: 58px 55px 54px 98px;
      border-left: 5px solid #0d1e5a;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.15);
      @include mobile {
        border-radius: 10px;
        padding: 43px 32px 58px 21px;
      }
      @include tablet {
        border-radius: 10px;
        padding: 43px 32px 58px 21px;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &__number {
      width: 80px;
      height: 80px;
      position: absolute;
      left: -40px;
      top: -40px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 2px solid #0d1e5a;
      border-radius: 100%;
      font-family: 'Noah Head';
      font-size: 26px;
      font-weight: 500;
      color: #0d1e5a;
      @include mobile {
        width: 40px;
        height: 40px;
        left: 21px;
        top: 43px;
        font-size: 18px;
        padding-top: 2px;
        font-weight: 600;
      }
      @include tablet {
        width: 60px;
        height: 60px;
        left: -30px;
        top: -30px;
        font-size: 24px;
      }
    }
    &__top {
      width: 100%;
      margin-bottom: 40px;
      padding-right: 115px;
      @include mobile {
        padding-right: 0;
      }
      h3 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 26px;
        line-height: em(38, 26);
        color: #000;
        margin-bottom: 24px;
        @include mobile {
          min-height: 40px;
          font-size: 24px;
          line-height: em(34, 24);
          word-break: break-all;
          margin-bottom: 19px;
          padding-left: 54px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        margin-bottom: 0;
      }
    }
    ul {
      width: 100%;
      li {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000;
        position: relative;
        padding-left: 14px;
        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: #2a6ebb;
          border-radius: 100%;
          position: absolute;
          top: 8px;
          left: 0;
        }
      }
    }
    &__bottom {
      width: 100%;
      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: em(20, 14);
        color: #505050;
        margin-bottom: 8px;
      }
    }
    &__box {
      width: 100%;
      display: flex;
      border-top: 1px solid #0d1e5a;
      padding-top: 16px;
      &__image {
        width: 100px;
        padding-right: 16px;
        @include mobile {
          height: 40px;
          width: auto;
        }
        img {
          float: right;
          width: auto;
          object-fit: contain;
          @include mobile {
            max-height: 100%;
          }
        }
      }
      &__content {
        width: calc(100% - 100px);
        @include mobile {
          width: calc(100% - 40px);
        }
        h4 {
          font-size: 22px;
          font-weight: 500;
          line-height: em(32, 22);
          color: #1b1b1b;
          margin-bottom: 8px;
        }
      }
    }
  }
  &__right {
    width: calc(100% - 670px);
    @include mobile {
      width: 100%;
      margin-left: -100%;
    }
    @include tablet {
      width: 100%;
      margin-left: -100%;
    }
    ul {
      float: right;
      width: 280px;
      padding: 32px 24px 40px 24px;
      background-color: #f1f1f1;
      position: -webkit-sticky;
      position: sticky;
      top: 130px;
      @include mobile {
        width: calc(100% + 30px);
        margin-right: -16px;
        display: flex;
        padding: 0px 16px;
        overflow-x: scroll;
        z-index: 4;
        top: 0;
      }
      @include tablet {
        width: 100vw;
        margin-right: -42px;
        display: flex;
        padding: 0px 42px;
        overflow-x: scroll;
        z-index: 4;
        top: 0;
      }
      &:after {
        content: '';
        background-color: #f1f1f1;
        height: 100%;
        width: 100vw !important;
        position: absolute;
        left: 100%;
        max-width: none;
        z-index: 3;
        top: 0;
        @include mobile {
          display: none;
        }
        @include tablet {
          display: none;
        }
      }
      li {
        width: 100%;
        display: flex;
        position: relative;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 600;
        line-height: em(24, 18);
        color: #2a6ebb;
        @include mobile {
          margin-bottom: 0;
          padding: 30px 0 37px 0;
          margin-right: 48px;
        }
        @include tablet {
          margin-bottom: 0;
          padding: 30px 0 37px 0;
          margin-right: 48px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #0d1e5a;
        }
        &:before {
          content: '';
          width: 8px;
          height: 40px;
          background-color: #ffca00;
          position: absolute;
          left: -27px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: $transition1;
          @include mobile {
            height: 8px;
            width: 100%;
            top: unset;
            bottom: -4px;
            left: 0;
          }
          @include tablet {
            height: 8px;
            width: 100%;
            top: unset;
            bottom: -4px;
            left: 0;
          }
        }
        &.is--active {
          color: #000000;
          &:before {
            opacity: 1;
          }
        }
        a {
          color: inherit;
          transition: $transition1;
          @include mobile {
            white-space: nowrap;
          }
          @include tablet {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
