.m39 {
  width: 100%;
  padding: 50px 0;
  @include tablet {
    padding: 70px 0;
  }
  &_wrapper {
    width: 100%;
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &_left {
    width: calc(100% - 768px);
    padding-right: 70px;
    position: relative;
    @include mobile {
      width: 100%;
      padding-right: 0;
    }
    @include tablet {
      width: 322px;
      padding-right: 120px;
    }
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #0d1e5a;
      position: absolute;
      top: 152px;
      left: 0;
      @include mobile {
        display: none;
      }
      @include tablet {
        width: 1px;
        height: 100%;
        top: 21px;
        left: -20px;
      }
    }
    &__top {
      width: 100%;
      margin-bottom: 35px;
      margin-top: 35px;
      @include mobile {
        margin-top: 0;
        margin-bottom: 24px;
      }
      @include tablet {
        margin-top: 15px;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: em(20, 14);
        color: #505050;
      }
      span {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        line-height: em(18, 12);
        letter-spacing: 0.1px;
        padding-left: 30px;
        margin-top: 10px;
        position: relative;
        @include mobile {
          display: none;
        }
        &:before {
          content: '';
          width: 11px;
          height: 11px;
          border-radius: 100%;
          background-color: #ffca00;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &__bottom {
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
    }
  }
  &_right {
    width: 768px;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
    img {
      @include tablet {
        width: 100%;
      }
    }
  }
}
