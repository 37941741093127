.m37 {
  width: 100%;
  padding-top: 30px;
  @include mobile {
    padding-top: 0;
  }
  &_item {
    width: 100%;
    padding: $vs-d 0;
    border-bottom: 1px solid #959595;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    @include mobile {
      padding: 80px 0;
    }
    @include tablet {
      padding: $vs-t 0;
    }
    h3 {
      font-family: 'Noah Head';
      font-size: 40px;
      line-height: em(54, 40);
      font-weight: 700;
      letter-spacing: -0.1px;
      margin-bottom: 25px;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 20px;
      }
    }
    &__bottom {
      width: 100%;
      display: flex;
      @include mobile {
        flex-direction: column-reverse;
      }
    }
    &__content {
      width: 850px;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 460px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: em(24, 16);
        letter-spacing: 0.001em;
        color: #000;
        margin-bottom: 16px;
      }
      a {
        font-size: inherit;
        font-weight: 500;
        color: #2a6ebb;
        transition: $transition1;
        &:hover {
          color: #0d1e5a;
        }
      }
    }
    &__excerpt {
      p {
        font-size: 24px;
        font-weight: normal;
        line-height: em(40, 24);
        color: #000;
        letter-spacing: 0.05px;
        margin-bottom: 16px;
      }
    }
    &__image {
      width: calc(100% - 850px);
      padding-left: 35px;
      @include mobile {
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px;
        text-align: center;
      }
      @include tablet {
        width: calc(100% - 460px);
        padding-left: 30px;
      }
      img {
        float: left;
        @include mobile {
          float: none;
        }
        @include tablet {
          float: right;
        }
      }
    }
    &__buttons {
      display: inline-block;
      @include mobile {
        margin-top: 15px;
      }
      .m37_button {
        margin-top: 35px;
        margin-right: 16px;
        float: left;
        padding: 8px 20px 10px 15px;
        border: 1px solid #fff;
        border-radius: 22px;
        background-color: #2a6ebb;
        font-weight: 800;
        font-size: 16px;
        line-height: em(24, 16);
        letter-spacing: 0.3px;
        color: #fff;
        text-transform: uppercase;
        transition: $transition1;
        display: block;
        @include mobile {
          margin-top: 15px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 12px;
          background: url('./images/icon-arrow-right-white.svg') no-repeat
            center;
          margin-right: 16px;
          transition: $transition1;
        }
        &:hover {
          background-color: #2a6ebb;
          color: #fff;
          &:before {
            margin-right: 11px;
            margin-left: 5px;
            background: url('./images/icon-arrow-right-white.svg') no-repeat
              center;
          }
        }
      }

      .m37_button--outlined {
        border-color: #2a6ebb;
        background-color: transparent;
        color: #2a6ebb;
        &:before {
          background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
        }
        &:hover {
          color: #2a6ebb;
          background: transparent;
          &:before {
            margin-right: 11px;
            margin-left: 5px;
            background: url('./images/icon-arrow-right-blue.svg') no-repeat
              center;
          }
        }
      }
    }
  }
}
