.m21 {
  padding-top: 100px;
  padding-bottom: $vs-d;
  @include mobile {
    padding-top: 0;
    padding-bottom: $vs-m;
  }
  @include tablet {
    padding-top: 0;
    padding-bottom: $vs-t;
  }

  @keyframes m21FadeEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__top {
    max-width: 670px;
    margin-bottom: 60px;
    h3 {
      font-family: 'Noah Head';
      font-size: 32px;
      line-height: em(42, 32);
      font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      @include mobile {
        font-size: 26px;
        line-height: em(32, 26);
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      color: #000;
      @include mobile {
        font-weight: 400;
      }
    }
  }
  .gform_wrapper {
    width: 100%;
    float: left;
  }
  .gform_body {
    float: left;
    width: 100%;
  }
  .gfield_required {
    display: none;
  }
  .gfield_label {
    font-weight: 500;
    font-size: 18px;
    line-height: em(24, 18);
    letter-spacing: 0.05px;
    color: #000000;
  }
  .gfield_radio {
    display: flex;
    input[type='radio']:checked + label:after {
      opacity: 1;
    }
    label {
      display: flex;
      align-items: center;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #000000;
        margin-right: 9px;
      }
      &:after {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #000;
        opacity: 0;
        position: absolute;
        left: 5px;
      }
    }
    li {
      position: relative;
      margin-top: 34px;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
  .gform_ajax_spinner {
    display: none;
  }
  .gfield {
    width: 100%;
    float: left;
    margin-bottom: 30px;
    input {
      border: 0;
      outline: none;
      width: 100% !important;
      border-bottom: 1px solid #959595;
      color: #959595;
      font-size: 16px;
      font-weight: 500;
      padding: 0 !important;
      padding-bottom: 2px !important;
      line-height: em(24, 16);
      @include placeholder {
        color: #959595;
        font-size: 16px;
        line-height: em(24, 16);
        font-weight: 500;
      }
    }
    input[type='checkbox']{
      width: initial!important;
      margin-top: 12px!important;
    }
    select {
      border: 0;
      outline: none;
      width: 100% !important;
      border-bottom: 1px solid #959595;
      color: #959595;
      font-size: 16px;
      font-weight: 500;
      padding: 0 !important;
      padding-bottom: 6px !important;
      option {
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        padding: 0 !important;
        margin-left: 0 !important;
      }
    }
    textarea {
      border: 0;
      outline: none;
      width: 100%;
      height: 252px !important;
      font-size: 16px;
      font-weight: 500;
      color: #959595;
      border-bottom: 1px solid #959595;
      padding: 0;
      @include placeholder {
        font-weight: 500;
        font-size: 16px;
        color: #959595;
      }
      @include mobile {
        height: 120px !important;
      }
      @include tablet {
        height: 120px !important;
      }
    }
    .gform_button_select_files {
      position: relative;
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #959595;
      border-radius: 8px;
      background-color: transparent;
      font-size: 0;
    }
    .gform_drop_instructions {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      user-select: none;

      font-size: 16px;
      line-height: em(24, 16);
      font-weight: 500;
      color: #959595;
      white-space: nowrap;

      background-image: url('./images/upload_icon.svg');
      background-position: left;
      background-size: 21px;
      background-repeat: no-repeat;
      padding-left: 36px;
    }
  }
  .screen-reader-text {
    display: none;
  }
  .gfield_description:not(.validation_message) {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #000000;
    max-width: 540px;
    margin-top: 5px;
  }
  .validation_message {
    bottom: calc(-100% + 5px);
  }
  .ginput_container_fileupload {
    margin-bottom: 32px;
    margin-top: 20px;
  }
  .gfield.gfield_container_textearea_holder {
    float: none;
    width: 50%;
    display: inline-block;
    padding-left: 16px;
    @include mobile {
      width: 100%;
      padding-left: 0;
    }
    @include tablet {
      width: 100%;
      padding-left: 0;
    }
    .validation_message {
      bottom: -20px;
      padding-left: 16px;
      @include mobile {
        padding-left: 0;
      }
      @include tablet {
        padding-left: 0;
      }
    }
  }
  .gfield.gfield_container_file_holder {
    margin-bottom: 0;
    .validation_message {
      bottom: 8px;
    }
  }
  .gfield_container_radio_holder {
    .validation_message {
      bottom: -22px;
    }
  }
  li.gfield:not(.gfield_container_radio_holder):not(.gfield_container_textearea_holder):not(.gfield_container_file_holder) {
    float: left;
    width: 50%;
    display: inline-block;
    clear: left;
    @include mobile {
      width: 100%;
      padding-right: 0 !important;
    }
    @include tablet {
      width: 100%;
      padding-right: 0 !important;
    }
  }
  .m21-tab-content--1 {
    textarea {
      height: 197px !important;
      @include mobile {
        height: 120px !important;
      }
      @include tablet {
        height: 120px !important;
      }
    }
  }
  .gfield_error {
    textarea,
    select,
    input {
      border-color: #ea0f0f !important;
    }
  }

  &-text,
  .m21-content-holder p,
  .m21-content-holder li {
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    color: #000000;
  }

  .m21-content-holder p {
    margin-bottom: 30px;
  }

  &-text-lg {
    font-size: 18px;
    line-height: em(24, 18);
    letter-spacing: 0.05px;
    color: #000000;
    font-weight: 500;
  }

  &-mb-30 {
    margin-bottom: 30px;
  }

  &-link,
  .m21-content-holder a {
    font-weight: 600;
    color: #2a6ebb;
    &:hover {
      color: #2363ac;
    }
  }

  &-title {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 32px;
    color: #000000;
    line-height: em(42, 32);
    @include mobile {
      font-size: 22px;
      line-height: em(30, 22);
      margin-bottom: 14px;
    }
    @include tablet {
      font-size: 22px;
      line-height: em(30, 22);
      margin-bottom: 14px;
    }
  }

  .m21-content-holder {
    ul {
      padding-left: 30px;
      li {
        margin-bottom: 5px;
      }
      li::before {
        content: '\2022';
        font-weight: 800;
        display: inline-block;
        width: 1em;
        color: #2a6ebb;
      }
    }
  }

  &-tab-img {
    width: 312px;
    height: 214px;
    object-fit: contain;
    margin: 60px auto;
    display: flex;
  }

  &-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    @include mobile {
      width: 100%;
      overflow-x: auto;
      // padding-left: 100%;
      margin-bottom: 30px;
    }
    &-tab {
      flex: 1;

      font-size: 24px;
      line-height: em(34, 24);
      letter-spacing: 0.05px;
      font-weight: 400;
      color: #1b1b1b;

      cursor: pointer;
      padding-bottom: 12px;
      border-bottom: 2px solid #707070;

      &:first-child {
        margin-right: 16px;
      }

      &:hover {
        color: #707070;
      }

      &[data-active='true'] {
        border-bottom-color: #ffca00;
      }

      @include mobile {
        font-size: 16px;
        font-weight: 500;
        line-height: em(30, 16);
        margin-right: 16px !important;
        white-space: nowrap;
        &:first-child {
          flex: 2;
        }
        &:last-child {
          margin-right: 0 !important;
        }
      }

      @include tablet {
        font-size: 22px;
        line-height: em(30, 22);
      }
    }
  }

  &-tab-content {
    animation: m21FadeEffect 1s;
    display: none;
    &[data-active='true'] {
      display: inherit;
    }

    &--1,
    &--2 {
      .m21-content-holder ul {
        margin-bottom: 30px;
      }
    }

    &--1 {
      .form-textarea {
        min-height: 251px;

        @include tablet {
          min-height: 130px !important;
        }
      }
    }

    &--2 {
      .m21-request > .m21-title {
        margin-bottom: 78px;
      }
      .form-textarea {
        min-height: 198px;
        @include tablet {
          min-height: 130px !important;
        }
      }
    }
  }

  &-request {
    margin-top: 100px;
    @include mobile {
      margin-top: 0;
    }
    @include tablet {
      margin-top: 0;
    }
    .m21-title {
      margin-bottom: 25px;
    }

    &__info {
      max-width: 278px;

      p:first-child {
        margin-bottom: 50px;
      }
      p:nth-child(2) {
        max-width: 217px;
        @include mobile {
          max-width: 260px;
        }
      }

      p:nth-child(3) {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      @include tablet {
        max-width: 400px;
        margin-bottom: 50px;
      }

      @include mobile {
        max-width: 100%;
        margin-bottom: 50px;
      }
    }
  }

  &-therapy-request-form {
    &-radios {
      display: flex;
      margin-bottom: 38px;
      label:first-child {
        margin-right: 50px;
      }
    }

    .form-group {
      margin-bottom: 30px;
    }
  }

  .file-uploader {
    margin-top: 22px;
  }
  .gform_footer {
    position: relative;
    float: left;
    width: 100%;
  }
  // .m31-alert {
  //   display: none !important;
  // }
  .m31-button-reset {
    position: absolute;
    right: 180px;
    top: 20px;
    @include mobile {
      top: 25px;
    }
  }
  .gform_button,
  &-button {
    margin-top: 10px;
    float: right;
    padding: 10px 20px 8px 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #2a6ebb;
    font-weight: 500;
    font-size: 16px;
    line-height: em(24, 16);
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    transition: $transition1;
    display: block;
    cursor: pointer;
    outline: none;
    @include mobile {
      margin-top: 15px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      margin-right: 16px;
      transition: $transition1;
    }
    &:hover {
      background-color: #2a6ebb;
      color: #fff;
      &:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
    }
  }

  &-submit-button {
    display: flex;
    justify-content: flex-end;
  }

  .form-form {
    display: none;
  }
}
