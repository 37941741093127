.m07 {
  width: 100%;
  padding: $vs-d 0 126px 0;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  &__inner {
    padding-right: 230px;
    @include mobile {
      padding-right: 0;
    }
    @include tablet {
      padding-right: 130px;
    }
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    color: #747474;
    line-height: em(16, 12);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0;
    @include mobile {
      margin-bottom: 7px;
    }
    @include tablet {
      margin-bottom: 4px;
    }
  }
  h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: em(54, 40);
    letter-spacing: -1px;
    margin-bottom: 0;
    @include mobile {
      font-size: 30px;
      line-height: em(42, 30);
      margin-bottom: 20px;
    }
    @include tablet {
      font-size: 27px;
      line-height: em(42, 27);
      margin-bottom: 56px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    margin-top: 32px;
    color: #000;
    &:not(.full) {
      column-count: 2;
      column-gap: 16px;
      @include mobile {
        column-count: unset;
        column-gap: 0;
      }
      @include tablet {
        column-count: unset;
        column-gap: 0;
      }
    }
    &.full {
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.5px;
      padding-right: 100px;
      margin-top: 64px;
      @include mobile {
        font-size: 22px;
        line-height: em(32, 22);
        letter-spacing: 0.25px;
        padding-right: 0;
        margin-top: 28px;
      }
      @include tablet {
        padding-right: 0;
        margin-top: 0;
      }
    }
  }
  a {
    font-weight: 800;
    font-size: 15px;
    line-height: em(16, 15);
    letter-spacing: 1.33px;
    color: #2a6ebb;
    text-transform: uppercase;
    border-bottom: 2px solid #2a6ebb;
    margin-top: 32px;
    display: inline-block;
    transition: $transition1;
    &:hover {
      color: #0d1e5a;
      border-color: #0d1e5a;
    }
    &.is--button {
      float: left;
      // padding: 10px ​20px 9px 15px;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 9px;
      padding-left: 15px;
      color: #fff;
      border: 1px solid #2a6ebb;
      border-radius: 22px;
      font-weight: 800;
      font-size: 16px;
      line-height: em(24, 16);
      letter-spacing: 0.3px;
      background-color: #2a6ebb;
      text-transform: uppercase;
      transition: $transition1;
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
        margin-right: 16px;
        transition: $transition1;
      }
      &:hover {
        background-color: transparent;
        color: #2a6ebb;
        &:before {
          margin-right: 11px;
          margin-left: 5px;
          background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
        }
      }
    }
  }
}
