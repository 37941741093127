.m52 {
  iframe {
    height: 800px;
    @media (max-width: 700px) {
      height: 1000px;
    }
    &.iframe-content {
      height: 2000px;
      @media (max-width: 700px) {
        height: 2600px;
      }
    }
    &.iframe-content2 {
      height: 1300px;
      @media (max-width: 700px) {
        height: 1500px;
      }
    }
  }
}