.m28 {
  width: 100%;
  padding-bottom: $vs-d;
  margin-top: -250px;
  @include mobile {
    padding-bottom: $vs-m;
    margin-top: 0;
  }
  @include tablet {
    padding-bottom: $vs-t;
    margin-top: 0;
  }
  &_content {
    width: 100%;
    max-width: 770px;
    padding-left: 100px;
    display: flex;
    flex-wrap: wrap;
    @include mobile {
      padding-left: 0;
    }
    @include tablet {
      padding-left: 0;
    }
  }
  &_block_wrapper {
    width: 100%;
    margin-bottom: 60px;
    h3 {
      font-size: 22px;
      line-height: em(32, 22);
      letter-spacing: 0.05px;
      font-weight: 500;
      color: #000;
      padding-left: 99px;
      @include mobile {
        padding-left: 0;
      }
      @include tablet {
        padding-left: 0;
      }
    }
  }
  &_block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    @include mobile {
      margin-top: 16px;
      flex-direction: column;
    }
    @include tablet {
      margin-top: 16px;
    }
    &__left {
      width: 100px;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      span {
        font-size: 16px;
        line-height: em(24, 16);
        font-weight: 500;
        color: #000;
      }
    }
    &__right {
      width: calc(100% - 100px);
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      p {
        font-size: 16px;
        line-height: em(24, 16);
        font-weight: 500;
        color: #000;
      }
    }
  }
  &_arrow {
    width: 36px;
    height: 36px;
    border: 1px solid #2a6ebb;
    border-radius: 36px;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
  }
  &_windows {
    width: 25px;
    height: 25px;
    display: flex;
    background: url('./images/icon-windows.svg') no-repeat center center;
  }
  &_footer {
    width: 100%;
    border-top: 2px solid #dcdcdc;
    padding-top: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      letter-spacing: 0.3px;
      color: #2a6ebb;
      margin-bottom: 4px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: em(20, 14);
      color: #505050;
      max-width: 200px;
    }
    &__left {
      width: 40%;
      @include mobile {
        width: 48px;
      }
      @include tablet {
        width: 48px;
      }
      .m28_arrow {
        transform: rotate(180deg);
        margin-right: 12px;
      }
      a {
        display: flex;
        align-items: center;
        div {
          @include mobile {
            display: none;
          }
          @include tablet {
            display: none;
          }
        }
      }
    }
    &__center {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h4 {
        text-transform: uppercase;
      }
    }
    &__right {
      width: 40%;
      @include mobile {
        width: 48px;
      }
      @include tablet {
        width: 48px;
      }
      .m28_arrow {
        margin-left: 12px;
      }
      a {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        div {
          @include mobile {
            display: none;
          }
          @include tablet {
            display: none;
          }
        }
      }
    }
  }
}
