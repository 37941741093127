.m45 {
  width: 100%;
  margin-top: -46px;
  padding: 0;
  transition: $transition1;
  @include mobile {
    padding: 0;
    overflow: hidden;
  }
  @include tablet {
    padding: 0;
  }
  @include tablet-big {
    padding: $vs-t 0;
  }
}
.therapy_slider {
  margin-top: 73px;
  @include tablet {
    margin-top: 50px;
  }
}
.m51_slider_nav{
  max-width: 1440px;
  margin: 0 auto;
  .slick-list {
    margin: 0 -8px;
  }
  .slick-track {
    @include mobile {
    }
    @include tablet {
    }
  }
  .m51_slider_nav_item__thumb {

    &:focus {
      outline: 0;
    }
  }
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -50px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
    center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 16px;
    transform: rotate(180deg);
    @include mobile {
      left: 16px;
    }
    @include tablet {
      left: 32px;
    }
    @include tablet-big {
      left: 32px;
    }
  }
  .slick-next {
    left: 59px;
    @include mobile {
      left: 59px;
    }
    @include tablet {
      left: 75px;
    }
    @include tablet-big {
      left: 75px;
    }


    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-disabled {
    opacity: 0.3;
  }
}
