.m23 {
  width: 100%;
  padding: 125px 0;
  @include mobile {
    padding: 110px 0 80px;
  }
  @include tablet {
    padding: 110px 0 80px;
  }
  figure {
    width: 100%;
    img {
      width: 100%;
    }
  }
  figcaption {
    max-width: 940px;
    font-size: 14px;
    font-weight: 500;
    line-height: em(20, 14);
    color: #505050;
    margin-top: 16px;
  }
}
