.m17 {
  width: 100%;
  padding: $vs-d 0px;
  background-color: #2a6ebb;
  @include mobile {
    padding: $vs-m 0px;
  }
  @include tablet {
    padding: $vs-t 0px;
    .container {
      padding: 0 110px;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    position: relative;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
  }
  &__title {
    width: 248px;
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 28px;
    line-height: em(42, 28);
    color: #fff;
    letter-spacing: 0.07px;
    @include mobile {
      width: 100%;
      font-size: 24px;
      margin-bottom: 8px;
    }
    @include tablet {
      width: 100%;
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
  &__list {
    width: calc(100% - 248px);
    padding-left: 29px;
    display: flex;
    @include mobile {
      width: 100%;
      padding-left: 0;
      flex-direction: column;
    }
    @include tablet {
      width: 100%;
      padding-left: 0;
      flex-direction: column;
    }
    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include mobile {
        flex-direction: row;
      }
      @include tablet {
        flex-direction: row;
      }
    }
    li {
      width: 33.333%;
      border-left: 1px solid #fff;
      padding-left: 16px;
      @include mobile {
        width: 100%;
        padding: 22px 0 22px 26px;
        border-left: 0;
        border-bottom: 1px solid #fff;
        &:last-child {
          border-bottom: 0;
        }
      }
      @include tablet {
        width: 100%;
        padding: 22px 0 22px 26px;
        border-left: 0;
        border-bottom: 1px solid #fff;
        &:last-child {
          border-bottom: 0;
        }
      }
      &.is--active {
        .number_modal {
          opacity: 1;
          visibility: visible;
          overflow: visible;
        }
      }
    }
    &__info {
      width: 20px;
      height: 20px;
      background: url('./images/icon-info-circle.svg') no-repeat center;
      background-size: 20px;
      margin-bottom: 15px;
      cursor: pointer;
      @include mobile {
        margin-bottom: 0;
        margin-top: 4px;
        position: absolute;
        right: 0;
      }
      @include tablet {
        margin-bottom: 0;
        margin-top: 4px;
        position: absolute;
        right: 0;
      }
    }
  }
  h4 {
    font-family: 'Noah Head';
    font-size: 28px;
    font-weight: 500;
    line-height: em(36, 28);
    letter-spacing: 0.07px;
    color: #dcdcdc;
    margin-bottom: 9px;
    @include mobile {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: bold;
      color: #fff;
      min-width: 50px;
      text-align: right;
      margin-right: 20px;
    }
    @include tablet {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: bold;
      color: #fff;
      min-width: 50px;
      text-align: right;
      margin-right: 20px;
    }
  }
  h3 {
    font-family: 'Noah Head';
    font-size: 28px;
    font-weight: 500;
    line-height: em(36, 28);
    letter-spacing: 0.07px;
    color: #fff;
    margin-bottom: 30px;
    @include mobile {
      font-size: 22px;
      margin-bottom: 0;
      font-weight: normal;
    }
    @include tablet {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: normal;
    }
  }
  .number_modal {
    display: flex;
    width: 864px;
    background-color: #fff;
    padding: 60px 88px 60px 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    @include mobile {
      width: 100%;
      padding: 32px 26px 40px;
      flex-direction: column;
      border-radius: 4px;
    }
    @include tablet {
      width: 460px;
      padding: 42px 26px 60px;
      flex-direction: column;
      border-radius: 4px;
    }
    h4 {
      color: #0d1e5a;
      @include mobile {
        text-align: left;
      }
      @include tablet {
        text-align: left;
      }
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      color: #000000;
      margin-bottom: 0;
      @include mobile {
        margin-bottom: 14px;
      }
    }
    &__left {
      width: 248px;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
    &__right {
      width: calc(100% - 248px);
      padding-left: 60px;
      @include mobile {
        width: 100%;
        padding-left: 0;
      }
      @include tablet {
        width: 100%;
        padding-left: 0;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000000;
      }
    }
    &__close {
      width: 16px;
      height: 16px;
      background: url('./images/icon-close.svg') no-repeat;
      background-size: 16px;
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }
  }
}
