.m48 {
  margin-top: 105px;
  @include mobile {
    margin-top: 40px;
  }
  @include tablet {
    margin-top: 40px;
  }
  @extend .default-section-margin-bottom;

  h4 {
    font-weight: 800;
    font-size: 22px;
    line-height: em(32, 22);
    letter-spacing: 0.05px;
    color: #1b1b1b;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    color: #1b1b1b;
    margin-bottom: 24px;
  }

  .contact-panel {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 40px;
    margin-bottom: 40px;
    display: none;
  }

  span {
    display: block;

    &.contact-label {
      margin-top: 9px;
      @include mobile {
        margin-top: 7px;
      }
      @include tablet {
        margin-top: 7px;
      }
    }
  }

  .link_with_arrow {
    font-weight: 500;
    font-size: 16px;
    line-height: em(34, 16);
    color: #2a6ebb;

    @include mobile {
      font-size: 15px;
      line-height: em(34, 16);
    }

    &:hover {
      color: #2a6ebb;

      &:after {
        transform: rotate(45deg);
      }
    }

    &:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 6px;
      background: url('./images/icon-arrow-top-right.svg') no-repeat center
        center;
      transition: $transition1;
    }
  }

  .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    &:hover {
      &:before {
        margin-right: 12px;
      }
    }
    &:before {
      transform: rotate(90deg);
    }
  }

  #m54-content {
    &:last-child {
      border-bottom: none;
    }
  }

  #loadMore {
    cursor: pointer;
  }
}
