.m49 {
  padding: 150px 0;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  @include tablet-big {
    padding: $vs-t 0;
  }
  h1 {
    font-family: 'Noah Head';
    font-weight: 700;
    font-size: 48px;
    line-height: em(62, 48);
    letter-spacing: -0.1px;
    color: #1b1b1b;
    margin-bottom: 10px;
    @include mobile {
      font-size: 36px;
      line-height: em(38, 36);
      margin-bottom: 20px;
    }
    @include tablet {
      font-size: 40px;
      line-height: em(62, 40);
      margin-bottom: 20px;
    }
  }

  p {
    font-weight: normal;
    font-size: 24px;
    line-height: em(32, 24);
    letter-spacing: 0.417px;
    color: #000;
    @include mobile {
      font-size: 22px;
      line-height: em(32, 22);
    }
    @include tablet {
      line-height: em(38, 24);
    }
    &:first-child {
      margin-bottom: 70px;
      @include mobile {
        margin-bottom: 60px;
      }
      @include tablet {
        margin-bottom: 100px;
      }
    }
  }
}
