.m14 {
  position: relative;
  padding: $vs-d 0px;
  background: #f5f5f5;
  @include mobile {
    padding: $vs-m 0px;
  }
  @include tablet {
    padding: $vs-t 0px;
  }
  @include tablet-big {
    padding: $vs-t 0px;
  }
  &-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 78px;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    &__left {
      h2 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 40px;
        color: #1b1b1b;
        line-height: 1.35em;
        letter-spacing: -1px;
        margin-top: 7px;
        @include mobile {
          font-size: 27px;
          margin-bottom: 20px;
        }
        @include tablet {
          font-size: 32px;
          margin-bottom: 20px;
        }
      }
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: em(16, 12);
        color: #747474;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }
  }
  .accordion {
    @include mobile {
      margin-bottom: 75px;
    }
    @include tablet {
      margin-bottom: 75px;
    }
    h2 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 26px;
      line-height: em(38, 26);
      color: #000;
      padding-bottom: 26px;
      border-bottom: 1px solid #dcdcdc;
      @include mobile {
        font-size: 20px;
      }
    }
    .accordion-section-title {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 22px;
      line-height: em(36, 22);
      color: #1b1b1b;
      padding: 27px 0;
      border-bottom: 1px solid #dcdcdc;
      background: url('./images/icon-down-arrow.svg') no-repeat right;
      background-size: 22px;
      padding-right: 40px;
      padding-right: 40px;
      @include mobile {
        font-size: 18px;
        line-height: em(26, 20);
        padding: 25px 0;
        padding-right: 40px;
        font-family: 'Noah Text';
        background: url('./images/icon-down-arrow.svg') no-repeat right 28px;
        background-size: 22px;
      }

      &:last-child() {
        border-bottom: 0 !important;
      }

      &.active {
        background: url('./images/icon-up-arrow.svg') no-repeat right;
        background-size: 22px;
        border-bottom: 0;
        @include mobile {
          background: url('./images/icon-up-arrow.svg') no-repeat right 28px;
          background-size: 22px;
        }
      }
    }
    .accordion-section-content {
      padding-bottom: 28px;
      display: none;
      border-bottom: 1px solid #dcdcdc;
      &:first-child {
        display: block;
      }
      &:last-child {
        border-bottom: 0;
      }
      p {
        color: #1b1b1b;
        font-size: 16px;
        line-height: em(24, 16);
        @include mobile {
          font-family: 'Noah Text';
        }
        @include tablet {
          font-size: 18px;
        }
        a {
          font-weight: 600;
          font-size: 16px;
          line-height: em(24, 16);
          color: #2a6ebb;
          @include mobile {
            font-family: 'Noah Text';
            font-weight: 800;
          }
        }
      }
    }
  }
  p {
    &.excerpt {
      max-width: 866px;
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.05px;
      color: #000;
      margin-top: 24px;

      @include mobile {
        font-size: 22px;
        line-height: em(32, 22);
      }
    }
  }
}

.pdr35 {
  padding-right: 35px;
  @include mobile {
    padding-right: 0;
  }
  @include tablet {
    padding-right: 0;
  }
}
.pdl35 {
  padding-left: 35px;
  @include mobile {
    padding-left: 0;
  }
  @include tablet {
    padding-left: 0;
  }
}
@include mobile {
  .m14 {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
