.m20 {
  margin: 30px 0;

  .slick-slide {
    width: 100%;
    max-width: 777px;

    @include tablet {
      max-width: 400px;
    }

    @include mobile {
      max-width: 320px;
    }

    p {
      margin: 16px 0 !important;
      padding: 0 !important;

      @include mobile {
        max-width: 247px;
      }
    }
  }

  .slick-slide {
    margin-right: 100px;
    opacity: 0.4;

    @include tablet {
      margin-right: 16px;
    }
    @include mobile {
      margin-right: 16px;
    }
  }

  .slick-slide__title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .slick-slide.slick-current {
    opacity: 1;
  }

  .slick-slide img {
    width: 100%;
    height: 544px;
    object-fit: cover;

    @include tablet {
      max-height: 260px;
    }

    @include mobile {
      max-height: 210px;
    }
  }

  .slick-slide__title {
    font-size: 14px;
    font-weight: 500;
    line-height: em(20, 14);
    color: #505050;
    margin-top: 7px;
    margin-bottom: 0;
  }

  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -25px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 0;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
  .slick-next {
    left: 40px;

    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-disabled {
    opacity: 0.3;
  }
}
