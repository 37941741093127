// New Fonts
@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-BoldItalic.woff2') format('woff2'),
    url('./fonts/NoahHead-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-Regular.woff2') format('woff2'),
    url('./fonts/NoahHead-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-Bold.woff2') format('woff2'),
    url('./fonts/NoahHead-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-MediumItalic.woff2') format('woff2'),
    url('./fonts/NoahHead-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-RegularItalic.woff2') format('woff2'),
    url('./fonts/NoahHead-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-Medium.woff2') format('woff2'),
    url('./fonts/NoahHead-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/NoahHead-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/NoahHead-ExtraBold.woff2') format('woff2'),
    url('./fonts/NoahHead-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/*
*
* Font family: Noha Text 
*
*/

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-BoldItalic.woff2') format('woff2'),
    url('./fonts/NoahText-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-Bold.woff2') format('woff2'),
    url('./fonts/NoahText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/NoahText-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-ExtraBold.woff2') format('woff2'),
    url('./fonts/NoahText-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-Medium.woff2') format('woff2'),
    url('./fonts/NoahText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-RegularItalic.woff2') format('woff2'),
    url('./fonts/NoahText-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-MediumItalic.woff2') format('woff2'),
    url('./fonts/NoahText-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noah Text';
  src: url('./fonts/NoahText-Regular.woff2') format('woff2'),
    url('./fonts/NoahText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
