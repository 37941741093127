.m47 {
  @extend .default-section-margin-top;
  @extend .default-section-margin-bottom;

  h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: em(54, 40);
    letter-spacing: -1px;
    margin-bottom: 30px;
    @include mobile {
      font-size: 30px;
      line-height: em(42, 30);
    }
    @include tablet {
      font-size: 27px;
      line-height: em(42, 27);
    }
  }

  ul {
    li {
      font-weight: 500;
    }
  }

  p {
    font-weight: 500;

    &.full {
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.5px;
      padding-right: 100px;
      margin-bottom: 100px;

      @include mobile {
        font-size: 22px;
        font-weight: 500;
        line-height: em(32, 22);
        letter-spacing: 0.25px;
        padding-right: 0;
        margin-bottom: 56px;
      }
      @include tablet {
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 56px;
      }
    }
  }

  ul {
    margin-bottom: 30px;
    @include mobile {
      margin-bottom: 30px;
    }
    @include tablet {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  iframe {
    width: 100%;
    height: fit-content;
  }
}
