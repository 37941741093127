.m31 {
  margin: 130px auto;
  @include mobile {
    margin: $vs-m auto;
  }
  @include tablet {
    margin: $vs-t auto;
  }
  &-form-wrapper {
    display: inline-block;
    padding: 70px 90px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @include mobile {
      padding: 100px 16px;
    }
  }

  &-title {
    margin-bottom: 35px;
    font-family: 'Noah Head';
    font-weight: bold;
    font-size: 26px;
    line-height: em(38, 26);
  }

  &-left {
    padding-right: 30px;
    @include mobile {
      padding-right: 0;
    }
    @include tablet {
      padding-right: 0;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: em(24, 16);
    }
  }

  &-right {
    padding-left: 30px;

    @include mobile {
      padding-left: 0;
      margin-top: 60px;
    }

    @include tablet {
      padding-left: 0;
      margin-top: 60px;
    }
  }

  .gfield_label {
    display: none;
  }

  .gfield,
  .form-group {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 24px;
    }
  }

  .ginput_container .gfield input,
  .form-input {
    color: #0d1e5a !important;
  }

  .gform_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .validation_error {
    display: none;
  }

  .gform_confirmation_message {
    display: none;
  }

  &-button-reset {
    cursor: pointer;
  }

  &-alert {
    color: #0d1e5a;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: em(18, 12);
  }

  &-button,
  .gform_button {
    margin-left: 24px;
    float: left;
    padding: 8px 20px 10px 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #2a6ebb;
    font-weight: 800;
    font-size: 16px;
    line-height: em(24, 16);
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    transition: $transition1;
    display: block;
    &--outlined {
      margin-left: 0;
      margin-top: 30px;
      border-color: #2a6ebb;
      background-color: transparent;
      color: #2a6ebb;
      &:before {
        background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
      }
      &:hover {
        color: #2a6ebb;
        background: transparent;
        &:before {
          margin-right: 11px;
          margin-left: 5px;
          background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
        }
      }
    }
    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      margin-right: 16px;
      transition: $transition1;
    }
    &:hover {
      background-color: #2a6ebb;
      color: #fff;
      &:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
    }
  }
}
