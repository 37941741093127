#cookie-notice {
  color: #fff;
  background: $black;
  padding: 64px 0;
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  display: none;
  z-index: 1000000;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  @include mobile {
    padding: 32px 0;
  }

  h3 {
    font-size: 22px;
    line-height: em(26, 22);
    color: #fff;
    margin-bottom: 24px;
    @include mobile {
      font-size: 20px;
      line-height: em(26, 20);
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 16px;
    line-height: em(22, 16);
    font-weight: 400;
    color: #fff;
    @include mobile {
      font-size: 14px;
      line-height: em(20, 14);
    }
  }
  .cookie-notice-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    @include mobile {
      width: auto;
      margin-top: 24px;
      flex-direction: column;
    }
  }

  .btn-accept,
  .btn-manage {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 16px 14px 16px 22px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: $transition1;
    &__icon {
      width: 20px;
      height: 20px;
      margin-left: 24px;
    }

    &:hover {
      color: $black;
      background-color: #fff;
    }
    &:focus {
      color: $black;
      background-color: #fff;
    }
  }
  .btn-manage {
    padding: 16px 22px;
    @include mobile {
      width: 100%;
      justify-content: center;
    }
  }

  .btn-accept {
    margin-left: 24px;
    @include mobile {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
#cookieModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 17, 65, 0.8); /* Black w/ opacity */
  z-index: 1000001;

  /* Modal Content/Box */
  .modal-content {
    background-color: #ffffff;
    margin: 32px auto;
    padding: 64px 110px 64px 56px;
    max-width: 840px;
    width: 100%;
    position: relative;
    @include mobile {
      margin: 16px auto 0;
      padding: 48px 75px 48px 24px;
    }
  }
  .container {
    @include mobile {
      padding: 0;
    }
  }

  /* The Close Button */
  .close {
    position: absolute;
    right: 26px;
    top: 43px;
    float: right;
    width: 24px;
    height: 24px;
    z-index: 2;
    transform: none;
    background: url('./images/icon-close.svg') no-repeat center center;
    transition: $transition1;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .cookie-notice-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    @include mobile {
      width: fit-content;
      flex-direction: column;
    }
  }

  .btn-accept-all,
  .btn-save {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 16px 14px 16px 22px;
    border: 1px solid $blue;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: $blue;
    text-decoration: none;
    cursor: pointer;
    transition: $transition1;
    &__icon {
      width: 20px;
      height: 20px;
      margin-left: 24px;
    }

    &:hover {
      color: #fff;
      background-color: $blue;
    }
    &:focus {
      color: #fff;
      background-color: $blue;
    }
  }
  .btn-save {
    padding: 16px 22px;
    @include mobile {
      width: 100%;
    }
  }
  .btn-accept-all {
    margin-left: 24px;
    @include mobile {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  h2 {
    font-size: 34px;
    line-height: em(44, 34);
    font-weight: 500;
    letter-spacing: 0.5px;
    color: $blue;
    margin-bottom: 35px;
    @include mobile {
      font-size: 28px;
      line-height: em(32, 28);
    }
  }
  label {
    font-size: 20px;
    line-height: em(24, 20);
    font-weight: 500;
    @include mobile {
      font-size: 16px;
      line-height: em(22, 16);
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: em(22, 16);
    color: $black;
    margin-top: 8px;
    margin-bottom: 35px;
  }

  //checkbox
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
  }

  label:before {
    width: 14px;
    height: 14px;
    margin-top: -4px;
  }
  label:after {
    margin-top: -4px;
  }
  input[disabled]:checked + label {
    cursor: default;
    &:after,
    &:before {
      opacity: 0.4;
    }
    &:before {
      background-color: #dcdcdc;
      border: 1px solid #dcdcdc;
    }
  }

  input:checked + label:before {
    background-color: $blue;
    border: 1px solid $blue;
  }

  input:checked + label:after {
    width: 14px;
    height: 14px;
    background-position-y: 3px;
    opacity: 1;
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.label-checkbox {
  font-size: 16px;
  font-weight: 500;
  line-height: em(18, 16);
  color: $black;

  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: $transition1;
  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid $blue;
    border-radius: 1px;
    margin-right: 8px;
    margin-top: -2px;
  }
  &:after {
    content: '';
    opacity: 0;
    width: 12px;
    height: 11px;
    background-color: $blue;
    background-image: url('./images/checkbox-tick.svg');
    background-position: center;
    background-position-y: 1px;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 1px;
    margin-top: -2px;
    transition: $transition1;
  }
}

.footer-cookies-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-cookies-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f3f7;
  border-radius: 3px;
  @include mobile {
    width: 100%;
    margin-top: 20px;
  }
  > div {
    padding: 11px 16px;
    @include mobile {
      padding: 8px ​5px;
    }
  }
  //checkbox
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
    &:hover {
      color: $black;
      &:before {
        // background-color: #dcdcdc;
        border: 1px solid $black;
      }
    }
  }

  label:before {
    width: 14px;
    height: 14px;
    margin-top: 0;
  }
  label:after {
    margin-top: 0;
  }
  input[disabled]:checked + label {
    cursor: default;
    &:after,
    &:before {
      opacity: 0.4;
    }
    &:before {
      background-color: #dcdcdc;
      border: 1px solid #dcdcdc;
    }
  }

  input:checked + label:before {
    background-color: $blue;
    border: 1px solid $blue;
  }

  input:checked + label:after {
    width: 14px;
    height: 14px;
    background-position-y: 3px;
    opacity: 1;
  }
}
