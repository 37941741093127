.m44 {
  margin-bottom: 150px;
  @include mobile {
    margin-bottom: 120px;
  }
  @include tablet {
    margin-bottom: 100px;
  }

  h2 {
    margin-top: 98px;
    font-family: 'Noah Head';
    font-weight: bold;
    font-size: 26px;
    line-height: em(38, 26);
    margin-bottom: 17px;

    @include mobile {
      margin-top: 18px;
      font-size: 24px;
      line-height: em(34, 24);
      margin-bottom: 17px;
    }

    @include tablet {
      margin-top: 21px;
      margin-bottom: 22px;
    }

    &:first-child {
      margin-top: 150px;
      @include mobile {
        margin-top: 120px;
      }
      @include tablet {
        margin-top: 100px;
      }
    }
  }

  h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: em(34, 20);
  }

  p {
    font-family: 'Noah Text';
    font-size: 20px;
    font-weight: 500;
    line-height: em(34, 20);
    margin-bottom: 21px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-weight: bold;
    color: #2a6ebb;

    &:hover {
      color: #0d1e5a;
    }
  }
}
