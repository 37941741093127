.m09 {
  width: 100%;
  padding: $vs-d 0px;
  @include mobile {
    padding: $vs-m 0px;
  }
  @include tablet {
    padding: $vs-t 0px;
  }
  &__top {
    padding-right: 350px;
    @include mobile {
      padding-right: 0;
    }
    @include tablet {
      padding-right: 0;
    }
    h5 {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: em(16, 12);
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 0;
      @include mobile {
        margin-bottom: 7px;
      }
      @include tablet {
        margin-bottom: 4px;
      }
    }
    h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 0;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
        margin-bottom: 14px;
      }
      @include tablet {
        font-size: 27px;
        line-height: em(42, 27);
        margin-bottom: 14px;
      }
    }
  }
  &__main {
    width: 100%;
    display: flex;
    margin-top: 90px;
    position: relative;
    @include mobile {
      margin-top: 50px;
      flex-direction: column;
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
    }
    @include tablet {
      margin-top: 50px;
      flex-direction: column;
    }
  }
  &__nav_slider {
    width: 490px;
    padding-left: 70px;
    position: relative;
    @include mobile {
      width: 100%;
      padding-left: 0;
    }
    @include tablet {
      width: calc(100% + 40px);
      padding-left: 0;
    }
    .slick-list {
      height: auto !important;
    }
    .slick-track {
      @include desktop {
        transform: none !important;
      }
      @include mobile {
        margin-left: -60px;
      }
      @include tablet {
        margin-left: -250px;
      }
      .slick-slider-tracker {
        background: red;
        width: 3px;
        height: 20px;
        background-color: #ffa700;
        position: absolute;
        left: 0;
        top: 0;
        transition: $transition1;
        @include mobile {
          display: none;
        }
        @include tablet {
          display: none;
        }
      }
    }
    li {
      outline: 0;
      width: 100%;
      position: relative;
      border-left: 1px solid #dcdcdc !important;
      padding-bottom: 26px;
      @include mobile {
        width: auto !important;
        border-left: 0 !important;
        border-bottom: 1px solid #dcdcdc !important;
        padding-bottom: 0;
      }
      @include tablet {
        width: auto !important;
        border-left: 0 !important;
        border-bottom: 1px solid #dcdcdc !important;
        padding-bottom: 0;
      }
      &:first-child {
        span {
          padding-top: 2px;
          @include mobile {
            padding-top: 14px;
          }
          @include tablet {
            padding-top: 14px;
          }
        }
      }
      &:last-child {
        padding-bottom: 0;
        span {
          padding-bottom: 2px;
          @include mobile {
            padding-bottom: 14px;
          }
          @include tablet {
            padding-bottom: 14px;
          }
        }
      }
      &.slick-current {
        span {
          font-weight: 800;
          &:before {
            opacity: 1;
          }
        }
      }
      span {
        width: 100%;
        display: flex;
        padding: 8px 23px;
        font-size: 22px;
        position: relative;
        cursor: pointer;
        transition: $transition1;
        @include mobile {
          padding: 14px 18px;
        }
        @include tablet {
          padding: 14px 18px;
        }
        &:hover {
          font-weight: 800;
        }
        &:before {
          content: '';
          width: 3px;
          height: 100%;
          background-color: #ffa700;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          display: none;
          transition: $transition1;
          @include mobile {
            width: 100%;
            height: 3px;
            top: unset;
            bottom: 0;
            display: block;
          }
          @include tablet {
            width: 100%;
            height: 3px;
            top: unset;
            bottom: 0;
            display: block;
          }
        }
      }
    }
  }
  &__slider {
    width: calc(100% - 490px);
    position: static;
    @include mobile {
      width: 100%;
      padding-left: 16px;
    }
    @include tablet {
      width: calc(100% + 40px);
    }
    .slick-track {
      @include mobile {
        margin-left: -40px;
      }
      @include tablet {
        margin-left: -80px;
      }
    }
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      left: 0;
      z-index: 2;
      transform: none;
      top: unset;
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center
        center;
      transition: $transition1;
      &:before {
        display: none;
      }
    }
    .slick-prev {
      transform: rotate(-90deg);
      &:hover {
        transform: rotate(-90deg) scale(1.1);
      }
    }
    .slick-next {
      transform: rotate(90deg);
      &:hover {
        transform: rotate(90deg) scale(1.1);
      }
    }
  }
  &__slide {
    outline: 0;
    @include mobile {
      margin-top: 54px;
      margin-right: 15px;
    }
    @include tablet {
      margin-top: 54px;
      margin-right: 90px;
    }
    &__content {
      @include mobile {
        *:not(div):not(a) {
          display: none;
        }
        a {
          display: inline-block;
        }
      }
    }
    span {
      font-weight: 800;
      font-size: 16px;
      line-height: em(24, 16);
      color: #0d1e5a;
      margin-bottom: 16px;
      display: block;
    }
    h4 {
      font-weight: 800;
      font-size: 22px;
      line-height: em(32, 22);
      letter-spacing: 0.05px;
      color: #1b1b1b;
      margin-bottom: 50px;
      @include mobile {
        margin-bottom: 19px;
      }
      @include tablet {
        margin-bottom: 19px;
      }
    }
    &__excerpt {
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.05px;
      color: #1b1b1b;
      margin-bottom: 20px;
      @include mobile {
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.02px;
        line-height: em(24, 16);
      }
      @include tablet {
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.02px;
        line-height: em(24, 16);
      }
    }
    p:not(.m09__slide__excerpt) {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      color: #1b1b1b;
      margin-bottom: 20px;
    }
    a {
      display: inline-block;
      font-weight: 800;
      font-size: 15px;
      line-height: em(16, 15);
      letter-spacing: 2px;
      color: #2a6ebb;
      text-decoration: none;
      text-transform: uppercase;
      padding-bottom: 3px;
      border-bottom: 2px solid #2a6ebb;
      transition: $transition1;
      &:hover {
        color: #0d1e5a;
        border-color: #0d1e5a;
      }
      @include mobile {
        font-size: 14px;
        line-height: em(16, 14);
      }
    }
  }
}
.page-id-424 {
  .m09__slide h4 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 500;
  }
}


.accordion__item {
  margin: 5px auto;
  .accordion__title {
    position: relative;
    display: block;
    padding: 13px 60px 15px 13px;
    margin-bottom: 2px;
    color: #202020;
    font-size: 28px;
    text-decoration: none;
    background-color: #eaeaea;
    border-radius: 3px;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #e5e4e4;
      transition: all 0.5s ease-out;
    }

    .accordion__arrow {
      position: absolute;
      top: 13px;
      right: 10px;
      display: inline-block;
      transition: all 0.2s ease-out;
    }
    .accordion__title-text {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: 16px;
      color: #1b1b1b;
    }
  }
  .accordion-active {
    background-color: #e5e4e4;
  }
  .accordion__rotate {
    transform: rotate(225deg);
  }
  .accordion__content {
    padding: 30px;
    margin-bottom: 2px;
    font-size: 14px;
    display: none;
    background-color: #f3f3f3;
  }
  .accordion__arrow-item {
    font-weight: 700;
    padding: 0;
    margin: 0;
  }
}

.m09__slide__content .aligncenter {
  margin-left: auto;
  margin-right: auto;
}