.m05 {
  width: 100%;
  padding: $vs-d 0;
  background-color: #f5f5f5;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  &_slider {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    @include desktop {
      padding-left: 124px;
    }
    @include mobile {
      overflow: hidden;
    }
    .slick-list {
      margin: 0 -8px;
      @include desktop {
        padding-left: 0 !important;
      }
      @include tablet {
        margin: 0 -16px;
      }
    }
    .slick-track {
      @include mobile {
        margin-left: -35px;
      }
      @include tablet {
        margin-left: -355px;
      }
      @include desktop {
        margin-left: -400px;
      }
    }
    &_box {
      width: 100%;
      margin: 60px 0 64px 0;
      padding: 0 8px;
      outline: 0;
      @include mobile {
        margin: 40px 0 10px 0;
      }
      @include tablet {
        margin: 40px 0 10px 0;
      }
      a {
        width: 100%;
        display: block;
        background-color: #fff;
        border-radius: 8px;
        padding: 32px 24px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        transition: $transition1;
        &:hover {
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
        }
        @media only screen and (max-width: 340px) {
          padding: 32px 18px;
        }
      }
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: em(18, 12);
        color: #959595;
        letter-spacing: -0.21px;
        margin-right: 13px;
        &:last-child {
          padding-left: 40px;
          border-left: 1px solid #959595;
          color: #2a6ebb;
          background: url('./images/icon-category.svg') no-repeat 15px center;
          background-size: 14px;
        }
      }
      h4 {
        font-weight: 800;
        font-size: 22px;
        line-height: em(32, 22);
        letter-spacing: 0.05px;
        color: #000;
        margin-top: 30px;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000;
        margin-top: 9px;
        margin-bottom: 0;
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 124px;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
  .slick-next {
    left: 167px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-disabled {
    opacity: 0.3;
    pointer-events: none !important;
  }
}
