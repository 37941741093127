.m16 {
  width: 100%;
  padding: $vs-d 0;

  @include tablet {
    padding: $vs-t 0;
  }

  @include mobile {
    padding: $vs-m 0;
    .container {
      padding: 0;
    }
    .row {
      margin: 0 !important;
    }
  }

  h1 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: em(54, 40);
    letter-spacing: -1px;
    margin-bottom: 20px;
    max-width: 299px;
    @include mobile {
      font-size: 30px;
      line-height: em(42, 30);
      margin-bottom: 24px;
    }
    @include tablet {
      font-size: 27px;
      line-height: em(42, 27);
      margin-bottom: 24px;
    }
  }

  &-subtitle {
    max-width: 490px;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.55em;
    letter-spacing: 0.5px;
    @include mobile {
      font-size: 22px;
      line-height: em(32, 22);
      letter-spacing: 0.25px;
    }
  }

  &-text {
    font-weight: 800;
    font-size: 16px;
    line-height: em(24, 16);
  }

  &-button {
    margin-top: 25px;
    float: left;
    padding: 10px 23px 8px 16px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #2a6ebb;
    font-weight: 800;
    font-size: 16px;
    line-height: em(24, 16);
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    transition: $transition1;
    display: block;
    @include mobile {
      margin-top: 15px;
    }
    @include tablet {
      margin-top: 16px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 13px;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      margin-right: 16px;
      transition: $transition1;
    }
    &:hover {
      background-color: #2a6ebb;
      color: #fff;
      &:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url('./images/icon-arrow-right-white.svg') no-repeat center;
      }
    }
  }

  &-button--outlined {
    border-color: #2a6ebb;
    background-color: transparent;
    color: #2a6ebb;
    &:before {
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
    }
    &:hover {
      color: #2a6ebb;
      background: transparent;
      &:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url('./images/icon-arrow-right-blue.svg') no-repeat center;
      }
    }
  }

  &-left {
    padding-right: 80px !important;
    @include desktop {
      position: sticky;
      top: 80px;
    }
    &.column-mob-12 {
      @include tablet {
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }
    &.column-tab-12 {
      @include mobile {
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }

    .m16-text {
      margin-top: 66px;
      margin-bottom: 54px;
      font-weight: 400;
      @include mobile {
        margin-top: 16px;
        margin-bottom: 10px;
      }
      @include tablet {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-right {
    padding-left: 80px !important;

    @include tablet {
      margin-top: 45px;
    }

    @include mobile {
      margin-top: 80px;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &-steps {
    &__step {
      position: relative;
      max-width: 470px;
      padding-bottom: 58px;

      @include tablet {
        padding-bottom: 80px;
      }

      @include mobile {
        max-width: 100%;
        padding-bottom: 80px;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &-line {
        position: absolute;
        left: -42px;
        height: 101%;
        width: 1px;
        background: #dcdcdc;
        span {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #ffc400;
          display: flex;
          justify-content: center;
          align-items: center;
          left: -15px;

          font-weight: 800;
          font-size: 12px;
          line-height: em(18, 16);
        }

        @include mobile {
          position: relative;
          left: 0;
          height: 1px;
          width: 100%;
          margin-bottom: 40px;

          span {
            left: 16px;
            top: -16px;
          }
        }
      }
    }

    &-title {
      font-weight: 800;
      font-size: 22px;
      line-height: em(32, 22);
      letter-spacing: 0.5px;
      margin-bottom: 15px;

      @include mobile {
        padding: 0 16px;
      }
    }

    &-text {
      font-size: 16px;
      line-height: em(24, 16);
      letter-spacing: 0px;
      @include mobile {
        padding: 0 16px;
      }
    }
  }
}
