.m27 {
  width: 100%;
  &_banner {
    width: 100%;
    height: 470px;
    background-size: cover;
    background-position: center;
    background-color: #2a6ebb;
    display: flex;
    align-items: flex-end;
    padding-bottom: 58px;
    @include mobile {
      height: 400px;
      padding-bottom: 134px;
    }
    @include tablet {
      height: 470px;
      padding-bottom: 142px;
    }
    &__inner {
      width: 100%;
      max-width: 474px;
    }
    span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: em(16, 12);
      line-height: 0.15px;
      color: #fff;
    }
    h1 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 32px;
      line-height: em(42, 32);
      color: #fff;
      margin-top: 10px;
      @include mobile {
        font-size: 26px;
        line-height: em(38, 26);
      }
      @include tablet {
        font-size: 26px;
        line-height: em(38, 26);
      }
    }
    p {
      font-weight: normal;
      font-size: 24px;
      line-height: em(40, 24);
      letter-spacing: 0.05px;
      color: #fff;
      @include mobile {
        font-size: 22px;
        line-height: em(32, 22);
      }
      @include tablet {
        line-height: em(38, 24);
      }
    }
  }
  &_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mobile {
      flex-direction: column-reverse;
    }
    @include tablet {
      flex-direction: column-reverse;
    }
  }
  &_breadcrumb {
    ul {
      display: flex;
      margin-top: 35px;
      @include mobile {
        margin-bottom: 60px;
      }
      @include tablet {
        margin-bottom: 60px;
      }
    }
    li {
      font-size: 12px;
      font-weight: 500;
      line-height: em(18, 12);
      letter-spacing: 0.01px;
      padding-right: 17px;
      background-image: url('./images/icon-arrow-right.svg');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 7px 12px;
      margin-right: 8px;
      &:last-child {
        background-image: none;
        margin-right: 0;
        padding-right: 0;
      }
      a {
        color: #2a6ebb;
        transition: $transition1;
        &:hover {
          color: #0d1e5a;
        }
      }
    }
  }
  &_box {
    width: 280px;
    margin-top: -180px;
    @include mobile {
      width: 256px;
      margin: 0 auto;
      margin-top: -65px;
    }
    @include tablet {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: -100px;
    }
    &__thumb {
      width: 100%;
      height: 360px;
      @include mobile {
        height: 330px;
      }
      @include tablet {
        width: 192px;
        height: 246px;
      }
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      width: 100%;
      padding: 32px 24px;
      background-color: #f1f1f1;
      @include mobile {
        padding: 24px 16px;
      }
      @include tablet {
        padding: 26px ​41px 20px 24px;
      }
      h4 {
        font-size: 18px;
        line-height: em(24, 18);
        font-weight: 500;
        color: #000;
        margin-bottom: 6px;
      }
      h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        color: #000;
      }
      ul {
        width: 100%;
        margin-bottom: 8px;
        li {
          font-size: 16px;
          line-height: em(24, 16);
          font-weight: 500;
          color: #000;
          position: relative;
          padding-left: 14px;
          &:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #2a6ebb;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
      }
    }
  }
}
