.m35 {
  width: 100%;
  padding: $vs-d 0;
  background-color: #f1f1f1;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }

  .row {
    @include desktop {
      display: flex;
      align-items: flex-end;
    }
    @include tablet-big {
      display: flex;
      align-items: flex-end;
    }
  }

  &_content {
    width: 100%;
    padding-right: 110px;
    @include mobile {
      padding-right: 0;
    }

    &__left {
      @include mobile {
        margin-bottom: 50px;
      }
      @include tablet {
        margin-bottom: 60px;
      }
    }
  }

  h3 {
    font-family: 'Noah Head';
    font-size: 26px;
    font-weight: 500;
    line-height: em(38, 26);
    color: #000;
    margin-bottom: 20px;
    @include mobile {
      font-size: 24px;
      line-height: em(34, 24);
      margin-bottom: 15px;
    }

    &.heading {
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: em(54, 40);
      letter-spacing: -1px;
      margin-bottom: 24px;
      @include mobile {
        font-size: 30px;
        line-height: em(42, 30);
      }
      @include tablet {
        font-size: 27px;
        line-height: em(42, 27);
      }
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    color: #000;
    margin-bottom: 25px;
    padding-right: 100px;
    @include mobile {
      padding-right: 0;
    }
  }

  &_social {
    width: 100%;
    display: flex;
    @include mobile {
      flex-direction: column;
    }

    li {
      margin-right: 50px;
      @include mobile {
        margin-right: 0;
        margin-top: 7px;
      }
    }

    a {
      font-size: 20px;
      line-height: em(34, 20);
      font-weight: 500;
      color: #2a6ebb;
      display: flex;
      align-items: center;
      transition: $transition1;

      &:hover {
        color: #0d1e5a;
      }
    }

    img {
      width: 14px;
      max-height: 14px;
      object-fit: contain;
      margin-right: 14px;
    }
  }
}
