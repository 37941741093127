.m03 {
  width: 100%;
  padding: 150px 0 200px;
  transition: $transition1;
  @include mobile {
    padding: $vs-m 0;
    overflow: hidden;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  @include tablet-big {
    padding: $vs-t 0;
  }
  &.mode--dark {
    background-color: #212121;
    .main_title {
      a,
      h3,
      span {
        color: #fff;
      }
      a {
        &:before {
          border-color: #fff;
          background-image: url('./images/icon-arrow-right-white.svg');
        }
      }
    }
    .therapy_slider_nav {
      .slick-prev,
      .slick-next {
        background-image: url('./images/icon-arrow-right-white.svg');
        border-color: #fff;
      }
    }
    .m03-dark-mode-btn {
      #moon {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
      }
      #sunn {
        opacity: 1;
        visibility: visible;
        overflow: visible;
      }
    }
    .therapy_slide__controls span svg {
      fill: #fff;
    }
    .therapy_slide__controls .fullscreen-btn svg {
      fill: #fff;
    }
    .therapy_slider_nav_item__content h5 {
      color: #fff;
    }
  }
}
.m03-dark-mode-btn {
  #moon {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    transition: $transition1;
  }
  #sunn {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: $transition1;
  }
}
.therapy_slider {
  margin-top: 73px;
  @include tablet {
    margin-top: 50px;
  }
}
.therapy_slide {
  outline: 0;
  margin-bottom: 40px;
  @include mobile {
    margin-bottom: 24px;
  }
  @include tablet {
    margin-bottom: 50px;
  }
  &__inner {
    width: 100%;
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
  }
  &__video {
    width: 100%;
    max-width: 670px;
    @include tablet {
      max-width: 100%;
    }
  }
  &__controls {
    padding-left: 16px;
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }
    span {
      display: flex;
      width: 28px;
      height: 28px;
      margin-bottom: 20px;
      cursor: pointer;
      svg {
        fill: #2a6ebb;
        transition: $transition1;
        &:hover {
          opacity: 0.6 !important;
        }
      }
    }
  }
  &__content {
    width: 100%;
    max-width: calc(100% - 670px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    @include mobile {
      max-width: 100%;
    }
    @include tablet {
      max-width: 100%;
    }
    &_inner {
      background-color: #2a6ebb;
      padding: 29px 47px 25px 16px;
      max-height: 280px;
      overflow: hidden;
      @include mobile {
        padding: 28px 24px 22px;
      }
      @include mobile {
        padding: 20px 78px 21px 29px;
      }
      h4 {
        font-weight: 800;
        font-size: 18px;
        line-height: em(24, 18);
        // letter-spacing: 0.05px;
        margin-bottom: 8px;
        @include mobile {
          margin-bottom: 4px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
        // letter-spacing: 0.002px;
      }
    }
  }
}
.therapy_slider_nav {
  max-width: 1440px;
  margin: 0 auto;
  .slick-list {
    margin: 0 -8px;
  }
  .slick-track {
    margin-left: -460px;
    @include mobile {
      margin-left: -35px;
    }
    @include tablet {
      margin-left: -350px;
    }
  }
  &_item {
    color: #000;
    padding: 0 8px;
    outline: 0;
    transition: $transition1;
    &:hover {
      opacity: 0.8;
    }
    &.slick-current {
      opacity: 0.4;
      .therapy_slider_nav_item__thumb:before {
        display: none;
      }
    }
    &__thumb {
      width: 100%;
      height: 160px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      &:before {
        content: '';
        width: 23px;
        height: 23px;
        background: url('./images/icon-play-small.svg') no-repeat center;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        border-radius: 100%;
        background-size: 34px;
        position: absolute;
        top: 10px;
        right: 10px;
        background-position: center -4px;
      }
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      margin-top: 8px;
      h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: em(24, 16);
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -50px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url('./images/icon-arrow-right-blue.svg') no-repeat center
      center;
    transition: $transition1;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 125px;
    transform: rotate(180deg);
    @include tablet-big {
      left: 40px;
    }
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
  .slick-next {
    left: 168px;
    @include tablet-big {
      left: 83px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-disabled {
    opacity: 0.3;
  }
}
.plyr__controls {
  padding: 0 !important;
  width: max-content !important;
  height: max-content !important;
  left: 50% !important;
  top: 50% !important;
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  background: none !important;
}
.plyr__control {
  width: 58px !important;
  height: 58px;
  background: url('./images/icon-play.svg') no-repeat -6px -4px !important;
  background-size: 70px !important;
  border-radius: 100%;
  padding: 0 !important;
  transition: $transition1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  &.plyr__control--pressed {
    background-image: url('./images/icon-pause.svg') !important;
  }
  svg {
    display: none;
  }
}
