.m50 {
  @extend .default-section-margin-top;
  @extend .default-section-margin-bottom;
  background-color: #f1f1f1;

  padding: 150px 0;
  @include mobile {
    padding: 100px 0;
  }
  @include tablet {
    padding: 120px 0;
  }

  h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: em(54, 40);
    letter-spacing: -1px;
    margin-bottom: 30px;
    max-width: 866px;
    @include mobile {
      font-size: 30px;
      line-height: em(42, 30);
    }
    @include tablet {
      font-size: 27px;
      line-height: em(42, 27);
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: em(24, 16);
    color: #1b1b1b;
    margin-bottom: 24px;
  }

  .publication-panel {
    border-bottom: 1px solid #dcdcdc;
    padding: 32px 0;
    display: none;

    .item {
      display: inline-block;

      &.titel {
        max-width: 470px;
        width: 100%;
        @include mobile {
          max-width: 100%;
        }
        @include tablet {
          max-width: 100%;
        }
        a {
          color: #2a6ebb;
          font-weight: 500;
        }
      }

      &.autoren {
        float: right;
      }

      &.jahr {
        float: right;
      }

      &.forschungspartner {
        float: right;
      }

      div {
        display: inline-block;
      }

      &.item-group {
        float: right;
        @include tablet {
          float: initial;
          margin-top: 16px;
        }
        @include mobile {
          float: initial;
          margin-top: 16px;
        }

        .autoren {
          width: 180px;
          @include tablet {
            width: 100%;
          }
          @include mobile {
            width: 100%;
          }
        }

        .jahr {
          width: 80px;
          @include tablet {
            width: initial;
          }
          @include mobile {
            width: initial;
          }
          span {
            display: none;
            @include tablet {
              display: initial;
            }
            @include mobile {
              display: initial;
            }
          }
        }

        .forschungspartner {
          width: 180px;
          padding-right: 50px;
          @include tablet {
            width: initial;
            padding: 0;
          }
          @include mobile {
            width: initial;
            padding: 0;
          }
        }
      }
    }
    &.publication-head {
      display: block;
      border: 0;
      padding: 30px 0 0;
      font-weight: 500;
      @include mobile {
        display: none !important;
      }
      @include tablet {
        display: none !important;
      }
    }
  }

  span {
    display: block;

    &.contact-label {
      margin-top: 9px;
      @include mobile {
        margin-top: 7px;
      }
      @include tablet {
        margin-top: 7px;
      }
    }
  }

  .link_with_arrow {
    font-weight: 500;
    font-size: 16px;
    line-height: em(34, 16);
    color: #2a6ebb;
    position: relative;

    @include mobile {
      font-size: 15px;
      line-height: em(34, 16);
    }
    @include tablet {
      line-height: 24px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 36px;
      background: url('./images/icon-arrow-top-right.svg') no-repeat center
        center;
      transition: $transition1;
    }
  }

  .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    &:hover {
      &:before {
        margin-right: 12px;
      }
    }

    &:before {
      transform: rotate(90deg);
    }
  }

  #m54-content {
    &:last-child {
      border-bottom: none;
    }
  }

  .load-more {
    cursor: pointer;
  }
}
