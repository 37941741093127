.m41 {
  width: 100%;
  .page_banner {
    &__thumb {
      width: 100%;
      height: 470px;
      background-size: cover;
      background-position: center;
      background-color: #2a6ebb;
      @include mobile {
        height: 230px;
      }
      @include tablet {
        height: 320px;
      }
    }
    &__bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include mobile {
        flex-direction: column-reverse;
      }
      @include tablet {
        flex-direction: column-reverse;
      }
    }
    &__left {
      width: calc(100% - 770px);
      padding-right: 16px;
      @include mobile {
        width: 100%;
        padding-right: 0;
      }
      @include tablet {
        width: 100%;
        padding-right: 0;
      }
    }
    &__right {
      width: 770px;
      // padding-right: 100px;
      @include mobile {
        width: 100%;
        // padding-right: 0;
      }
      @include tablet {
        width: 100%;
        // padding-right: 0;
      }
    }
    &__content {
      margin-top: 150px;
      margin-bottom: 0;
      display: flex;
      @include mobile {
        flex-direction: column;
        margin-top: $vs-m;
      }
      @include tablet {
        flex-direction: column;
        margin-top: $vs-t;
      }
      h1 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 48px;
        line-height: em(62, 48);
        letter-spacing: -0.1px;
        color: #1b1b1b;
        margin-bottom: 10px;
        @include mobile {
          font-size: 36px;
          line-height: em(38, 36);
          margin-bottom: 20px;
        }
        @include tablet {
          font-size: 40px;
          line-height: em(62, 40);
          margin-bottom: 20px;
        }
      }
      a {
        font-weight: inherit;
        color: #2a6ebb;
        position: relative;
        &:before {
          content: '';
          width: 0;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #2a6ebb;
          transition: $transition1;
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: em(34, 20);
        color: #000000;
        margin-bottom: 25px;
        @include mobile {
          font-size: 18px;
          line-height: em(30, 18);
        }
      }
    }
  }
}
