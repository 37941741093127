@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0s; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0s; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0s; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0s; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0s; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0s; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0s; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0s; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0s; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0s; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0s; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0s; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0s; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0s; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0s; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0s; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0s; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0s; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0s; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0s; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0s; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0s; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0s; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0s; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0s; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0s; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0s; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0s; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0s; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0s; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0s; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0s; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0s; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0s; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0s; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0s; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0s; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0s; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0s; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0s; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0s; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0s; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0s; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0s; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0s; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0s; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0s; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0s; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0s; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0s; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0s; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0s; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0s; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0s; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0s; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0s; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0s; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0s; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0s; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0s; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

[data-aos] {
  pointer-events: none; }
  [data-aos].aos-animate {
    pointer-events: auto; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */ }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
        opacity: 1;
        transform: none; }
    html:not(.no-js) [data-aos='fade-up'] {
      transform: translate3d(0, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down'] {
      transform: translate3d(0, -100px, 0); }
    html:not(.no-js) [data-aos='fade-right'] {
      transform: translate3d(-100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-left'] {
      transform: translate3d(100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-up-right'] {
      transform: translate3d(-100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-up-left'] {
      transform: translate3d(100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down-right'] {
      transform: translate3d(-100px, -100px, 0); }
    html:not(.no-js) [data-aos='fade-down-left'] {
      transform: translate3d(100px, -100px, 0); }
    html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1); }
    html:not(.no-js) [data-aos='zoom-in'] {
      transform: scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-up'] {
      transform: translate3d(0, 100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-down'] {
      transform: translate3d(0, -100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-right'] {
      transform: translate3d(-100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-left'] {
      transform: translate3d(100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-out'] {
      transform: scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-up'] {
      transform: translate3d(0, 100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-down'] {
      transform: translate3d(0, -100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-right'] {
      transform: translate3d(-100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-left'] {
      transform: translate3d(100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos^='slide'][data-aos^='slide'] {
      transition-property: transform;
      visibility: hidden; }
      html:not(.no-js) [data-aos^='slide'][data-aos^='slide'].aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0); }
    html:not(.no-js) [data-aos='slide-up'] {
      transform: translate3d(0, 100%, 0); }
    html:not(.no-js) [data-aos='slide-down'] {
      transform: translate3d(0, -100%, 0); }
    html:not(.no-js) [data-aos='slide-right'] {
      transform: translate3d(-100%, 0, 0); }
    html:not(.no-js) [data-aos='slide-left'] {
      transform: translate3d(100%, 0, 0); }
    html:not(.no-js) [data-aos^='flip'][data-aos^='flip'] {
      backface-visibility: hidden;
      transition-property: transform; }
    html:not(.no-js) [data-aos='flip-left'] {
      transform: perspective(2500px) rotateY(-100deg); }
      html:not(.no-js) [data-aos='flip-left'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-right'] {
      transform: perspective(2500px) rotateY(100deg); }
      html:not(.no-js) [data-aos='flip-right'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-up'] {
      transform: perspective(2500px) rotateX(-100deg); }
      html:not(.no-js) [data-aos='flip-up'].aos-animate {
        transform: perspective(2500px) rotateX(0); }
    html:not(.no-js) [data-aos='flip-down'] {
      transform: perspective(2500px) rotateX(100deg); }
      html:not(.no-js) [data-aos='flip-down'].aos-animate {
        transform: perspective(2500px) rotateX(0); } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0; } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow .3s ease;
  z-index: 0; }

.plyr audio, .plyr iframe, .plyr video {
  display: block;
  height: 100%;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: #fff;
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  animation: plyr-fade-in .3s ease;
  bottom: 0;
  display: none;
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform .4s ease-in-out;
  width: 100%; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(10px * 2);
    padding: calc(var(--plyr-control-spacing,10px) * 2); } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px); } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(10px * -4));
  transform: translateY(calc(var(--plyr-control-spacing,10px) * -4)); }

.plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  background: var(--plyr-captions-background, rgba(0, 0, 0, 0.8));
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__caption div {
  display: inline; }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: calc(10px * .7);
  padding: calc(var(--plyr-control-spacing,10px) * .7);
  position: relative;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: 18px;
  width: var(--plyr-control-icon-size, 18px); }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0; }

.plyr__controls .plyr__controls__item {
  margin-left: calc(10px / 4);
  margin-left: calc(var(--plyr-control-spacing,10px)/ 4); }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(10px / 4);
  padding-left: calc(var(--plyr-control-spacing,10px)/ 4); }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(10px / 2);
  padding: 0 calc(var(--plyr-control-spacing,10px)/ 2); }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls:empty {
  display: none; }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  transition: transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  content: '';
  height: 0;
  position: absolute;
  right: calc(((18px / 2) + calc(10px * .7)) - (4px / 2));
  right: calc(((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7)) - (var(--plyr-menu-arrow-size,4px)/ 2));
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: calc(10px * .7);
  padding: calc(var(--plyr-control-spacing,10px) * .7); }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  display: flex;
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding-bottom: calc(calc(10px * .7)/ 1.5);
  padding-bottom: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 1.5);
  padding-left: calc(calc(10px * .7) * 1.5);
  padding-left: calc(calc(var(--plyr-control-spacing,10px) * .7) * 1.5);
  padding-right: calc(calc(10px * .7) * 1.5);
  padding-right: calc(calc(var(--plyr-control-spacing,10px) * .7) * 1.5);
  padding-top: calc(calc(10px * .7)/ 1.5);
  padding-top: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 1.5);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(10px * .7) * 4);
  padding-right: calc(calc(var(--plyr-control-spacing,10px) * .7) * 4); }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: calc((calc(10px * .7) * 1.5) - 4px);
  right: calc((calc(var(--plyr-control-spacing,10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size,4px)); }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(10px * .7);
  margin: calc(var(--plyr-control-spacing,10px) * .7);
  margin-bottom: calc(calc(10px * .7)/ 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 2);
  padding-left: calc(calc(10px * .7) * 4);
  padding-left: calc(calc(var(--plyr-control-spacing,10px) * .7) * 4);
  position: relative;
  width: calc(100% - (calc(10px * .7) * 2));
  width: calc(100% - (calc(var(--plyr-control-spacing,10px) * .7) * 2)); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: calc((calc(10px * .7) * 1.5) - 4px);
  left: calc((calc(var(--plyr-control-spacing,10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size,4px)); }

.plyr__menu__container .plyr__control--back::before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: '';
  height: 1px;
  left: 0;
  margin-top: calc(calc(10px * .7)/ 2);
  margin-top: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: calc(10px * .7);
  padding-left: calc(var(--plyr-control-spacing,10px) * .7); }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform .3s ease,opacity .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #00b3ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b3ff))); }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(35, 40, 47, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: calc((calc(10px * .7) - 2) * -1);
  margin-right: calc((calc(var(--plyr-control-spacing,10px) * .7) - 2) * -1);
  overflow: hidden;
  padding-left: calc(calc(10px * .7) * 3.5);
  padding-left: calc(calc(var(--plyr-control-spacing,10px) * .7) * 3.5);
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: calc(13px * 2);
  border-radius: calc(var(--plyr-range-thumb-height,13px) * 2);
  color: #00b3ff;
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  display: block;
  height: calc((3px * 2) + 13px);
  height: calc((var(--plyr-range-thumb-active-shadow-width,3px) * 2) + var(--plyr-range-thumb-height,13px));
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  -webkit-appearance: none;
  margin-top: calc(((13px - 5px)/ 2) * -1);
  margin-top: calc(((var(--plyr-range-thumb-height,13px) - var(--plyr-range-track-height,5px))/ 2) * -1); }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -moz-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -moz-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px); }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px); }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -ms-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px; }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px)); }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px); }

@media (max-width: calc(768px - 1px)) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  border-radius: var(--plyr-tooltip-radius, 3px);
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: #4a5464;
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: calc(calc(10px / 2) * 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing,10px)/ 2) * 2);
  opacity: 0;
  padding: calc(10px / 2) calc(calc(10px / 2) * 1.5);
  padding: calc(var(--plyr-control-spacing,10px)/ 2) calc(calc(var(--plyr-control-spacing,10px)/ 2) * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(4px * -1);
  bottom: calc(var(--plyr-tooltip-arrow-size,4px) * -1);
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: calc((18px / 2) + calc(10px * .7));
  left: calc((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7)); }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: calc((18px / 2) + calc(10px * .7));
  right: calc((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7));
  transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1); }

.plyr__progress {
  left: calc(13px * .5);
  left: calc(var(--plyr-range-thumb-height,13px) * .5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: calc(13px * -.5);
  margin-left: calc(var(--plyr-range-thumb-height,13px) * -.5);
  margin-right: calc(13px * -.5);
  margin-right: calc(var(--plyr-range-thumb-height,13px) * -.5);
  width: calc(100% + 13px);
  width: calc(100% + var(--plyr-range-thumb-height,13px)); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: calc((5px / 2) * -1);
  margin-top: calc((var(--plyr-range-track-height,5px)/ 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -moz-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 40, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 40, 47, 0.6) 50%, rgba(35, 40, 47, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)); }

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%; }

.plyr__volume input[type=range] {
  margin-left: calc(10px / 2);
  margin-left: calc(var(--plyr-control-spacing,10px)/ 2);
  margin-right: calc(10px / 2);
  margin-right: calc(var(--plyr-control-spacing,10px)/ 2);
  position: relative;
  z-index: 2; }

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto; }

.plyr--audio {
  display: block; }

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: #4a5464;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px); }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #00b3ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  color: #fff;
  color: var(--plyr-audio-control-color-hover, #fff); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6))); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6))); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6))); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1)); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1)); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1)); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 200, 209, 0.6);
  color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  left: 0;
  position: absolute;
  top: 0; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%); }

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: calc(10px / 2);
  padding: calc(var(--plyr-control-spacing,10px)/ 2);
  padding-top: calc(10px * 2);
  padding-top: calc(var(--plyr-control-spacing,10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(10px * 3.5);
    padding-top: calc(var(--plyr-control-spacing,10px) * 3.5); } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #00b3ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff); }

.plyr__control--overlaid {
  background: #00b3ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: .9;
  padding: calc(10px * 1.5);
  padding: calc(var(--plyr-control-spacing,10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: .3s;
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  opacity: 1; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25))); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25))); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25))); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5)); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5)); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5)); }

.plyr--video .plyr__progress__buffer {
  color: rgba(255, 255, 255, 0.25);
  color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)); }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); }
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  position: static; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px); } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: #23282f;
  border-radius: 2px;
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin: -var(--plyr-range-track-height, 5px)/2 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  background-color: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  margin-bottom: calc(calc(10px / 2) * 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing,10px)/ 2) * 2);
  opacity: 0;
  padding: 3px;
  padding: var(--plyr-tooltip-radius, 3px);
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(4px * -1);
  bottom: calc(var(--plyr-tooltip-arrow-size,4px) * -1);
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #c1c8d1;
  border-radius: calc(3px - 1px);
  border-radius: calc(var(--plyr-tooltip-radius,3px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: calc(3px - 1px);
  border-radius: calc(var(--plyr-tooltip-radius,3px) - 1px);
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

header,
main,
footer {
  float: left;
  width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }
  *:after, *:before {
    box-sizing: border-box; }

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  max-width: 100%;
  height: auto; }

i,
em {
  font-style: italic; }

b,
strong {
  font-weight: bold; }

body {
  overflow-x: hidden;
  position: relative; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-BoldItalic.woff2") format("woff2"), url("./fonts/NoahHead-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-Regular.woff2") format("woff2"), url("./fonts/NoahHead-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-Bold.woff2") format("woff2"), url("./fonts/NoahHead-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-MediumItalic.woff2") format("woff2"), url("./fonts/NoahHead-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-RegularItalic.woff2") format("woff2"), url("./fonts/NoahHead-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-Medium.woff2") format("woff2"), url("./fonts/NoahHead-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-ExtraBoldItalic.woff2") format("woff2"), url("./fonts/NoahHead-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Head';
  src: url("./fonts/NoahHead-ExtraBold.woff2") format("woff2"), url("./fonts/NoahHead-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

/*
*
* Font family: Noha Text 
*
*/
@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-BoldItalic.woff2") format("woff2"), url("./fonts/NoahText-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-Bold.woff2") format("woff2"), url("./fonts/NoahText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-ExtraBoldItalic.woff2") format("woff2"), url("./fonts/NoahText-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-ExtraBold.woff2") format("woff2"), url("./fonts/NoahText-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-Medium.woff2") format("woff2"), url("./fonts/NoahText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-RegularItalic.woff2") format("woff2"), url("./fonts/NoahText-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-MediumItalic.woff2") format("woff2"), url("./fonts/NoahText-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Noah Text';
  src: url("./fonts/NoahText-Regular.woff2") format("woff2"), url("./fonts/NoahText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1; }
  .container:before,
  .row:before, .container:after,
  .row:after {
    content: " ";
    display: table; }
  .container:after,
  .row:after {
    clear: both; }

.headerWrap,
.mainWrap,
.footerWrap {
  float: left;
  width: 100%;
  clear: both; }

.m01--images img, .therapy_slider_nav_item__thumb img, .m08_wrapp__image img, .m18__right__image img, .m22__image img, .m26_team__image img, .m27_box__thumb img, .m33_author__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

/*=====  End of EXTEND SELECTORS  ======*/
/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
.column-1 {
  width: 8.33333%; }

.column-2 {
  width: 16.66667%; }

.column-3 {
  width: 25%; }

.column-4 {
  width: 33.33333%; }

.column-5 {
  width: 41.66667%; }

.column-6 {
  width: 50%; }

.column-7 {
  width: 58.33333%; }

.column-8 {
  width: 66.66667%; }

.column-9 {
  width: 75%; }

.column-10 {
  width: 83.33333%; }

.column-11 {
  width: 91.66667%; }

.column-12 {
  width: 100%; }

.wow {
  visibility: hidden; }

[class*="column-"] {
  float: left;
  min-height: 1px; }

.has_gutter [class*="column-"] {
  padding: 0 8px; }

.row {
  margin-left: -8px;
  margin-right: -8px; }

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 8px; }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .container {
      padding: 0 32px;
      max-width: 748px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .container {
      max-width: 986px; } }
  @media only screen and (min-width: 1200px) {
    .container {
      max-width: 1210px; } }

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important; }
  .show-tab {
    display: none !important; }
  .show-tab-big {
    display: none !important; } }

.hidden-mob {
  display: block; }

.hidden {
  display: none !important; }

@media only screen and (min-width: 1200px) {
  .hidden-desktop {
    display: none !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1384px) {
  .hidden-desktop {
    display: none !important; } }

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%; }
  .column-mob-2 {
    width: 16.66667%; }
  .column-mob-3 {
    width: 25%; }
  .column-mob-4 {
    width: 33.33333%; }
  .column-mob-5 {
    width: 41.66667%; }
  .column-mob-6 {
    width: 50%; }
  .column-mob-7 {
    width: 58.33333%; }
  .column-mob-8 {
    width: 66.66667%; }
  .column-mob-9 {
    width: 75%; }
  .column-mob-10 {
    width: 83.33333%; }
  .column-mob-11 {
    width: 91.66667%; }
  .column-mob-12 {
    width: 100%; }
  .show-mob {
    display: block !important; }
  .hidden-mob {
    display: none !important; } }

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%; }
  .column-tab-2 {
    width: 16.66667%; }
  .column-tab-3 {
    width: 25%; }
  .column-tab-4 {
    width: 33.33333%; }
  .column-tab-5 {
    width: 41.66667%; }
  .column-tab-6 {
    width: 50%; }
  .column-tab-7 {
    width: 58.33333%; }
  .column-tab-8 {
    width: 66.66667%; }
  .column-tab-9 {
    width: 75%; }
  .column-tab-10 {
    width: 83.33333%; }
  .column-tab-11 {
    width: 91.66667%; }
  .column-tab-12 {
    width: 100%; }
  .show-tab {
    display: block !important; }
  .hidden-tab {
    display: none !important; } }

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%; }
  .column-tab-big-2 {
    width: 16.66667%; }
  .column-tab-big-3 {
    width: 25%; }
  .column-tab-big-4 {
    width: 33.33333%; }
  .column-tab-big-5 {
    width: 41.66667%; }
  .column-tab-big-6 {
    width: 50%; }
  .column-tab-big-7 {
    width: 58.33333%; }
  .column-tab-big-8 {
    width: 66.66667%; }
  .column-tab-big-9 {
    width: 75%; }
  .column-tab-big-10 {
    width: 83.33333%; }
  .column-tab-big-11 {
    width: 91.66667%; }
  .column-tab-big-12 {
    width: 100%; }
  .show-tablet-big {
    display: block !important; }
  .hidden-tablet-big {
    display: none !important; } }

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none; }

.no_padding {
  padding: 0 !important; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

body {
  font-family: 'Noah Text';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.001em; }

a {
  text-decoration: none;
  outline: 0; }
  a:hover {
    text-decoration: inherit;
    color: inherit; }

.main_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .main_title.align-start {
    align-items: flex-start; }
  @media only screen and (max-width: 767px) {
    .main_title {
      flex-direction: column;
      align-items: flex-start; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .main_title {
      flex-direction: column;
      align-items: flex-start; } }
  @media only screen and (max-width: 767px) {
    .main_title__right {
      margin-top: 24px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .main_title__right {
      margin-top: 24px; } }
  .main_title span {
    font-size: 12px;
    color: #747474;
    line-height: 1.33333em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0; }
  .main_title h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    line-height: 1.35em;
    letter-spacing: -0.1px;
    color: #1b1b1b;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .main_title h3 {
        font-size: 30px;
        line-height: 1.4em; } }
    @media only screen and (max-width: 767px) {
      .main_title h3 {
        font-size: 32px;
        line-height: 1.3125em; } }

.btn_link_with_arrow {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 2.1875em;
  letter-spacing: 0.3px;
  color: #2a6ebb;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .btn_link_with_arrow {
      font-size: 15px;
      line-height: 2.2em; } }
  .btn_link_with_arrow:hover {
    color: #2a6ebb; }
    .btn_link_with_arrow:hover:before {
      margin-right: 8px; }
  .btn_link_with_arrow:before {
    content: '';
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 12px;
    border: 1px solid #2a6ebb;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }

.text-link {
  display: inline-block;
  font-weight: 800;
  font-size: 15px;
  line-height: 1.06667em;
  color: #2a6ebb;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 3px;
  border-bottom: 2px solid #2a6ebb;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .text-link:hover {
    color: #0d1e5a;
    border-color: #0d1e5a; }

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 500;
  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #000000; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000000; }

.form-group,
.gfield {
  position: relative;
  margin-bottom: 10px; }
  .form-group--error .form-input,
  .gfield--error .form-input {
    border-bottom-color: #ef4a4a; }
  .form-group--error .form-feedback,
  .gfield--error .form-feedback {
    display: inherit; }
  .form-group.gfield_error select,
  .form-group.gfield_error textarea,
  .form-group.gfield_error input,
  .gfield.gfield_error select,
  .gfield.gfield_error textarea,
  .gfield.gfield_error input {
    border-bottom-color: #ea0f0f; }
  .form-group.gfield_error .validation_message,
  .gfield.gfield_error .validation_message {
    display: inherit; }

.form-feedback,
.validation_message {
  position: absolute;
  display: none;
  left: 0;
  margin-top: 5px;
  color: #ef4a4a;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0.01px; }

.form-input,
.ginput_container input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #959595;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 500; }
  .form-input::-webkit-input-placeholder,
  .ginput_container input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 500;
    color: #959595; }
  .form-input:-moz-placeholder,
  .ginput_container input:-moz-placeholder {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 500;
    color: #959595; }
  .form-input::-moz-placeholder,
  .ginput_container input::-moz-placeholder {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 500;
    color: #959595; }
  .form-input:-ms-input-placeholder,
  .ginput_container input:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 500;
    color: #959595; }
  .form-input:focus,
  .ginput_container input:focus {
    outline: none; }

.form-select {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 800;
  color: #959595;
  padding-right: 26px;
  background-image: url("./images/icon-down.svg");
  background-position: calc(100% - 5px);
  background-size: 15px;
  background-repeat: no-repeat;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none; }

.form-textarea {
  min-height: 141px; }

.file-uploader__dropzone {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #959595;
  border-radius: 8px; }
  .file-uploader__dropzone--error {
    border-color: #ef4a4a; }
    .file-uploader__dropzone--error .file-uploader__dropzone__feedback {
      display: inherit; }
  .file-uploader__dropzone p {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 800;
    color: #959595;
    background-image: url("./images/upload_icon.svg");
    background-position: left;
    background-size: 21px;
    background-repeat: no-repeat;
    padding-left: 36px; }
  .file-uploader__dropzone a {
    color: #2a6ebb; }
    .file-uploader__dropzone a:hover {
      color: #2363ac; }
  .file-uploader__dropzone__feedback {
    display: none;
    position: absolute;
    left: 0;
    bottom: -20px;
    margin-top: 5px;
    color: #ef4a4a;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.01px; }

.file-uploader__files {
  display: flex;
  flex-direction: column;
  margin-top: 35px; }

.ginput_preview,
.file-uploader__file {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  min-height: 34px;
  padding-left: 40px;
  margin-bottom: 28px;
  border-bottom: 2px solid #0d1e5a;
  font-weight: 500;
  background-image: url("./images/file-icon.svg");
  background-position: 0 0;
  background-size: 22px;
  background-repeat: no-repeat; }
  .ginput_preview a,
  .file-uploader__file a {
    display: none; }
  .ginput_preview b,
  .file-uploader__file b {
    display: none; }
  .ginput_preview strong,
  .file-uploader__file strong {
    font-weight: bold;
    margin-bottom: 2px; }
  .ginput_preview img,
  .file-uploader__file img {
    cursor: pointer; }
  .ginput_preview--error,
  .file-uploader__file--error {
    margin-bottom: 34px; }
    .ginput_preview--error .file-uploader__file__progress,
    .file-uploader__file--error .file-uploader__file__progress {
      background: #ef4a4a; }
    .ginput_preview--error .file-uploader__file__feedback,
    .file-uploader__file--error .file-uploader__file__feedback {
      display: inherit; }
  .ginput_preview--uploaded .file-uploader__file__progress,
  .file-uploader__file--uploaded .file-uploader__file__progress {
    background: #0d1e5a; }
  .ginput_preview--uploading .file-uploader__file__progress,
  .file-uploader__file--uploading .file-uploader__file__progress {
    background: #ffca00; }
  .ginput_preview__name,
  .file-uploader__file__name {
    font-weight: 500;
    font-size: 16px;
    color: #0d1e5a;
    line-height: 1.5em;
    margin-bottom: 7px; }
  .ginput_preview__size,
  .file-uploader__file__size {
    font-weight: 800;
    font-size: 16px;
    color: #959595;
    line-height: 1.5em;
    margin-left: 24px;
    margin-bottom: 7px; }
  .ginput_preview__progress,
  .file-uploader__file__progress {
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: #dcdcdc; }
  .ginput_preview__feedback,
  .file-uploader__file__feedback {
    display: none;
    position: absolute;
    left: 0;
    bottom: -20px;
    margin-top: 5px;
    color: #ef4a4a;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0.01px; }
  .ginput_preview .gform_delete, .ginput_preview__remove,
  .file-uploader__file .gform_delete,
  .file-uploader__file__remove {
    content: url("./images/file-remove.svg");
    position: absolute;
    right: 0;
    bottom: 8px;
    width: 21px;
    height: 21px;
    cursor: pointer;
    background-image: url("./images/file-remove.svg");
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat; }
    .ginput_preview .gform_delete:hover, .ginput_preview__remove:hover,
    .file-uploader__file .gform_delete:hover,
    .file-uploader__file__remove:hover {
      opacity: 0.8; }

.article_header {
  width: 100%; }
  .article_header__inner {
    width: 100%;
    height: 470px;
    background: #2a6ebb;
    display: flex;
    align-items: flex-end;
    padding-bottom: 45px; }
    @media only screen and (max-width: 767px) {
      .article_header__inner {
        padding-bottom: 40px;
        height: 330px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .article_header__inner {
        height: 330px; } }
  .article_header h1 {
    max-width: 970px;
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 32px;
    line-height: 1.3125em;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .article_header h1 {
        font-size: 26px;
        line-height: 1.46154em; } }
  .article_header__bottom {
    width: 100%;
    margin-top: 100px;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .article_header__bottom {
        margin-top: 60px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .article_header__bottom {
        margin-top: 80px; } }
    .article_header__bottom .container {
      display: flex;
      align-items: flex-start; }
      @media only screen and (max-width: 767px) {
        .article_header__bottom .container {
          flex-direction: column-reverse; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .article_header__bottom .container {
          flex-direction: column-reverse; } }
  .article_header p {
    width: calc(100% - 200px);
    font-weight: normal;
    font-size: 24px;
    line-height: 1.66667em;
    letter-spacing: 0.05px;
    color: #000000;
    padding-right: 100px; }
    @media only screen and (max-width: 767px) {
      .article_header p {
        width: 100%;
        padding-right: 0;
        font-size: 22px;
        line-height: 1.45455em; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .article_header p {
        width: 100%;
        padding-right: 0; } }
  .article_header__info {
    width: 200px;
    padding: 24px 16px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .article_header__info {
        width: 100%;
        background-color: transparent;
        margin-bottom: 40px;
        padding: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .article_header__info {
        width: 100%;
        background-color: transparent;
        flex-direction: row;
        padding: 0;
        margin-bottom: 40px; } }
    .article_header__info span {
      padding-left: 26px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5em;
      background-repeat: no-repeat;
      background-position: left center; }
      .article_header__info span.date {
        color: #959595;
        background-image: url("./images/icon-calendar-gray.svg");
        background-size: 16px; }
      .article_header__info span.category {
        color: #2a6ebb;
        margin-top: 17px;
        background-image: url("./images/icon-medicin.svg");
        background-size: 13px; }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .article_header__info span.category {
            margin-top: 0;
            border-left: 1px solid #959595;
            margin-left: 18px;
            padding-left: 38px;
            background-position: 15px center; } }
    .article_header__info:after {
      content: '';
      background-color: #f5f5f5;
      height: 100%;
      width: 100vw !important;
      position: absolute;
      left: 100%;
      max-width: none;
      z-index: 3;
      top: 0; }
      @media only screen and (max-width: 767px) {
        .article_header__info:after {
          display: none; } }

.article_main p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  padding: 0 16px;
  max-width: 670px;
  margin: 20px auto; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .article_main p {
      max-width: 400px; } }
  @media only screen and (max-width: 767px) {
    .article_main p {
      max-width: 100%;
      text-align: left; } }
  .article_main p a {
    font-weight: 600;
    text-decoration: underline;
    color: #2363ac; }

.filters {
  width: 100%;
  display: flex;
  color: #2a6ebb; }
  .filters.is--open::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3; }
  .filters.is--open .filters_modal {
    opacity: 1;
    visibility: visible;
    overflow: visible; }
  .filters_inner {
    width: 100%;
    position: relative;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .filters_inner {
        flex-wrap: wrap;
        align-items: flex-start; } }
  .filters__btn {
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 500;
    padding-right: 20px;
    margin-right: 40px;
    cursor: pointer;
    background-image: url("./images/icon-arrow-down-gray.svg");
    background-repeat: no-repeat;
    background-position: right center; }
    @media only screen and (max-width: 767px) {
      .filters__btn {
        margin-right: 15px; } }
  .filters__list {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .filters__list {
        width: calc(100% - 90px);
        flex-wrap: wrap; } }
    .filters__list span {
      display: flex;
      border: 1px solid #2a6ebb;
      border-radius: 16px;
      padding: 8px 16px 8px 33px;
      font-size: 16px;
      font-weight: 500;
      line-height: 0.91em;
      margin-right: 24px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
      background-image: url("./images/icon-close-blue.svg");
      background-repeat: no-repeat;
      background-position: 13px;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        .filters__list span {
          margin-bottom: 10px;
          margin-right: 15px; } }
      .filters__list span:hover {
        background-image: url("./images/icon-close-white.svg");
        background-color: #2a6ebb;
        color: #fff; }
  .filters_modal {
    position: absolute;
    left: 0;
    top: calc(100% + 22px);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 47px 40px 40px;
    border-radius: 8px;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .filters_modal {
        width: 100%;
        padding: 40px 20px 40px; } }
    .filters_modal_wrapper {
      display: flex; }
      @media only screen and (max-width: 767px) {
        .filters_modal_wrapper {
          flex-wrap: wrap; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .filters_modal_wrapper {
          flex-wrap: wrap; } }
    .filters_modal__close {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 13px;
      top: 13px;
      background: url("./images/icon-close-blue.svg") no-repeat;
      cursor: pointer;
      z-index: 5; }
    .filters_modal__item {
      margin-right: 40px;
      margin-bottom: 26px; }
      .filters_modal__item:last-of-type {
        margin-right: 0; }
        .filters_modal__item:last-of-type .form-group-custom {
          padding-right: 0;
          border-right: 0; }
      .filters_modal__item h5 {
        display: inline-block;
        font-size: 18px;
        line-height: 1.33333em;
        font-weight: bold;
        letter-spacing: 0.05px;
        color: #000; }

.form-group-custom {
  min-height: calc(100% - 40px);
  position: relative;
  margin-top: 18px;
  border-right: 2px solid #dcdcdc;
  padding-right: 40px; }
  @media only screen and (max-width: 767px) {
    .form-group-custom {
      padding-right: 0;
      border-right: 0; } }
  .form-group-custom .checkbox_btns {
    display: flex;
    flex-direction: column; }
    .form-group-custom .checkbox_btns li {
      margin-bottom: 10px; }
      .form-group-custom .checkbox_btns li:last-child {
        margin-bottom: 0; }
    .form-group-custom .checkbox_btns input[type='checkbox'] {
      display: none; }
      .form-group-custom .checkbox_btns input[type='checkbox']:disabled ~ label {
        opacity: 0.3;
        cursor: not-allowed; }
      .form-group-custom .checkbox_btns input[type='checkbox']:checked ~ label {
        color: #2a6ebb;
        font-weight: bold; }
        .form-group-custom .checkbox_btns input[type='checkbox']:checked ~ label:before {
          border-color: #2a6ebb; }
        .form-group-custom .checkbox_btns input[type='checkbox']:checked ~ label:after {
          opacity: 1; }
  .form-group-custom .label-checkbox {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7em;
    color: #000000;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .form-group-custom .label-checkbox:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 2px solid #505050;
      margin-right: 16px; }
    .form-group-custom .label-checkbox:after {
      content: '';
      opacity: 0;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: #2a6ebb;
      position: absolute;
      left: 6px; }

.article_content {
  color: #000000;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 500; }
  .article_content p {
    margin-bottom: 25px; }
  .article_content figcaption {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 1.32em; }
  .article_content ul {
    list-style: none;
    padding-left: 40px;
    margin-bottom: 20px; }
    .article_content ul li {
      margin-bottom: 5px;
      position: relative; }
      .article_content ul li:before {
        content: '';
        background-color: #2a6ebb;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        position: absolute;
        left: -23px;
        top: 50%;
        transform: translateY(-50%); }
  .article_content table {
    margin: 0 auto;
    margin-top: 20px;
    border-left: 1px solid #ebf0f3;
    border-top: 1px solid #ebf0f3; }
  .article_content th,
  .article_content td {
    padding: 10px 15px;
    border-right: 1px solid #ebf0f3;
    border-bottom: 1px solid #ebf0f3; }
  .article_content h1,
  .article_content h2,
  .article_content h3,
  .article_content h4,
  .article_content h5,
  .article_content h6 {
    font-family: 'Noah Head';
    font-weight: 800;
    margin-bottom: 24px; }
  .article_content h1 {
    font-size: 38px;
    line-height: 1.26316em; }
    @media only screen and (max-width: 767px) {
      .article_content h1 {
        font-size: 32px;
        line-height: 1.25em; } }
  .article_content h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25em; }
    @media only screen and (max-width: 767px) {
      .article_content h2 {
        font-size: 26px;
        line-height: 1.23077em; } }
  .article_content h3 {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.23077em; }
    @media only screen and (max-width: 767px) {
      .article_content h3 {
        font-size: 22px;
        line-height: 1.27273em; } }
  .article_content h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4em; }
    @media only screen and (max-width: 767px) {
      .article_content h4 {
        font-size: 18px;
        line-height: 1.33333em; } }
  .article_content h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33333em; }
    @media only screen and (max-width: 767px) {
      .article_content h5 {
        font-size: 16px;
        line-height: 1.25em; } }
  .article_content h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857em; }
  .article_content ol {
    list-style: decimal;
    padding-left: 20px;
    margin-bottom: 20px; }
    .article_content ol li {
      margin-bottom: 20x;
      font-size: 16px;
      font-weight: 500;
      font-weight: normal;
      color: #000000; }
  .article_content img {
    width: auto;
    text-align: center;
    height: auto;
    margin-bottom: 20px; }
  .article_content iframe {
    margin-bottom: 20px !important;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .article_content iframe {
        width: 100% !important;
        max-width: 100% !important;
        height: auto;
        min-height: 210px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .article_content iframe {
        width: 100% !important;
        max-width: 100% !important; } }
  .article_content .fb_iframe_widget {
    float: left;
    width: 100%;
    margin-bottom: 35px; }
    @media only screen and (max-width: 767px) {
      .article_content .fb_iframe_widget {
        width: 100% !important;
        max-width: 100% !important; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .article_content .fb_iframe_widget {
        width: 100% !important;
        max-width: 100% !important; } }
  .article_content a {
    font-weight: inherit;
    color: #2a6ebb;
    position: relative; }
    .article_content a:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #2a6ebb;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .article_content a:hover:before {
      width: 100%; }

.slick-slider:not(.slick-initialized) {
  overflow: hidden; }

.default-section-margin-bottom, .m47, .m48, .m50 {
  margin-bottom: 150px; }
  @media only screen and (max-width: 767px) {
    .default-section-margin-bottom, .m47, .m48, .m50 {
      margin-bottom: 120px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .default-section-margin-bottom, .m47, .m48, .m50 {
      margin-bottom: 100px; } }

.default-section-margin-top, .m47, .m50 {
  margin-top: 150px; }
  @media only screen and (max-width: 767px) {
    .default-section-margin-top, .m47, .m50 {
      margin-top: 120px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .default-section-margin-top, .m47, .m50 {
      margin-top: 100px; } }

hr.wp-block-separator {
  width: 100%;
  position: relative;
  max-width: 1210px;
  margin: 0 auto; }

.single-beitrage .article_header__info span.date {
  white-space: nowrap; }

.main_header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 50px;
  left: 0;
  padding: 0 24px;
  z-index: 999;
  transition: all 1s ease-in-out; }
  @media only screen and (max-width: 767px) {
    .main_header {
      height: 56px;
      top: 24px;
      padding: 0 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .main_header {
      height: 68px;
      top: 40px;
      padding: 0 32px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .main_header {
      height: 68px;
      top: 40px;
      padding: 0 32px; } }
  .main_header:before {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .main_header.hide {
    top: -100%; }

.menu--toggled .main_header:before {
  opacity: 1;
  visibility: visible;
  overflow: visible; }

.menu--toggled .headerWrap {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.headerWrap {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  float: none; }
  @media only screen and (max-width: 767px) {
    .headerWrap {
      padding-left: 14px;
      padding-right: 20px;
      border-radius: 4px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .headerWrap {
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 6px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .headerWrap {
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 6px; } }

.header_left {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .header_left {
      width: 100%;
      align-items: center;
      justify-content: space-between; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .header_left {
      width: 100%;
      align-items: center;
      justify-content: space-between; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .header_left {
      width: 100%;
      align-items: center;
      justify-content: space-between; } }

.header_right {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .header_right {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .header_right {
      display: none; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .header_right {
      display: none; } }

.logo {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .logo {
      width: 137px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .logo {
      width: 150px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .logo {
      width: 150px; } }
  @media only screen and (min-width: 1200px) {
    .logo {
      width: 193px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .logo {
      width: 170px; } }
  .logo a {
    width: 100%; }

.nav_wrapper {
  display: flex;
  align-items: center;
  margin-left: 50px; }
  @media only screen and (max-width: 767px) {
    .nav_wrapper {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .nav_wrapper {
      display: none; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .nav_wrapper {
      display: none; } }
  @media only screen and (min-width: 1200px) and (max-width: 1384px) {
    .nav_wrapper {
      margin-left: 20px;
      min-width: 620px; } }
  .nav_wrapper > ul {
    height: 100%;
    list-style: none;
    display: flex; }
    @media only screen and (min-width: 1200px) and (max-width: 1384px) {
      .nav_wrapper > ul {
        width: 100%;
        justify-content: space-between; } }
    .nav_wrapper > ul > li {
      padding-right: 55px; }
      @media only screen and (min-width: 1200px) and (max-width: 1384px) {
        .nav_wrapper > ul > li {
          padding-right: 35px; } }
      .nav_wrapper > ul > li > a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.19em;
        color: #3a3a3d;
        padding-top: 4px;
        border-bottom: 4px solid transparent;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .nav_wrapper .menu-items-tracker {
    display: none;
    background: red;
    height: 4px;
    width: 0;
    background-color: #ffca00;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    @media only screen and (max-width: 767px) {
      .nav_wrapper .menu-items-tracker {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .nav_wrapper .menu-items-tracker {
        display: none; } }

.sub_menu {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  border-top: 1px solid #dcdcdc;
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 48px 92px 55px;
  display: flex;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .sub_menu__info {
    width: 250px;
    margin-right: 100px; }
    .sub_menu__info h4 {
      font-weight: 800;
      font-size: 24px;
      color: #3a3a3d;
      line-height: 1.19em;
      margin-bottom: 20px; }
    .sub_menu__info p {
      font-size: 17px;
      font-weight: 500;
      color: #000;
      line-height: 1.58em;
      margin-bottom: 22px; }
    .sub_menu__info a {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.15em;
      color: #1b1b1b;
      padding-right: 24px;
      background: url(./images/icon-arrow-right.svg) no-repeat right 4px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .sub_menu__info a:hover {
        padding-right: 30px; }
  .sub_menu__close {
    width: 24px;
    height: 24px;
    background: url(./images/icon-close.svg) no-repeat center;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .sub_menu__close:hover {
      transform: scale(0.8); }

.sub-menu {
  width: 650px;
  margin-top: -20px; }
  .sub-menu li {
    float: left;
    width: calc(50% - 50px);
    margin-right: 47px;
    border-bottom: 1px solid #dedede;
    padding: 20px 0; }
  .sub-menu a {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.15em;
    color: #1b1b1b;
    padding-right: 24px;
    background: url(./images/icon-arrow-right.svg) no-repeat right 5px;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .sub-menu a:hover {
      padding-right: 30px;
      color: #2a6ebb;
      background-image: url(./images/icon-arrow-right-secondary.svg); }

.sub-menu--open .sub_menu {
  opacity: 1;
  visibility: visible;
  overflow: visible; }

.header_btn {
  padding: 28px 5px;
  min-width: 150px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19em;
  color: #1b1b1b;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .header_btn span {
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    margin-right: 12px; }
  .header_btn.therapy-btn {
    width: 208px;
    background-color: #ffca00; }
    .header_btn.therapy-btn span {
      background-image: url("./images/icon-form.svg"); }
    .header_btn.therapy-btn:hover {
      background-color: #e6b600; }
  .header_btn.lang-btn span {
    background-image: url("./images/icon-globe.svg"); }

.lang {
  display: flex;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .lang.is--toggled ul {
      height: 80px;
      visibility: visible;
      overflow: visible; }
      .lang.is--toggled ul li {
        opacity: 1;
        transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1); } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .lang.is--toggled ul {
      height: 80px;
      visibility: visible;
      overflow: visible; }
      .lang.is--toggled ul li {
        opacity: 1;
        transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1); } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .lang.is--toggled ul {
      height: 80px;
      visibility: visible;
      overflow: visible; }
      .lang.is--toggled ul li {
        opacity: 1;
        transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1); } }
  @media only screen and (min-width: 1200px) {
    .lang:hover ul {
      height: fit-content;
      visibility: visible;
      overflow: visible; }
      .lang:hover ul li {
        opacity: 1;
        transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1); } }
  .lang ul {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: calc(100% - 18px);
    left: 0;
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-left: 64px;
    box-shadow: 0px 12px 15px 0px rgba(0, 0, 0, 0.075);
    transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1); }
  .lang li {
    padding: 8px 0;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .lang li a {
      font-size: 16px;
      font-weight: 500;
      color: rgba(27, 27, 27, 0.6);
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .lang li a:hover {
        color: rgba(27, 27, 27, 0.8); }

.hamburger {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 20px;
  height: 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  .hamburger span {
    width: 100%;
    height: 1px;
    background-color: #0d1e5a;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  @media only screen and (max-width: 767px) {
    .hamburger {
      opacity: 1;
      visibility: visible;
      overflow: visible; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .hamburger {
      opacity: 1;
      visibility: visible;
      overflow: visible; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .hamburger {
      opacity: 1;
      visibility: visible;
      overflow: visible; } }
  .hamburger.is--toggled span:nth-child(1) {
    transform: rotate(45deg) translateX(7px) translateY(6px); }
  .hamburger.is--toggled span:nth-child(2) {
    opacity: 0; }
  .hamburger.is--toggled span:nth-child(3) {
    transform: rotate(-45deg) translateX(6px) translateY(-6px); }

.header__mobile {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: #fff;
  padding: 0 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .header__mobile {
      padding: 0 40px;
      border-top: 1px solid #dedede; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .header__mobile {
      padding: 0 40px;
      border-top: 1px solid #dedede; } }
  .header__mobile--inner {
    padding: 20px 0; }
    @media only screen and (max-width: 767px) {
      .header__mobile--inner {
        border-top: 1px solid #dedede; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile--inner {
        display: flex;
        flex-direction: column;
        padding: 30px 0; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .header__mobile--inner {
        display: flex;
        flex-direction: column;
        padding: 30px 0; } }
  .header__mobile .header_btn {
    padding: 16px 5px; }
    @media only screen and (max-width: 767px) {
      .header__mobile .header_btn {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile .header_btn {
        float: left; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .header__mobile .header_btn {
        float: left; } }
    .header__mobile .header_btn.therapy-btn {
      justify-content: flex-start;
      padding: 16px;
      border-radius: 4px; }
  .header__mobile .lang {
    justify-content: flex-end;
    flex-direction: column; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile .lang {
        float: left; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .header__mobile .lang {
        float: left; } }
    @media only screen and (max-width: 767px) {
      .header__mobile .lang .header_btn {
        border-top: 1px solid #dedede;
        justify-content: flex-start;
        padding-bottom: 5px; } }
    .header__mobile .lang ul {
      position: static;
      box-shadow: none;
      padding-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .header__mobile .lang ul {
          padding-left: 41px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .header__mobile .lang ul {
          margin-top: -10px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .header__mobile .lang ul {
          margin-top: -10px; } }
    @media only screen and (min-width: 1200px) {
      .header__mobile .lang:hover ul {
        height: auto; } }
  .header__mobile .nav_wrapper {
    display: block;
    width: 100%;
    margin-left: 0;
    background: #fff;
    padding: 40px 0; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile .nav_wrapper {
        padding: 0 0 30px 10px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .header__mobile .nav_wrapper {
        padding: 0 0 30px 10px; } }
    .header__mobile .nav_wrapper > ul {
      width: 100%;
      flex-direction: column;
      overflow: hidden; }
      .header__mobile .nav_wrapper > ul > li {
        padding-right: 0; }
        .header__mobile .nav_wrapper > ul > li > a {
          font-size: 18px;
          font-weight: 500;
          border-bottom: 0;
          line-height: 1em;
          padding: 16px 0; }
          @media only screen and (max-width: 767px) {
            .header__mobile .nav_wrapper > ul > li > a {
              font-size: 20px;
              padding: 13px 0; } }
  .header__mobile.second-menu--open .nav_wrapper {
    position: relative;
    padding-bottom: 0; }
    .header__mobile.second-menu--open .nav_wrapper > ul > li:not(.sub-menu--open) {
      display: none; }
    .header__mobile.second-menu--open .nav_wrapper > ul > li > a {
      display: none; }
  @media only screen and (max-width: 767px) {
    .header__mobile.second-menu--open .lang {
      height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden; } }
  .header__mobile .sub_menu {
    height: 0;
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    border-top: 0;
    flex-direction: column;
    transform: translateX(-100%) !important; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile .sub_menu {
        flex-direction: row; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .header__mobile .sub_menu {
        flex-direction: row; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile .sub_menu--item {
        width: 100%;
        padding-right: 15px;
        padding-bottom: 30px;
        margin-right: 50px;
        margin-bottom: 50px;
        border-right: 1px solid #f1f1f1; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .header__mobile .sub_menu--item {
        width: 100%;
        padding-right: 15px;
        padding-bottom: 30px;
        margin-right: 50px;
        margin-bottom: 50px;
        border-right: 1px solid #f1f1f1; } }
    .header__mobile .sub_menu__close {
      width: auto;
      height: auto;
      position: static;
      background: url("./images/icon-arrow-left.svg") no-repeat left 5px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 12px;
      color: #2a6ebb;
      line-height: 1.4em; }
    .header__mobile .sub_menu__info {
      width: 100%;
      margin-right: 0; }
      .header__mobile .sub_menu__info h4 {
        font-size: 22px;
        font-weight: 500; }
        @media only screen and (max-width: 767px) {
          .header__mobile .sub_menu__info h4 {
            margin-top: 10px;
            margin-bottom: 4px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .header__mobile .sub_menu__info h4 {
            margin-top: 24px;
            margin-bottom: 15px; } }
        @media only screen and (min-width: 991px) and (max-width: 1199px) {
          .header__mobile .sub_menu__info h4 {
            margin-top: 24px;
            margin-bottom: 15px; } }
      .header__mobile .sub_menu__info p {
        font-size: 16px; }
        @media only screen and (max-width: 767px) {
          .header__mobile .sub_menu__info p {
            display: none; } }
      @media only screen and (max-width: 767px) {
        .header__mobile .sub_menu__info a {
          font-weight: 500;
          font-size: 12px;
          padding-right: 14px;
          background-size: 8px; } }
  .header__mobile .sub-menu {
    width: 100%;
    margin-top: 0; }
    @media only screen and (max-width: 767px) {
      .header__mobile .sub-menu {
        margin-top: 35px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .header__mobile .sub-menu {
        margin-top: 8px; } }
    .header__mobile .sub-menu li {
      width: 100%;
      margin-right: 0;
      border-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .header__mobile .sub-menu li {
          border-top: 1px solid #dedede; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .header__mobile .sub-menu li {
          padding: 0;
          margin-bottom: 32px; }
          .header__mobile .sub-menu li:last-child {
            margin-bottom: 20px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .header__mobile .sub-menu li {
          padding: 0;
          margin-bottom: 32px; }
          .header__mobile .sub-menu li:last-child {
            margin-bottom: 20px; } }
  .header__mobile .sub-menu--animate {
    transform: translateX(-100%) !important;
    transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1); }
  .header__mobile .sub-menu--open .sub_menu {
    height: auto;
    transform: translateX(0) !important; }

.main_footer {
  padding: 70px 24px 45px;
  background-color: #0d1e5a; }
  @media only screen and (max-width: 767px) {
    .main_footer {
      padding: 100px 16px 97px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .main_footer {
      padding: 117px 32px 60px; } }

.footerWrap {
  max-width: 1220px;
  margin: 0 auto;
  float: none; }

@media only screen and (min-width: 1200px) {
  .footer__main {
    padding-bottom: 20px; } }

.footer__about {
  padding-right: 20px; }
  @media only screen and (max-width: 767px) {
    .footer__about {
      padding-right: 0; } }
  .footer__about p {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.5em; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .footer__about p {
        max-width: 65%; } }

.footer__bottom {
  width: 100%;
  padding-top: 17px;
  border-top: 1px solid #c2d1d9;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .footer__bottom {
      padding-top: 42px;
      flex-direction: column;
      border-top-color: rgba(255, 255, 255, 0.3); } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .footer__bottom {
      width: auto;
      padding-top: 0;
      border-top: 0;
      display: inline-block;
      float: right;
      margin-top: -50px; } }
  .footer__bottom a {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    color: #d9d9d9;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .footer__bottom a:hover {
      opacity: 0.8; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .footer__bottom a {
        display: none; } }
  .footer__bottom p {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    color: #fff;
    letter-spacing: -0.21px; }
    @media only screen and (max-width: 767px) {
      .footer__bottom p {
        margin-top: 20px; } }

.footer_menu {
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .footer_menu {
      margin-bottom: 47px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .footer_menu {
      margin-bottom: 30px; } }
  .footer_menu h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 1.33333em;
    color: #fff;
    margin-bottom: 29px; }
  .footer_menu li {
    width: 100%; }
    .footer_menu li a:after {
      content: '';
      width: 18px;
      height: 8px;
      display: inline-block;
      background: url("./images/icon-arrow-right-white-2.svg") no-repeat right center;
      background-size: 9px;
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .footer_menu li a:hover:after {
      opacity: 1; }
  .footer_menu a {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5em;
    color: #d9d9d9;
    padding-right: 16px;
    margin-bottom: 21px;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .footer_menu a:hover {
      color: #fff; }
  .footer_menu p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5em;
    color: #fff; }

.footer__social {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .footer__social {
      padding-bottom: 48px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .footer__social {
      margin-top: 13px;
      padding-bottom: 50px;
      margin-bottom: 45px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); } }
  .footer__social a {
    margin-right: 20px; }
    .footer__social a:hover {
      opacity: 0.8; }

#cookie-notice a {
  color: white;
  text-decoration: underline; }

.error404-content {
  width: 100%;
  background-color: #2a6ebb;
  padding: 379px 0 194px;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .error404-content {
      padding: 267px 0 220px; } }
  @media only screen and (max-width: 767px) {
    .error404-content {
      padding: 200px 0 64px; }
      .error404-content img {
        margin-bottom: 30px;
        max-width: 192px; }
      .error404-content .hidden-desktop.hidden-tab.column-mob-12 {
        text-align: center; } }
  .error404-content .row {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .error404-content .row {
        display: block; } }
  .error404-content h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33333em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .error404-content h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2em;
    margin-bottom: 7px; }
    @media only screen and (max-width: 767px) {
      .error404-content h3 {
        font-size: 24px;
        line-height: 1.41667em;
        margin-bottom: 27px;
        padding-right: 10px;
        text-align: left; } }
  .error404-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em; }
    @media only screen and (max-width: 767px) {
      .error404-content p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .error404-content p {
        padding-right: 100px; } }
  .error404-content a {
    margin-top: 25px;
    float: left;
    padding: 8px 20px 10px 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.3px;
    color: #2a6ebb;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    @media only screen and (max-width: 767px) {
      .error404-content a {
        margin-top: 0; } }
    .error404-content a:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url("./images/icon-arrow-right-blue.svg") no-repeat center;
      margin-right: 16px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .error404-content a:hover {
      background-color: #2a6ebb;
      color: #fff; }
      .error404-content a:hover:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
    .error404-content a.m04__btn-outline {
      margin-left: 20px;
      background-color: transparent;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        .error404-content a.m04__btn-outline {
          clear: both;
          margin-left: 0; } }
      .error404-content a.m04__btn-outline:before {
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
      .error404-content a.m04__btn-outline:hover {
        background-color: #fff;
        color: #2a6ebb; }
        .error404-content a.m04__btn-outline:hover:before {
          background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }

#cookie-notice {
  color: #fff;
  background: #001141;
  padding: 64px 0;
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  display: none;
  z-index: 1000000;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  @media only screen and (max-width: 767px) {
    #cookie-notice {
      padding: 32px 0; } }
  #cookie-notice h3 {
    font-size: 22px;
    line-height: 1.18182em;
    color: #fff;
    margin-bottom: 24px; }
    @media only screen and (max-width: 767px) {
      #cookie-notice h3 {
        font-size: 20px;
        line-height: 1.3em;
        margin-bottom: 16px; } }
  #cookie-notice p {
    font-size: 16px;
    line-height: 1.375em;
    font-weight: 400;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      #cookie-notice p {
        font-size: 14px;
        line-height: 1.42857em; } }
  #cookie-notice .cookie-notice-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px; }
    @media only screen and (max-width: 767px) {
      #cookie-notice .cookie-notice-buttons {
        width: auto;
        margin-top: 24px;
        flex-direction: column; } }
  #cookie-notice .btn-accept,
  #cookie-notice .btn-manage {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 16px 14px 16px 22px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    #cookie-notice .btn-accept__icon,
    #cookie-notice .btn-manage__icon {
      width: 20px;
      height: 20px;
      margin-left: 24px; }
    #cookie-notice .btn-accept:hover,
    #cookie-notice .btn-manage:hover {
      color: #001141;
      background-color: #fff; }
    #cookie-notice .btn-accept:focus,
    #cookie-notice .btn-manage:focus {
      color: #001141;
      background-color: #fff; }
  #cookie-notice .btn-manage {
    padding: 16px 22px; }
    @media only screen and (max-width: 767px) {
      #cookie-notice .btn-manage {
        width: 100%;
        justify-content: center; } }
  #cookie-notice .btn-accept {
    margin-left: 24px; }
    @media only screen and (max-width: 767px) {
      #cookie-notice .btn-accept {
        margin-left: 0;
        margin-top: 16px; } }

#cookieModal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 17, 65, 0.8);
  /* Black w/ opacity */
  z-index: 1000001;
  /* Modal Content/Box */
  /* The Close Button */ }
  #cookieModal .modal-content {
    background-color: #ffffff;
    margin: 32px auto;
    padding: 64px 110px 64px 56px;
    max-width: 840px;
    width: 100%;
    position: relative; }
    @media only screen and (max-width: 767px) {
      #cookieModal .modal-content {
        margin: 16px auto 0;
        padding: 48px 75px 48px 24px; } }
  @media only screen and (max-width: 767px) {
    #cookieModal .container {
      padding: 0; } }
  #cookieModal .close {
    position: absolute;
    right: 26px;
    top: 43px;
    float: right;
    width: 24px;
    height: 24px;
    z-index: 2;
    transform: none;
    background: url("./images/icon-close.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  #cookieModal .close:hover,
  #cookieModal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer; }
  #cookieModal .cookie-notice-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px; }
    @media only screen and (max-width: 767px) {
      #cookieModal .cookie-notice-buttons {
        width: fit-content;
        flex-direction: column; } }
  #cookieModal .btn-accept-all,
  #cookieModal .btn-save {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 16px 14px 16px 22px;
    border: 1px solid #0032c3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #0032c3;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    #cookieModal .btn-accept-all__icon,
    #cookieModal .btn-save__icon {
      width: 20px;
      height: 20px;
      margin-left: 24px; }
    #cookieModal .btn-accept-all:hover,
    #cookieModal .btn-save:hover {
      color: #fff;
      background-color: #0032c3; }
    #cookieModal .btn-accept-all:focus,
    #cookieModal .btn-save:focus {
      color: #fff;
      background-color: #0032c3; }
  #cookieModal .btn-save {
    padding: 16px 22px; }
    @media only screen and (max-width: 767px) {
      #cookieModal .btn-save {
        width: 100%; } }
  #cookieModal .btn-accept-all {
    margin-left: 24px; }
    @media only screen and (max-width: 767px) {
      #cookieModal .btn-accept-all {
        margin-left: 0;
        margin-top: 16px; } }
  #cookieModal h2 {
    font-size: 34px;
    line-height: 1.29412em;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #0032c3;
    margin-bottom: 35px; }
    @media only screen and (max-width: 767px) {
      #cookieModal h2 {
        font-size: 28px;
        line-height: 1.14286em; } }
  #cookieModal label {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 500; }
    @media only screen and (max-width: 767px) {
      #cookieModal label {
        font-size: 16px;
        line-height: 1.375em; } }
  #cookieModal p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375em;
    color: #001141;
    margin-top: 8px;
    margin-bottom: 35px; }
  #cookieModal input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer; }
  #cookieModal label {
    position: relative;
    cursor: pointer; }
  #cookieModal label:before {
    width: 14px;
    height: 14px;
    margin-top: -4px; }
  #cookieModal label:after {
    margin-top: -4px; }
  #cookieModal input[disabled]:checked + label {
    cursor: default; }
    #cookieModal input[disabled]:checked + label:after, #cookieModal input[disabled]:checked + label:before {
      opacity: 0.4; }
    #cookieModal input[disabled]:checked + label:before {
      background-color: #dcdcdc;
      border: 1px solid #dcdcdc; }
  #cookieModal input:checked + label:before {
    background-color: #0032c3;
    border: 1px solid #0032c3; }
  #cookieModal input:checked + label:after {
    width: 14px;
    height: 14px;
    background-position-y: 3px;
    opacity: 1; }

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.label-checkbox {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.125em;
  color: #001141;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .label-checkbox:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #0032c3;
    border-radius: 1px;
    margin-right: 8px;
    margin-top: -2px; }
  .label-checkbox:after {
    content: '';
    opacity: 0;
    width: 12px;
    height: 11px;
    background-color: #0032c3;
    background-image: url("./images/checkbox-tick.svg");
    background-position: center;
    background-position-y: 1px;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 1px;
    margin-top: -2px;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }

.footer-cookies-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.footer-cookies-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f3f7;
  border-radius: 3px; }
  @media only screen and (max-width: 767px) {
    .footer-cookies-checkbox {
      width: 100%;
      margin-top: 20px; } }
  .footer-cookies-checkbox > div {
    padding: 11px 16px; }
    @media only screen and (max-width: 767px) {
      .footer-cookies-checkbox > div {
        padding: 8px ​5px; } }
  .footer-cookies-checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer; }
  .footer-cookies-checkbox label {
    position: relative;
    cursor: pointer; }
    .footer-cookies-checkbox label:hover {
      color: #001141; }
      .footer-cookies-checkbox label:hover:before {
        border: 1px solid #001141; }
  .footer-cookies-checkbox label:before {
    width: 14px;
    height: 14px;
    margin-top: 0; }
  .footer-cookies-checkbox label:after {
    margin-top: 0; }
  .footer-cookies-checkbox input[disabled]:checked + label {
    cursor: default; }
    .footer-cookies-checkbox input[disabled]:checked + label:after, .footer-cookies-checkbox input[disabled]:checked + label:before {
      opacity: 0.4; }
    .footer-cookies-checkbox input[disabled]:checked + label:before {
      background-color: #dcdcdc;
      border: 1px solid #dcdcdc; }
  .footer-cookies-checkbox input:checked + label:before {
    background-color: #0032c3;
    border: 1px solid #0032c3; }
  .footer-cookies-checkbox input:checked + label:after {
    width: 14px;
    height: 14px;
    background-position-y: 3px;
    opacity: 1; }

.m01 {
  width: 100%;
  position: relative; }
  .m01--images img {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .m01--images .is--active {
    opacity: 1; }
  .m01 .homepage_slider {
    height: 100vh;
    min-height: -webkit-stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .m01 .homepage_slider {
        padding-bottom: 50px; } }
    .m01 .homepage_slider__content {
      max-width: 492px;
      margin-bottom: 12px; }
      @media only screen and (max-width: 767px) {
        .m01 .homepage_slider__content {
          margin-bottom: 15px; } }
      .m01 .homepage_slider__content span {
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        line-height: 1.33333em;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 1px; }
      .m01 .homepage_slider__content h2 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 48px;
        line-height: 1.29167em;
        letter-spacing: -0.1px;
        color: #1b1b1b;
        margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .m01 .homepage_slider__content h2 {
            font-size: 30px;
            line-height: 1.4em; } }
    .m01 .homepage_slider.dark-background .homepage_slider__content span {
      color: #eaeaea; }
    .m01 .homepage_slider.dark-background .homepage_slider__content h2 {
      color: #ffffff; }
    .m01 .homepage_slider .homepage-slider {
      max-height: 500px;
      opacity: 0; }
      .m01 .homepage_slider .homepage-slider.slick-initialized {
        opacity: 1; }
      @media only screen and (max-width: 767px) {
        .m01 .homepage_slider .homepage-slider {
          margin-bottom: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m01 .homepage_slider .homepage-slider {
          margin-bottom: 0; } }
    .m01 .homepage_slider .slick-list {
      margin: 0 -12px; }
      @media only screen and (max-width: 767px) {
        .m01 .homepage_slider .slick-list {
          margin: 0 -16px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m01 .homepage_slider .slick-list {
          margin: 0 -46px; } }
    @media only screen and (max-width: 767px) {
      .m01 .homepage_slider .slick-track {
        margin-left: -17px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m01 .homepage_slider .slick-track {
        margin-left: -106px; } }
    .m01 .homepage_slider .slick-dots {
      text-align: left;
      position: static;
      margin-top: -8px; }
      .m01 .homepage_slider .slick-dots li {
        margin: 0;
        width: 14px;
        height: 14px;
        margin-right: 14px; }
        .m01 .homepage_slider .slick-dots li.slick-active button {
          width: 12px;
          height: 12px; }
        .m01 .homepage_slider .slick-dots li button {
          width: 6px;
          height: 6px;
          background-color: #fff;
          border-radius: 12px;
          margin-right: 0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          .m01 .homepage_slider .slick-dots li button:before {
            display: none; }
  .m01 .homepage_slide {
    padding: 0 12px;
    outline: 0; }
    @media only screen and (max-width: 767px) {
      .m01 .homepage_slide {
        padding: 0 8px 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m01 .homepage_slide {
        padding: 0 12px 32px; } }
    .m01 .homepage_slide.is--hovered .homepage_slide_box a {
      opacity: 1; }
    .m01 .homepage_slide_box {
      width: 100%; }
      .m01 .homepage_slide_box a {
        display: flex;
        background-color: #fff;
        padding: 32px 27px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        opacity: 0.7;
        outline: 0;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
        @media only screen and (max-width: 767px) {
          .m01 .homepage_slide_box a {
            padding: 28px 33px 28px 23px; } }
        .m01 .homepage_slide_box a:hover {
          opacity: 1; }
      .m01 .homepage_slide_box__icon {
        width: 65px; }
        @media only screen and (max-width: 767px) {
          .m01 .homepage_slide_box__icon {
            width: 45px; } }
      .m01 .homepage_slide_box__content {
        width: calc(100% - 65px);
        padding-left: 26px; }
        @media only screen and (max-width: 767px) {
          .m01 .homepage_slide_box__content {
            width: calc(100% - 45px);
            padding-left: 16px; } }
        .m01 .homepage_slide_box__content h4 {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.45455em;
          letter-spacing: 0.05px;
          color: #1b1b1b;
          margin-bottom: 2px; }
          @media only screen and (max-width: 340px) {
            .m01 .homepage_slide_box__content h4 {
              font-size: 18px; } }
        .m01 .homepage_slide_box__content p {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5em;
          color: #000000;
          margin-bottom: 2px; }
          @media only screen and (max-width: 340px) {
            .m01 .homepage_slide_box__content p {
              font-size: 14px; } }
  .m01 .slick-prev,
  .m01 .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -32px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m01 .slick-prev:before,
    .m01 .slick-next:before {
      display: none; }
  .m01 .slick-prev {
    left: 0;
    transform: rotate(180deg); }
    .m01 .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
  .m01 .slick-next {
    left: 43px; }
    .m01 .slick-next:hover {
      transform: scale(1.1); }
  .m01 .slick-disabled {
    opacity: 0.3; }

.m02 {
  width: 100%;
  background-color: #f5f5f5;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m02 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m02 .container {
      padding: 0 110px; } }
  .m02 h5 {
    font-size: 12px;
    font-weight: 500;
    color: #747474;
    line-height: 1.33333em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .m02 h5 {
        margin-bottom: 7px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m02 h5 {
        margin-bottom: 4px; } }
  .m02 h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: 1.35em;
    letter-spacing: -1px;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .m02 h3 {
        font-size: 30px;
        line-height: 1.4em;
        margin-bottom: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m02 h3 {
        font-size: 27px;
        line-height: 1.55556em;
        margin-bottom: 14px; } }
  .m02 p {
    font-weight: normal;
    font-size: 24px;
    line-height: 1.66667em;
    letter-spacing: 0.5px; }
    @media only screen and (max-width: 767px) {
      .m02 p {
        font-size: 22px;
        line-height: 1.45455em;
        letter-spacing: 0.25px; } }

.m03 {
  width: 100%;
  padding: 150px 0 200px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  @media only screen and (max-width: 767px) {
    .m03 {
      padding: 100px 0;
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m03 {
      padding: 120px 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m03 {
      padding: 120px 0; } }
  .m03.mode--dark {
    background-color: #212121; }
    .m03.mode--dark .main_title a,
    .m03.mode--dark .main_title h3,
    .m03.mode--dark .main_title span {
      color: #fff; }
    .m03.mode--dark .main_title a:before {
      border-color: #fff;
      background-image: url("./images/icon-arrow-right-white.svg"); }
    .m03.mode--dark .therapy_slider_nav .slick-prev,
    .m03.mode--dark .therapy_slider_nav .slick-next {
      background-image: url("./images/icon-arrow-right-white.svg");
      border-color: #fff; }
    .m03.mode--dark .m03-dark-mode-btn #moon {
      opacity: 0;
      visibility: hidden;
      overflow: hidden; }
    .m03.mode--dark .m03-dark-mode-btn #sunn {
      opacity: 1;
      visibility: visible;
      overflow: visible; }
    .m03.mode--dark .therapy_slide__controls span svg {
      fill: #fff; }
    .m03.mode--dark .therapy_slide__controls .fullscreen-btn svg {
      fill: #fff; }
    .m03.mode--dark .therapy_slider_nav_item__content h5 {
      color: #fff; }

.m03-dark-mode-btn #moon {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }

.m03-dark-mode-btn #sunn {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }

.therapy_slider {
  margin-top: 73px; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .therapy_slider {
      margin-top: 50px; } }

.therapy_slide {
  outline: 0;
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .therapy_slide {
      margin-bottom: 24px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .therapy_slide {
      margin-bottom: 50px; } }
  .therapy_slide__inner {
    width: 100%;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .therapy_slide__inner {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .therapy_slide__inner {
        flex-direction: column; } }
  .therapy_slide__video {
    width: 100%;
    max-width: 670px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .therapy_slide__video {
        max-width: 100%; } }
  .therapy_slide__controls {
    padding-left: 16px; }
    @media only screen and (max-width: 767px) {
      .therapy_slide__controls {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .therapy_slide__controls {
        display: none; } }
    .therapy_slide__controls span {
      display: flex;
      width: 28px;
      height: 28px;
      margin-bottom: 20px;
      cursor: pointer; }
      .therapy_slide__controls span svg {
        fill: #2a6ebb;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
        .therapy_slide__controls span svg:hover {
          opacity: 0.6 !important; }
  .therapy_slide__content {
    width: 100%;
    max-width: calc(100% - 670px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .therapy_slide__content {
        max-width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .therapy_slide__content {
        max-width: 100%; } }
    .therapy_slide__content_inner {
      background-color: #2a6ebb;
      padding: 29px 47px 25px 16px;
      max-height: 280px;
      overflow: hidden; }
      @media only screen and (max-width: 767px) {
        .therapy_slide__content_inner {
          padding: 28px 24px 22px; } }
      @media only screen and (max-width: 767px) {
        .therapy_slide__content_inner {
          padding: 20px 78px 21px 29px; } }
      .therapy_slide__content_inner h4 {
        font-weight: 800;
        font-size: 18px;
        line-height: 1.33333em;
        margin-bottom: 8px; }
        @media only screen and (max-width: 767px) {
          .therapy_slide__content_inner h4 {
            margin-bottom: 4px; } }
      .therapy_slide__content_inner p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em; }

.therapy_slider_nav {
  max-width: 1440px;
  margin: 0 auto; }
  .therapy_slider_nav .slick-list {
    margin: 0 -8px; }
  .therapy_slider_nav .slick-track {
    margin-left: -460px; }
    @media only screen and (max-width: 767px) {
      .therapy_slider_nav .slick-track {
        margin-left: -35px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .therapy_slider_nav .slick-track {
        margin-left: -350px; } }
  .therapy_slider_nav_item {
    color: #000;
    padding: 0 8px;
    outline: 0;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .therapy_slider_nav_item:hover {
      opacity: 0.8; }
    .therapy_slider_nav_item.slick-current {
      opacity: 0.4; }
      .therapy_slider_nav_item.slick-current .therapy_slider_nav_item__thumb:before {
        display: none; }
    .therapy_slider_nav_item__thumb {
      width: 100%;
      height: 160px;
      border-radius: 5px;
      overflow: hidden;
      position: relative; }
      .therapy_slider_nav_item__thumb:before {
        content: '';
        width: 23px;
        height: 23px;
        background: url("./images/icon-play-small.svg") no-repeat center;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        border-radius: 100%;
        background-size: 34px;
        position: absolute;
        top: 10px;
        right: 10px;
        background-position: center -4px; }
    .therapy_slider_nav_item__content {
      margin-top: 8px; }
      .therapy_slider_nav_item__content h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em; }
  .therapy_slider_nav .slick-prev,
  .therapy_slider_nav .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -50px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .therapy_slider_nav .slick-prev:before,
    .therapy_slider_nav .slick-next:before {
      display: none; }
  .therapy_slider_nav .slick-prev {
    left: 125px;
    transform: rotate(180deg); }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .therapy_slider_nav .slick-prev {
        left: 40px; } }
    .therapy_slider_nav .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
  .therapy_slider_nav .slick-next {
    left: 168px; }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .therapy_slider_nav .slick-next {
        left: 83px; } }
    .therapy_slider_nav .slick-next:hover {
      transform: scale(1.1); }
  .therapy_slider_nav .slick-disabled {
    opacity: 0.3; }

.plyr__controls {
  padding: 0 !important;
  width: max-content !important;
  height: max-content !important;
  left: 50% !important;
  top: 50% !important;
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  background: none !important; }

.plyr__control {
  width: 58px !important;
  height: 58px;
  background: url("./images/icon-play.svg") no-repeat -6px -4px !important;
  background-size: 70px !important;
  border-radius: 100%;
  padding: 0 !important;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4); }
  .plyr__control.plyr__control--pressed {
    background-image: url("./images/icon-pause.svg") !important; }
  .plyr__control svg {
    display: none; }

.m04 {
  width: 100%;
  background-color: #2a6ebb;
  padding: 150px 0;
  color: #fff; }
  @media only screen and (max-width: 767px) {
    .m04 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m04 {
      padding: 120px 0; } }
  .m04 h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33333em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .m04 h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 26px;
    line-height: 1.46154em;
    margin-bottom: 7px; }
    @media only screen and (max-width: 767px) {
      .m04 h3 {
        font-size: 24px;
        line-height: 1.5em;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .m04 h3 {
        font-size: 24px;
        line-height: 1.41667em;
        margin-bottom: 16px;
        padding-right: 10px; } }
  .m04 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em; }
    @media only screen and (max-width: 767px) {
      .m04 p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m04 p {
        padding-right: 100px; } }
  .m04 a {
    margin-top: 25px;
    float: left;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 9px;
    padding-left: 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #fff;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.3px;
    color: #2a6ebb;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    @media only screen and (max-width: 767px) {
      .m04 a {
        margin-top: 15px; } }
    .m04 a:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url("./images/icon-arrow-right-blue.svg") no-repeat center;
      margin-right: 16px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m04 a:hover {
      background-color: #2a6ebb;
      color: #fff; }
      .m04 a:hover:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
    .m04 a.m04__btn-outline {
      margin-left: 20px;
      background-color: transparent;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        .m04 a.m04__btn-outline {
          clear: both;
          margin-left: 0; } }
      .m04 a.m04__btn-outline:before {
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
      .m04 a.m04__btn-outline:hover {
        background-color: #fff;
        color: #2a6ebb; }
        .m04 a.m04__btn-outline:hover:before {
          background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }
  .m04 img {
    float: right; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m04 img {
        float: none;
        margin-top: 60px; } }

.m05 {
  width: 100%;
  padding: 150px 0;
  background-color: #f5f5f5; }
  @media only screen and (max-width: 767px) {
    .m05 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m05 {
      padding: 120px 0; } }
  .m05_slider {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto; }
    @media only screen and (min-width: 1200px) {
      .m05_slider {
        padding-left: 124px; } }
    @media only screen and (max-width: 767px) {
      .m05_slider {
        overflow: hidden; } }
    .m05_slider .slick-list {
      margin: 0 -8px; }
      @media only screen and (min-width: 1200px) {
        .m05_slider .slick-list {
          padding-left: 0 !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m05_slider .slick-list {
          margin: 0 -16px; } }
    @media only screen and (max-width: 767px) {
      .m05_slider .slick-track {
        margin-left: -35px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m05_slider .slick-track {
        margin-left: -355px; } }
    @media only screen and (min-width: 1200px) {
      .m05_slider .slick-track {
        margin-left: -400px; } }
    .m05_slider_box {
      width: 100%;
      margin: 60px 0 64px 0;
      padding: 0 8px;
      outline: 0; }
      @media only screen and (max-width: 767px) {
        .m05_slider_box {
          margin: 40px 0 10px 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m05_slider_box {
          margin: 40px 0 10px 0; } }
      .m05_slider_box a {
        width: 100%;
        display: block;
        background-color: #fff;
        border-radius: 8px;
        padding: 32px 24px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
        .m05_slider_box a:hover {
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3); }
        @media only screen and (max-width: 340px) {
          .m05_slider_box a {
            padding: 32px 18px; } }
      .m05_slider_box span {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5em;
        color: #959595;
        letter-spacing: -0.21px;
        margin-right: 13px; }
        .m05_slider_box span:last-child {
          padding-left: 40px;
          border-left: 1px solid #959595;
          color: #2a6ebb;
          background: url("./images/icon-category.svg") no-repeat 15px center;
          background-size: 14px; }
      .m05_slider_box h4 {
        font-weight: 800;
        font-size: 22px;
        line-height: 1.45455em;
        letter-spacing: 0.05px;
        color: #000;
        margin-top: 30px;
        margin-bottom: 0; }
      .m05_slider_box p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000;
        margin-top: 9px;
        margin-bottom: 0; }
  .m05 .slick-prev,
  .m05 .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m05 .slick-prev:before,
    .m05 .slick-next:before {
      display: none; }
  .m05 .slick-prev {
    left: 124px;
    transform: rotate(180deg); }
    .m05 .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
  .m05 .slick-next {
    left: 167px; }
    .m05 .slick-next:hover {
      transform: scale(1.1); }
  .m05 .slick-disabled {
    opacity: 0.3;
    pointer-events: none !important; }

.m06 {
  width: 100%; }
  .m06 .page_banner__thumb {
    width: 100%;
    height: 470px;
    background-size: cover;
    background-position: center;
    background-color: #2a6ebb; }
    @media only screen and (max-width: 767px) {
      .m06 .page_banner__thumb {
        height: 230px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m06 .page_banner__thumb {
        height: 320px; } }
  .m06 .page_banner__bottom {
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 767px) {
      .m06 .page_banner__bottom {
        flex-direction: column-reverse;
        padding-bottom: 100px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m06 .page_banner__bottom {
        flex-direction: column-reverse;
        padding-bottom: 120px; } }
  .m06 .page_banner__box {
    width: 100%;
    max-width: 512px;
    display: block;
    margin-left: auto;
    margin-right: 0;
    background-color: #2a6ebb;
    padding: 40px 52px 32px 40px;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .m06 .page_banner__box {
        max-width: 100%;
        padding: 50px 18px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m06 .page_banner__box {
        max-width: 100%;
        padding: 50px 76px; } }
    .m06 .page_banner__box h5 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.33333em;
      letter-spacing: 1.15px;
      margin-bottom: 15px; }
    .m06 .page_banner__box h3 {
      font-weight: normal;
      font-size: 22px;
      line-height: 1.54545em;
      letter-spacing: 0.05px;
      margin-bottom: 7px; }
      @media only screen and (max-width: 767px) {
        .m06 .page_banner__box h3 {
          font-size: 20px;
          line-height: 1.4em;
          margin-bottom: 15px; } }
    .m06 .page_banner__box h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857em;
      margin-bottom: 0; }
  .m06 .page_banner__content {
    margin-top: 32px;
    margin-bottom: 150px; }
    @media only screen and (max-width: 767px) {
      .m06 .page_banner__content {
        margin-top: 100px;
        margin-bottom: 65px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m06 .page_banner__content {
        margin-top: 88px;
        margin-bottom: 88px; } }
    .m06 .page_banner__content h1 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 48px;
      line-height: 1.29167em;
      letter-spacing: -0.1px;
      color: #1b1b1b;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        .m06 .page_banner__content h1 {
          font-size: 36px;
          line-height: 1.05556em;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m06 .page_banner__content h1 {
          font-size: 40px;
          line-height: 1.55em;
          margin-bottom: 20px; } }
    .m06 .page_banner__content p {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.33333em;
      letter-spacing: 0.417px;
      color: #000; }
      .m06 .page_banner__content p a {
        font-weight: 800;
        color: #2a6ebb;
        position: relative; }
        .m06 .page_banner__content p a:hover {
          text-decoration: underline; }
  .m06 .page_banner__thumb.align-flex-bottom {
    display: flex;
    align-items: flex-end;
    padding-bottom: 40px; }
    .m06 .page_banner__thumb.align-flex-bottom h1 {
      font-family: 'Noah Head';
      max-width: 572px;
      font-size: 32px;
      font-weight: 800;
      line-height: 1.3125em;
      color: #fff; }
  .m06 .page_banner__content:only-child {
    margin-top: 155px; }
    @media only screen and (max-width: 767px) {
      .m06 .page_banner__content:only-child {
        margin-top: 100px;
        margin-bottom: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m06 .page_banner__content:only-child {
        margin-top: 120px;
        margin-bottom: 0; } }
  .m06 .go_section_bottom {
    cursor: pointer;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 1px solid #2a6ebb;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    position: absolute;
    bottom: 30px;
    right: 0;
    transform: rotate(90deg);
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m06 .go_section_bottom:hover {
      transform: rotate(90deg) scale(1.1); }
    @media only screen and (max-width: 767px) {
      .m06 .go_section_bottom {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m06 .go_section_bottom {
        display: none; } }

.m07 {
  width: 100%;
  padding: 150px 0 126px 0; }
  @media only screen and (max-width: 767px) {
    .m07 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m07 {
      padding: 120px 0; } }
  .m07__inner {
    padding-right: 230px; }
    @media only screen and (max-width: 767px) {
      .m07__inner {
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m07__inner {
        padding-right: 130px; } }
  .m07 h5 {
    font-size: 12px;
    font-weight: 500;
    color: #747474;
    line-height: 1.33333em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .m07 h5 {
        margin-bottom: 7px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m07 h5 {
        margin-bottom: 4px; } }
  .m07 h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: 1.35em;
    letter-spacing: -1px;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .m07 h3 {
        font-size: 30px;
        line-height: 1.4em;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m07 h3 {
        font-size: 27px;
        line-height: 1.55556em;
        margin-bottom: 56px; } }
  .m07 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    margin-top: 32px;
    color: #000; }
    .m07 p:not(.full) {
      column-count: 2;
      column-gap: 16px; }
      @media only screen and (max-width: 767px) {
        .m07 p:not(.full) {
          column-count: unset;
          column-gap: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m07 p:not(.full) {
          column-count: unset;
          column-gap: 0; } }
    .m07 p.full {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.66667em;
      letter-spacing: 0.5px;
      padding-right: 100px;
      margin-top: 64px; }
      @media only screen and (max-width: 767px) {
        .m07 p.full {
          font-size: 22px;
          line-height: 1.45455em;
          letter-spacing: 0.25px;
          padding-right: 0;
          margin-top: 28px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m07 p.full {
          padding-right: 0;
          margin-top: 0; } }
  .m07 a {
    font-weight: 800;
    font-size: 15px;
    line-height: 1.06667em;
    letter-spacing: 1.33px;
    color: #2a6ebb;
    text-transform: uppercase;
    border-bottom: 2px solid #2a6ebb;
    margin-top: 32px;
    display: inline-block;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m07 a:hover {
      color: #0d1e5a;
      border-color: #0d1e5a; }
    .m07 a.is--button {
      float: left;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 9px;
      padding-left: 15px;
      color: #fff;
      border: 1px solid #2a6ebb;
      border-radius: 22px;
      font-weight: 800;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.3px;
      background-color: #2a6ebb;
      text-transform: uppercase;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m07 a.is--button:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("./images/icon-arrow-right-white.svg") no-repeat center;
        margin-right: 16px;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m07 a.is--button:hover {
        background-color: transparent;
        color: #2a6ebb; }
        .m07 a.is--button:hover:before {
          margin-right: 11px;
          margin-left: 5px;
          background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }

.m08 {
  width: 100%;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m08 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m08 {
      padding: 120px 0; } }
  .m08_wrapp {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m08_wrapp {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m08_wrapp {
        flex-direction: column; } }
    .m08_wrapp__image {
      width: 670px;
      height: 448px; }
      @media only screen and (max-width: 767px) {
        .m08_wrapp__image {
          width: 100%;
          height: 225px;
          margin-top: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m08_wrapp__image {
          width: 100%;
          height: 406px;
          margin-top: 60px; } }
    .m08_wrapp__content {
      width: calc(100% - 670px);
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end; }
      @media only screen and (max-width: 767px) {
        .m08_wrapp__content {
          width: 100%;
          padding-left: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m08_wrapp__content {
          width: 100%;
          padding-left: 0; } }
      .m08_wrapp__content h5 {
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        line-height: 1.33333em;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .m08_wrapp__content h5 {
            margin-bottom: 7px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m08_wrapp__content h5 {
            margin-bottom: 4px; } }
      .m08_wrapp__content h3 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 40px;
        color: #1b1b1b;
        line-height: 1.35em;
        letter-spacing: -1px;
        margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .m08_wrapp__content h3 {
            font-size: 30px;
            line-height: 1.4em;
            margin-bottom: 14px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m08_wrapp__content h3 {
            font-size: 27px;
            line-height: 1.55556em;
            margin-bottom: 14px; } }
      .m08_wrapp__content p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000;
        margin-top: 25px; }

.m09 {
  width: 100%;
  padding: 150px 0px; }
  @media only screen and (max-width: 767px) {
    .m09 {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m09 {
      padding: 120px 0px; } }
  .m09__top {
    padding-right: 350px; }
    @media only screen and (max-width: 767px) {
      .m09__top {
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__top {
        padding-right: 0; } }
    .m09__top h5 {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: 1.33333em;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m09__top h5 {
          margin-bottom: 7px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m09__top h5 {
          margin-bottom: 4px; } }
    .m09__top h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m09__top h3 {
          font-size: 30px;
          line-height: 1.4em;
          margin-bottom: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m09__top h3 {
          font-size: 27px;
          line-height: 1.55556em;
          margin-bottom: 14px; } }
  .m09__main {
    width: 100%;
    display: flex;
    margin-top: 90px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .m09__main {
        margin-top: 50px;
        flex-direction: column;
        margin-left: -16px;
        margin-right: -16px;
        width: calc(100% + 32px); } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__main {
        margin-top: 50px;
        flex-direction: column; } }
  .m09__nav_slider {
    width: 490px;
    padding-left: 70px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .m09__nav_slider {
        width: 100%;
        padding-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__nav_slider {
        width: calc(100% + 40px);
        padding-left: 0; } }
    .m09__nav_slider .slick-list {
      height: auto !important; }
    @media only screen and (min-width: 1200px) {
      .m09__nav_slider .slick-track {
        transform: none !important; } }
    @media only screen and (max-width: 767px) {
      .m09__nav_slider .slick-track {
        margin-left: -60px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__nav_slider .slick-track {
        margin-left: -250px; } }
    .m09__nav_slider .slick-track .slick-slider-tracker {
      background: red;
      width: 3px;
      height: 20px;
      background-color: #ffa700;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      @media only screen and (max-width: 767px) {
        .m09__nav_slider .slick-track .slick-slider-tracker {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m09__nav_slider .slick-track .slick-slider-tracker {
          display: none; } }
    .m09__nav_slider li {
      outline: 0;
      width: 100%;
      position: relative;
      border-left: 1px solid #dcdcdc !important;
      padding-bottom: 26px; }
      @media only screen and (max-width: 767px) {
        .m09__nav_slider li {
          width: auto !important;
          border-left: 0 !important;
          border-bottom: 1px solid #dcdcdc !important;
          padding-bottom: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m09__nav_slider li {
          width: auto !important;
          border-left: 0 !important;
          border-bottom: 1px solid #dcdcdc !important;
          padding-bottom: 0; } }
      .m09__nav_slider li:first-child span {
        padding-top: 2px; }
        @media only screen and (max-width: 767px) {
          .m09__nav_slider li:first-child span {
            padding-top: 14px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m09__nav_slider li:first-child span {
            padding-top: 14px; } }
      .m09__nav_slider li:last-child {
        padding-bottom: 0; }
        .m09__nav_slider li:last-child span {
          padding-bottom: 2px; }
          @media only screen and (max-width: 767px) {
            .m09__nav_slider li:last-child span {
              padding-bottom: 14px; } }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m09__nav_slider li:last-child span {
              padding-bottom: 14px; } }
      .m09__nav_slider li.slick-current span {
        font-weight: 800; }
        .m09__nav_slider li.slick-current span:before {
          opacity: 1; }
      .m09__nav_slider li span {
        width: 100%;
        display: flex;
        padding: 8px 23px;
        font-size: 22px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
        @media only screen and (max-width: 767px) {
          .m09__nav_slider li span {
            padding: 14px 18px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m09__nav_slider li span {
            padding: 14px 18px; } }
        .m09__nav_slider li span:hover {
          font-weight: 800; }
        .m09__nav_slider li span:before {
          content: '';
          width: 3px;
          height: 100%;
          background-color: #ffa700;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          display: none;
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          @media only screen and (max-width: 767px) {
            .m09__nav_slider li span:before {
              width: 100%;
              height: 3px;
              top: unset;
              bottom: 0;
              display: block; } }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m09__nav_slider li span:before {
              width: 100%;
              height: 3px;
              top: unset;
              bottom: 0;
              display: block; } }
  .m09__slider {
    width: calc(100% - 490px);
    position: static; }
    @media only screen and (max-width: 767px) {
      .m09__slider {
        width: 100%;
        padding-left: 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__slider {
        width: calc(100% + 40px); } }
    @media only screen and (max-width: 767px) {
      .m09__slider .slick-track {
        margin-left: -40px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__slider .slick-track {
        margin-left: -80px; } }
    .m09__slider .slick-prev,
    .m09__slider .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      left: 0;
      z-index: 2;
      transform: none;
      top: unset;
      background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m09__slider .slick-prev:before,
      .m09__slider .slick-next:before {
        display: none; }
    .m09__slider .slick-prev {
      transform: rotate(-90deg); }
      .m09__slider .slick-prev:hover {
        transform: rotate(-90deg) scale(1.1); }
    .m09__slider .slick-next {
      transform: rotate(90deg); }
      .m09__slider .slick-next:hover {
        transform: rotate(90deg) scale(1.1); }
  .m09__slide {
    outline: 0; }
    @media only screen and (max-width: 767px) {
      .m09__slide {
        margin-top: 54px;
        margin-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m09__slide {
        margin-top: 54px;
        margin-right: 90px; } }
    @media only screen and (max-width: 767px) {
      .m09__slide__content *:not(div):not(a) {
        display: none; }
      .m09__slide__content a {
        display: inline-block; } }
    .m09__slide span {
      font-weight: 800;
      font-size: 16px;
      line-height: 1.5em;
      color: #0d1e5a;
      margin-bottom: 16px;
      display: block; }
    .m09__slide h4 {
      font-weight: 800;
      font-size: 22px;
      line-height: 1.45455em;
      letter-spacing: 0.05px;
      color: #1b1b1b;
      margin-bottom: 50px; }
      @media only screen and (max-width: 767px) {
        .m09__slide h4 {
          margin-bottom: 19px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m09__slide h4 {
          margin-bottom: 19px; } }
    .m09__slide__excerpt {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.66667em;
      letter-spacing: 0.05px;
      color: #1b1b1b;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .m09__slide__excerpt {
          font-weight: normal;
          font-size: 16px;
          letter-spacing: 0.02px;
          line-height: 1.5em; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m09__slide__excerpt {
          font-weight: normal;
          font-size: 16px;
          letter-spacing: 0.02px;
          line-height: 1.5em; } }
    .m09__slide p:not(.m09__slide__excerpt) {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #1b1b1b;
      margin-bottom: 20px; }
    .m09__slide a {
      display: inline-block;
      font-weight: 800;
      font-size: 15px;
      line-height: 1.06667em;
      letter-spacing: 2px;
      color: #2a6ebb;
      text-decoration: none;
      text-transform: uppercase;
      padding-bottom: 3px;
      border-bottom: 2px solid #2a6ebb;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m09__slide a:hover {
        color: #0d1e5a;
        border-color: #0d1e5a; }
      @media only screen and (max-width: 767px) {
        .m09__slide a {
          font-size: 14px;
          line-height: 1.14286em; } }

.page-id-424 .m09__slide h4 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 500; }

.accordion__item {
  margin: 5px auto; }
  .accordion__item .accordion__title {
    position: relative;
    display: block;
    padding: 13px 60px 15px 13px;
    margin-bottom: 2px;
    color: #202020;
    font-size: 28px;
    text-decoration: none;
    background-color: #eaeaea;
    border-radius: 3px;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    cursor: pointer; }
    .accordion__item .accordion__title:hover {
      background-color: #e5e4e4;
      transition: all 0.5s ease-out; }
    .accordion__item .accordion__title .accordion__arrow {
      position: absolute;
      top: 13px;
      right: 10px;
      display: inline-block;
      transition: all 0.2s ease-out; }
    .accordion__item .accordion__title .accordion__title-text {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: 16px;
      color: #1b1b1b; }
  .accordion__item .accordion-active {
    background-color: #e5e4e4; }
  .accordion__item .accordion__rotate {
    transform: rotate(225deg); }
  .accordion__item .accordion__content {
    padding: 30px;
    margin-bottom: 2px;
    font-size: 14px;
    display: none;
    background-color: #f3f3f3; }
  .accordion__item .accordion__arrow-item {
    font-weight: 700;
    padding: 0;
    margin: 0; }

.m09__slide__content .aligncenter {
  margin-left: auto;
  margin-right: auto; }

.m10 {
  width: 100%;
  padding: 150px 0px; }
  @media only screen and (max-width: 767px) {
    .m10 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m10 {
      padding: 120px 0; } }
  .m10__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 767px) {
      .m10__top {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m10__top {
        flex-direction: column; } }
    .m10__top__left {
      max-width: 70%; }
      @media only screen and (max-width: 767px) {
        .m10__top__left {
          max-width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10__top__left {
          max-width: 100%; } }
    .m10__top h5 {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: 1.33333em;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m10__top h5 {
          margin-bottom: 7px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10__top h5 {
          margin-bottom: 4px; } }
    .m10__top h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m10__top h3 {
          font-size: 30px;
          line-height: 1.4em;
          margin-bottom: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10__top h3 {
          font-size: 27px;
          line-height: 1.55556em;
          margin-bottom: 14px; } }
  .m10_box {
    width: 100%;
    margin: 42px 0 0; }
    @media only screen and (max-width: 767px) {
      .m10_box {
        margin: 30px 0 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m10_box {
        margin: 40px 0 0; } }
    .m10_box a {
      width: 100%;
      display: flex;
      background-color: #fff;
      border-radius: 8px;
      padding: 32px 27px 60px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      @media only screen and (max-width: 767px) {
        .m10_box a {
          flex-direction: column;
          padding: 32px 22px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10_box a {
          flex-direction: column;
          padding: 32px 22px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m10_box a {
          flex-direction: column;
          padding: 32px 22px; } }
      .m10_box a:hover {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.31); }
      @media only screen and (max-width: 340px) {
        .m10_box a {
          padding: 32px 18px; } }
    .m10_box__icon {
      width: 55px; }
      @media only screen and (max-width: 767px) {
        .m10_box__icon {
          width: 46px;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10_box__icon {
          margin-bottom: 6px;
          width: 46px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10_box__icon {
          margin-bottom: 10px; } }
    .m10_box__content {
      width: calc(100% - 45px);
      padding-left: 16px; }
      @media only screen and (max-width: 767px) {
        .m10_box__content {
          width: calc(100% - 25px);
          padding-left: 0px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m10_box__content {
          padding-left: 0px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m10_box__content {
          padding-left: 0px; } }
      .m10_box__content h4 {
        font-weight: 800;
        font-size: 22px;
        line-height: 1.45455em;
        letter-spacing: 0.05px;
        color: #1b1b1b;
        margin-bottom: 16px;
        min-height: 64px; }
        @media only screen and (max-width: 767px) {
          .m10_box__content h4 {
            margin-bottom: 12px;
            line-height: 1.45455em;
            align-items: flex-start;
            flex-direction: column;
            display: flex;
            justify-content: center; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m10_box__content h4 {
            min-height: unset;
            margin-bottom: 8px; } }
        @media only screen and (min-width: 991px) and (max-width: 1199px) {
          .m10_box__content h4 {
            min-height: unset;
            margin-bottom: 8px; } }
      .m10_box__content p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000000;
        margin-bottom: 2px; }
        @media (min-width: 1200px) {
          .m10_box__content p {
            height: 120px; } }
  .m10 .benefits-slider .slick-list {
    padding-bottom: 64px !important; }
  @media only screen and (max-width: 767px) {
    .m10 .benefits-slider .slick-track {
      margin-left: -40px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m10 .benefits-slider .slick-track {
      margin-left: -350px; } }
  .m10 .slick-prev,
  .m10 .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m10 .slick-prev:before,
    .m10 .slick-next:before {
      display: none; }
  .m10 .slick-prev {
    left: 8px;
    transform: rotate(180deg); }
    .m10 .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
  .m10 .slick-next {
    left: 50px; }
    .m10 .slick-next:hover {
      transform: scale(1.1); }
  .m10 .slick-disabled {
    opacity: 0.3;
    pointer-events: none !important; }

.m11 {
  width: 100%;
  padding: 150px 0px; }
  @media only screen and (max-width: 767px) {
    .m11 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m11 {
      padding: 120px 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m11 {
      padding: 120px 0; } }
  .m11__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 767px) {
      .m11__top {
        flex-direction: column; } }
    .m11__top__left {
      max-width: 70%; }
    .m11__top h5 {
      font-size: 12px;
      font-weight: 500;
      color: #747474;
      line-height: 1.33333em;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m11__top h5 {
          margin-bottom: 7px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m11__top h5 {
          margin-bottom: 4px; } }
    .m11__top h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m11__top h3 {
          font-size: 30px;
          line-height: 1.4em;
          margin-bottom: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m11__top h3 {
          font-size: 27px;
          line-height: 1.55556em;
          margin-bottom: 14px; } }
  .m11__wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .m11__wrapper {
        overflow: hidden;
        padding-left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m11__wrapper .slick-list {
        margin: 0 -16px; } }
    @media only screen and (max-width: 767px) {
      .m11__wrapper .slick-track {
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m11__wrapper .slick-track {
        margin-left: -220px; } }
    @media only screen and (min-width: 1200px) {
      .m11__wrapper .slick-track {
        margin-left: -455px; } }
  .m11_box {
    width: 278px;
    margin: 58px 16px 82px 0; }
    @media only screen and (max-width: 767px) {
      .m11_box {
        width: 248px;
        margin: 33px 15px 0 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m11_box {
        width: 244px;
        margin: 45px 15px 0 0; } }
    .m11_box__img {
      width: 100%;
      height: 270px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden; }
      @media only screen and (max-width: 767px) {
        .m11_box__img {
          height: 170px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m11_box__img {
          height: 194px; } }
      .m11_box__img img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .m11_box__content {
      width: 100%;
      padding: 20px 24px;
      background-color: rgba(13, 30, 90, 0.9);
      margin-top: -70px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      position: relative;
      z-index: 3; }
      @media only screen and (max-width: 767px) {
        .m11_box__content {
          background-color: #152761;
          margin-top: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m11_box__content {
          margin-top: 0; } }
    .m11_box a {
      width: 100%;
      display: block;
      text-decoration: none;
      color: #fff; }
    .m11_box h4 {
      font-weight: 800;
      font-size: 18px;
      line-height: 1.33333em;
      letter-spacing: 0.05px;
      color: #fff;
      margin-bottom: 13px; }
    .m11_box span {
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0.1px;
      color: #fff;
      margin-bottom: 14px; }
    .m11_box p {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5em;
      color: #fff; }
  .m11 .slick-prev,
  .m11 .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m11 .slick-prev:before,
    .m11 .slick-next:before {
      display: none; }
  .m11 .slick-prev {
    left: 126px;
    transform: rotate(180deg); }
    .m11 .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
    @media only screen and (max-width: 767px) {
      .m11 .slick-prev {
        left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m11 .slick-prev {
        left: 136px; } }
  .m11 .slick-next {
    left: 166px; }
    .m11 .slick-next:hover {
      transform: scale(1.1); }
    @media only screen and (max-width: 767px) {
      .m11 .slick-next {
        left: 68px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m11 .slick-next {
        left: 180px; } }
  .m11 .slick-disabled {
    opacity: 0.3;
    pointer-events: none !important; }

.m13 {
  background-color: #f5f5f5;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m13 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m13 {
      padding: 120px 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m13 {
      padding: 120px 0; } }
  .m13-head__top-txt {
    font-size: 12px;
    font-weight: 500;
    color: #747474;
    line-height: 1.33333em;
    letter-spacing: 1.5px;
    margin-bottom: 9px;
    text-transform: uppercase; }
  .m13-head__title {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: 1.35em;
    letter-spacing: -1px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .m13-head__title {
        font-size: 30px;
        line-height: 1.4em;
        margin-bottom: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m13-head__title {
        font-size: 27px;
        line-height: 1.55556em;
        margin-bottom: 14px; } }
  .m13-body-slider {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .m13-body-slider {
        overflow: hidden;
        padding-left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m13-body-slider .slick-list {
        margin: 0 -16px; } }
    @media only screen and (max-width: 767px) {
      .m13-body-slider .slick-track {
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m13-body-slider .slick-track {
        margin-left: -243px; } }
    @media only screen and (min-width: 1200px) {
      .m13-body-slider .slick-track {
        margin-left: -467px; } }
    .m13-body-slider_box {
      width: 256px;
      margin: 40px 35px 64px 0; }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m13-body-slider_box {
          width: 192px; } }
      @media only screen and (max-width: 767px) {
        .m13-body-slider_box {
          width: 202px;
          margin-right: 14px; } }
      .m13-body-slider_box__img {
        width: 100%;
        height: 331px; }
        .m13-body-slider_box__img img {
          object-fit: cover;
          width: 100%;
          height: 100%; }
      .m13-body-slider_box a {
        width: 100%;
        display: block; }
      .m13-body-slider_box h4 {
        font-weight: 500;
        font-size: 22px;
        line-height: 1.45455em;
        letter-spacing: 0.05px;
        color: #1b1b1b;
        margin-top: 13px;
        margin-bottom: 0; }
      .m13-body-slider_box p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42857em;
        color: #505050; }
  .m13-body .slick-prev,
  .m13-body .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: 0;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m13-body .slick-prev:before,
    .m13-body .slick-next:before {
      display: none; }
  .m13-body .slick-prev {
    left: 126px;
    transform: rotate(180deg); }
    .m13-body .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
    @media only screen and (max-width: 767px) {
      .m13-body .slick-prev {
        left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m13-body .slick-prev {
        left: 136px; } }
  .m13-body .slick-next {
    left: 166px; }
    .m13-body .slick-next:hover {
      transform: scale(1.1); }
    @media only screen and (max-width: 767px) {
      .m13-body .slick-next {
        left: 68px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m13-body .slick-next {
        left: 180px; } }
  .m13-body .slick-disabled {
    opacity: 0.3; }

.m12 {
  width: 100%;
  padding: 150px 0; }
  .m12.gray_background {
    background: #F1F1F1; }
  @media only screen and (max-width: 767px) {
    .m12 {
      padding: 100px 0;
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m12 {
      padding: 120px 0;
      overflow: hidden; } }
  .m12__inner {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m12__inner {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m12__inner {
        flex-direction: column; } }
  .m12__left {
    width: 485px;
    min-width: 485px;
    padding-right: 35px;
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .m12__left {
        width: 100%;
        padding-right: 0;
        margin-top: 35px;
        min-width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m12__left {
        width: 80%;
        padding-right: 0;
        margin-top: 35px;
        min-width: 80%; } }
    .m12__left a {
      font-weight: 800;
      font-size: 15px;
      line-height: 1.06667em;
      letter-spacing: 1.33px;
      color: #2a6ebb;
      text-transform: uppercase;
      border-bottom: 2px solid #2a6ebb;
      margin-top: 50px;
      display: inline-block;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m12__left a:hover {
        color: #0d1e5a;
        border-color: #0d1e5a; }
    .m12__left p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #000; }
      .m12__left p:not(.excerpt) {
        padding-right: 10px; }
      .m12__left p.excerpt {
        font-weight: normal;
        font-size: 24px;
        line-height: 1.66667em;
        letter-spacing: 0.5px;
        margin-bottom: 40px; }
        @media only screen and (max-width: 767px) {
          .m12__left p.excerpt {
            font-size: 22px;
            line-height: 1.45455em; } }
  .m12__right {
    width: calc(100% - 450px);
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .m12__right {
        width: 100%;
        margin-top: 32px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m12__right {
        width: 80%;
        margin-top: 55px; } }
    .m12__right.m12-slider {
      margin-right: -50%;
      width: 100vw !important;
      position: relative;
      margin-left: -50%;
      left: 50%;
      max-width: none;
      padding-bottom: 56px; }
      @media only screen and (max-width: 767px) {
        .m12__right.m12-slider {
          padding-bottom: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m12__right.m12-slider {
          padding-bottom: 0; } }
    .m12__right .slick-slide {
      width: 700px;
      outline: 0;
      margin-right: 15px;
      opacity: 0.4; }
      .m12__right .slick-slide.slick-current {
        opacity: 1; }
      @media only screen and (max-width: 767px) {
        .m12__right .slick-slide {
          width: 320px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m12__right .slick-slide {
          width: 500px;
          margin-right: 23px; } }
    .m12__right img {
      width: 100%;
      height: 480px;
      object-fit: cover; }
      @media only screen and (max-width: 767px) {
        .m12__right img {
          height: 190px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m12__right img {
          height: 295px; } }
    .m12__right__bottom {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5em;
      color: #000000;
      margin-top: 15px; }
      .m12__right__bottom span {
        color: #0d1e5a;
        white-space: nowrap;
        margin-right: 15px; }
    .m12__right .slick-prev,
    .m12__right .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      bottom: 0;
      z-index: 2;
      transform: none;
      top: unset;
      background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m12__right .slick-prev:before,
      .m12__right .slick-next:before {
        display: none; }
      @media only screen and (max-width: 767px) {
        .m12__right .slick-prev,
        .m12__right .slick-next {
          display: none !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m12__right .slick-prev,
        .m12__right .slick-next {
          display: none !important; } }
    .m12__right .slick-dotted.slick-slider {
      margin-bottom: 0; }
    .m12__right .slick-prev {
      left: 0;
      transform: rotate(180deg); }
      .m12__right .slick-prev:hover {
        transform: rotate(180deg) scale(1.1); }
    .m12__right .slick-next {
      left: 40px; }
      .m12__right .slick-next:hover {
        transform: scale(1.1); }
    .m12__right .slick-disabled {
      opacity: 0.3; }

.m14 {
  position: relative;
  padding: 150px 0px;
  background: #f5f5f5; }
  @media only screen and (max-width: 767px) {
    .m14 {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m14 {
      padding: 120px 0px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m14 {
      padding: 120px 0px; } }
  .m14-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 78px; }
    @media only screen and (max-width: 767px) {
      .m14-head {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m14-head {
        flex-direction: column; } }
    .m14-head__left h2 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-top: 7px; }
      @media only screen and (max-width: 767px) {
        .m14-head__left h2 {
          font-size: 27px;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m14-head__left h2 {
          font-size: 32px;
          margin-bottom: 20px; } }
    .m14-head__left span {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33333em;
      color: #747474;
      letter-spacing: 1.5px;
      text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .m14 .accordion {
      margin-bottom: 75px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m14 .accordion {
      margin-bottom: 75px; } }
  .m14 .accordion h2 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 26px;
    line-height: 1.46154em;
    color: #000;
    padding-bottom: 26px;
    border-bottom: 1px solid #dcdcdc; }
    @media only screen and (max-width: 767px) {
      .m14 .accordion h2 {
        font-size: 20px; } }
  .m14 .accordion .accordion-section-title {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.63636em;
    color: #1b1b1b;
    padding: 27px 0;
    border-bottom: 1px solid #dcdcdc;
    background: url("./images/icon-down-arrow.svg") no-repeat right;
    background-size: 22px;
    padding-right: 40px;
    padding-right: 40px; }
    @media only screen and (max-width: 767px) {
      .m14 .accordion .accordion-section-title {
        font-size: 18px;
        line-height: 1.3em;
        padding: 25px 0;
        padding-right: 40px;
        font-family: 'Noah Text';
        background: url("./images/icon-down-arrow.svg") no-repeat right 28px;
        background-size: 22px; } }
    .m14 .accordion .accordion-section-title:last-child {
      border-bottom: 0 !important; }
    .m14 .accordion .accordion-section-title.active {
      background: url("./images/icon-up-arrow.svg") no-repeat right;
      background-size: 22px;
      border-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m14 .accordion .accordion-section-title.active {
          background: url("./images/icon-up-arrow.svg") no-repeat right 28px;
          background-size: 22px; } }
  .m14 .accordion .accordion-section-content {
    padding-bottom: 28px;
    display: none;
    border-bottom: 1px solid #dcdcdc; }
    .m14 .accordion .accordion-section-content:first-child {
      display: block; }
    .m14 .accordion .accordion-section-content:last-child {
      border-bottom: 0; }
    .m14 .accordion .accordion-section-content p {
      color: #1b1b1b;
      font-size: 16px;
      line-height: 1.5em; }
      @media only screen and (max-width: 767px) {
        .m14 .accordion .accordion-section-content p {
          font-family: 'Noah Text'; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m14 .accordion .accordion-section-content p {
          font-size: 18px; } }
      .m14 .accordion .accordion-section-content p a {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
        color: #2a6ebb; }
        @media only screen and (max-width: 767px) {
          .m14 .accordion .accordion-section-content p a {
            font-family: 'Noah Text';
            font-weight: 800; } }
  .m14 p.excerpt {
    max-width: 866px;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.66667em;
    letter-spacing: 0.05px;
    color: #000;
    margin-top: 24px; }
    @media only screen and (max-width: 767px) {
      .m14 p.excerpt {
        font-size: 22px;
        line-height: 1.45455em; } }

.pdr35 {
  padding-right: 35px; }
  @media only screen and (max-width: 767px) {
    .pdr35 {
      padding-right: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .pdr35 {
      padding-right: 0; } }

.pdl35 {
  padding-left: 35px; }
  @media only screen and (max-width: 767px) {
    .pdl35 {
      padding-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .pdl35 {
      padding-left: 0; } }

@media only screen and (max-width: 767px) {
  .m14 .row {
    margin-left: 0;
    margin-right: 0; } }

.m15 {
  width: 100%;
  padding: 150px 0px; }
  @media only screen and (max-width: 767px) {
    .m15 {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m15 {
      padding: 120px 0px; } }
  .m15__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 96px; }
    @media only screen and (max-width: 767px) {
      .m15__top {
        flex-direction: column;
        margin-bottom: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m15__top {
        flex-direction: column;
        margin-bottom: 0; } }
    .m15__top__left {
      max-width: 70%; }
      @media only screen and (max-width: 767px) {
        .m15__top__left {
          max-width: 100%; } }
    .m15__top h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-bottom: 21px; }
      @media only screen and (max-width: 767px) {
        .m15__top h3 {
          font-size: 30px;
          line-height: 1.4em;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m15__top h3 {
          font-size: 27px;
          line-height: 1.55556em;
          margin-bottom: 20px; } }
    .m15__top p {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.66667em;
      letter-spacing: 0.5px;
      color: #000; }
      @media only screen and (max-width: 767px) {
        .m15__top p {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m15__top p {
          display: none; } }
  .m15 .row {
    margin-left: -68px;
    margin-right: -68px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m15 .row {
        margin-left: 0px;
        margin-right: 0px; } }
  .m15 .column-4 {
    border-right: 1px solid #dcdcdc;
    padding-left: 68px;
    padding-right: 68px;
    min-height: 300px;
    display: flex;
    align-items: center; }
    .m15 .column-4:nth-child(3n) {
      border-right: 0; }
    @media only screen and (max-width: 767px) {
      .m15 .column-4:last-child .m15_box {
        border-bottom: 0;
        padding-bottom: 0; } }
    @media only screen and (max-width: 767px) {
      .m15 .column-4 {
        border-right: 0;
        min-height: auto; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m15 .column-4 {
        padding-left: 0;
        padding-right: 0;
        border-right: 0;
        min-height: auto; } }
  @media only screen and (max-width: 767px) {
    .m15_box {
      padding-bottom: 50px;
      margin-top: 50px;
      border-bottom: 1px solid #dcdcdc; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m15_box {
      padding-bottom: 50px;
      padding-right: 40%;
      margin-top: 50px;
      border-bottom: 1px solid #dcdcdc; } }
  .m15_box h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.45455em;
    letter-spacing: 0.5px;
    color: #1b1b1b;
    margin-bottom: 29px; }
    @media only screen and (max-width: 767px) {
      .m15_box h3 {
        margin-bottom: 19px; } }
  .m15_box p {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.58824em;
    color: #000000;
    margin-bottom: 32px; }
    @media only screen and (max-width: 767px) {
      .m15_box p {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.02px;
        margin-bottom: 50px; } }

.m16 {
  width: 100%;
  padding: 150px 0; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m16 {
      padding: 120px 0; } }
  @media only screen and (max-width: 767px) {
    .m16 {
      padding: 100px 0; }
      .m16 .container {
        padding: 0; }
      .m16 .row {
        margin: 0 !important; } }
  .m16 h1 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: 1.35em;
    letter-spacing: -1px;
    margin-bottom: 20px;
    max-width: 299px; }
    @media only screen and (max-width: 767px) {
      .m16 h1 {
        font-size: 30px;
        line-height: 1.4em;
        margin-bottom: 24px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m16 h1 {
        font-size: 27px;
        line-height: 1.55556em;
        margin-bottom: 24px; } }
  .m16-subtitle {
    max-width: 490px;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.55em;
    letter-spacing: 0.5px; }
    @media only screen and (max-width: 767px) {
      .m16-subtitle {
        font-size: 22px;
        line-height: 1.45455em;
        letter-spacing: 0.25px; } }
  .m16-text {
    font-weight: 800;
    font-size: 16px;
    line-height: 1.5em; }
  .m16-button {
    margin-top: 25px;
    float: left;
    padding: 10px 23px 8px 16px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #2a6ebb;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    display: block; }
    @media only screen and (max-width: 767px) {
      .m16-button {
        margin-top: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m16-button {
        margin-top: 16px; } }
    @media only screen and (max-width: 330px) {
      .m16-button {
        font-size: 13px; } }
    .m16-button:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url("./images/icon-arrow-right-white.svg") no-repeat center;
      margin-right: 16px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m16-button:hover {
      background-color: #2a6ebb;
      color: #fff; }
      .m16-button:hover:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
  .m16-button--outlined {
    border-color: #2a6ebb;
    background-color: transparent;
    color: #2a6ebb; }
    .m16-button--outlined:before {
      background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }
    .m16-button--outlined:hover {
      color: #2a6ebb;
      background: transparent; }
      .m16-button--outlined:hover:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }
  .m16-left {
    padding-right: 80px !important; }
    @media only screen and (min-width: 1200px) {
      .m16-left {
        position: sticky;
        top: 80px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m16-left.column-mob-12 {
        padding-left: 16px !important;
        padding-right: 16px !important; } }
    @media only screen and (max-width: 767px) {
      .m16-left.column-tab-12 {
        padding-left: 16px !important;
        padding-right: 16px !important; } }
    .m16-left .m16-text {
      margin-top: 66px;
      margin-bottom: 54px;
      font-weight: 400; }
      @media only screen and (max-width: 767px) {
        .m16-left .m16-text {
          margin-top: 16px;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m16-left .m16-text {
          margin-top: 16px;
          margin-bottom: 16px; } }
    .m16-left-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
  .m16-right {
    padding-left: 80px !important; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m16-right {
        margin-top: 45px; } }
    @media only screen and (max-width: 767px) {
      .m16-right {
        margin-top: 80px;
        padding-left: 0 !important;
        padding-right: 0 !important; } }
  .m16-steps__step {
    position: relative;
    max-width: 470px;
    padding-bottom: 58px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m16-steps__step {
        padding-bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .m16-steps__step {
        max-width: 100%;
        padding-bottom: 80px; } }
    .m16-steps__step:last-child {
      padding-bottom: 0; }
    .m16-steps__step-line {
      position: absolute;
      left: -42px;
      height: 101%;
      width: 1px;
      background: #dcdcdc; }
      .m16-steps__step-line span {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #ffc400;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -15px;
        font-weight: 800;
        font-size: 12px;
        line-height: 1.125em; }
      @media only screen and (max-width: 767px) {
        .m16-steps__step-line {
          position: relative;
          left: 0;
          height: 1px;
          width: 100%;
          margin-bottom: 40px; }
          .m16-steps__step-line span {
            left: 16px;
            top: -16px; } }
  .m16-steps-title {
    font-weight: 800;
    font-size: 22px;
    line-height: 1.45455em;
    letter-spacing: 0.5px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 767px) {
      .m16-steps-title {
        padding: 0 16px; } }
  .m16-steps-text {
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0px; }
    @media only screen and (max-width: 767px) {
      .m16-steps-text {
        padding: 0 16px; } }

.m17 {
  width: 100%;
  padding: 150px 0px;
  background-color: #2a6ebb; }
  @media only screen and (max-width: 767px) {
    .m17 {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m17 {
      padding: 120px 0px; }
      .m17 .container {
        padding: 0 110px; } }
  .m17__wrapper {
    width: 100%;
    display: flex;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .m17__wrapper {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m17__wrapper {
        flex-direction: column; } }
  .m17__title {
    width: 248px;
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 28px;
    line-height: 1.5em;
    color: #fff;
    letter-spacing: 0.07px; }
    @media only screen and (max-width: 767px) {
      .m17__title {
        width: 100%;
        font-size: 24px;
        margin-bottom: 8px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m17__title {
        width: 100%;
        font-size: 24px;
        margin-bottom: 30px; } }
  .m17__list {
    width: calc(100% - 248px);
    padding-left: 29px;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m17__list {
        width: 100%;
        padding-left: 0;
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m17__list {
        width: 100%;
        padding-left: 0;
        flex-direction: column; } }
    .m17__list__item {
      width: 100%;
      display: flex;
      flex-direction: column; }
      @media only screen and (max-width: 767px) {
        .m17__list__item {
          flex-direction: row; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m17__list__item {
          flex-direction: row; } }
    .m17__list li {
      width: 33.333%;
      border-left: 1px solid #fff;
      padding-left: 16px; }
      @media only screen and (max-width: 767px) {
        .m17__list li {
          width: 100%;
          padding: 22px 0 22px 26px;
          border-left: 0;
          border-bottom: 1px solid #fff; }
          .m17__list li:last-child {
            border-bottom: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m17__list li {
          width: 100%;
          padding: 22px 0 22px 26px;
          border-left: 0;
          border-bottom: 1px solid #fff; }
          .m17__list li:last-child {
            border-bottom: 0; } }
      .m17__list li.is--active .number_modal {
        opacity: 1;
        visibility: visible;
        overflow: visible; }
    .m17__list__info {
      width: 20px;
      height: 20px;
      background: url("./images/icon-info-circle.svg") no-repeat center;
      background-size: 20px;
      margin-bottom: 15px;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        .m17__list__info {
          margin-bottom: 0;
          margin-top: 4px;
          position: absolute;
          right: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m17__list__info {
          margin-bottom: 0;
          margin-top: 4px;
          position: absolute;
          right: 0; } }
  .m17 h4 {
    font-family: 'Noah Head';
    font-size: 28px;
    font-weight: 500;
    line-height: 1.28571em;
    letter-spacing: 0.07px;
    color: #dcdcdc;
    margin-bottom: 9px; }
    @media only screen and (max-width: 767px) {
      .m17 h4 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: bold;
        color: #fff;
        min-width: 50px;
        text-align: right;
        margin-right: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m17 h4 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: bold;
        color: #fff;
        min-width: 50px;
        text-align: right;
        margin-right: 20px; } }
  .m17 h3 {
    font-family: 'Noah Head';
    font-size: 28px;
    font-weight: 500;
    line-height: 1.28571em;
    letter-spacing: 0.07px;
    color: #fff;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .m17 h3 {
        font-size: 22px;
        margin-bottom: 0;
        font-weight: normal; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m17 h3 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: normal; } }
  .m17 .number_modal {
    display: flex;
    width: 864px;
    background-color: #fff;
    padding: 60px 88px 60px 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .m17 .number_modal {
        width: 100%;
        padding: 32px 26px 40px;
        flex-direction: column;
        border-radius: 4px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m17 .number_modal {
        width: 460px;
        padding: 42px 26px 60px;
        flex-direction: column;
        border-radius: 4px; } }
    .m17 .number_modal h4 {
      color: #0d1e5a; }
      @media only screen and (max-width: 767px) {
        .m17 .number_modal h4 {
          text-align: left; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m17 .number_modal h4 {
          text-align: left; } }
    .m17 .number_modal h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      color: #000000;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m17 .number_modal h3 {
          margin-bottom: 14px; } }
    .m17 .number_modal__left {
      width: 248px; }
      @media only screen and (max-width: 767px) {
        .m17 .number_modal__left {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m17 .number_modal__left {
          width: 100%; } }
    .m17 .number_modal__right {
      width: calc(100% - 248px);
      padding-left: 60px; }
      @media only screen and (max-width: 767px) {
        .m17 .number_modal__right {
          width: 100%;
          padding-left: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m17 .number_modal__right {
          width: 100%;
          padding-left: 0; } }
      .m17 .number_modal__right p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000000; }
    .m17 .number_modal__close {
      width: 16px;
      height: 16px;
      background: url("./images/icon-close.svg") no-repeat;
      background-size: 16px;
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer; }

.m23 {
  width: 100%;
  padding: 125px 0; }
  @media only screen and (max-width: 767px) {
    .m23 {
      padding: 110px 0 80px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m23 {
      padding: 110px 0 80px; } }
  .m23 figure {
    width: 100%; }
    .m23 figure img {
      width: 100%; }
  .m23 figcaption {
    max-width: 940px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857em;
    color: #505050;
    margin-top: 16px; }

.m18 {
  width: 100%;
  padding: 150px 0;
  background-color: #2a6ebb; }
  @media only screen and (max-width: 767px) {
    .m18 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m18 {
      padding: 120px 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m18 {
      padding: 120px 0; } }
  .m18__wrapper {
    width: 100%;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m18__wrapper {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m18__wrapper {
        flex-direction: column; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m18__wrapper {
        flex-direction: column; } }
  .m18__left {
    width: calc(100% - 240px);
    padding-right: 172px; }
    @media only screen and (max-width: 767px) {
      .m18__left {
        width: 100%;
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m18__left {
        width: 100%;
        padding-right: 0; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m18__left {
        width: 100%;
        padding-right: 0; } }
    .m18__left h5 {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33333em;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase;
      margin-bottom: 16px; }
      @media only screen and (max-width: 767px) {
        .m18__left h5 {
          font-size: 8px;
          line-height: 2em;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m18__left h5 {
          font-size: 8px;
          line-height: 2em;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m18__left h5 {
          font-size: 8px;
          line-height: 2em;
          margin-bottom: 10px; } }
    .m18__left h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 26px;
      line-height: 1.46154em;
      color: #fff;
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .m18__left h3 {
          font-size: 21px;
          line-height: 1.33333em;
          margin-bottom: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m18__left h3 {
          font-size: 21px;
          line-height: 1.33333em;
          letter-spacing: 0.7px;
          margin-bottom: 30px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m18__left h3 {
          font-size: 21px;
          line-height: 1.33333em;
          letter-spacing: 0.7px;
          margin-bottom: 30px; } }
    .m18__left p {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5em;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        .m18__left p {
          font-size: 12px;
          line-height: 1.5em;
          margin-bottom: 50px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m18__left p {
          font-size: 12px;
          line-height: 1.5em;
          margin-bottom: 40px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m18__left p {
          font-size: 12px;
          line-height: 1.5em;
          margin-bottom: 40px; } }
  .m18__right {
    width: 240px; }
    @media only screen and (max-width: 767px) {
      .m18__right {
        width: 100%;
        display: flex;
        justify-content: space-between; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m18__right {
        width: 100%;
        display: flex; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m18__right {
        width: 100%;
        display: flex; } }
    .m18__right__image {
      width: 150px;
      height: 150px;
      border: 2px solid #fff;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 13px; }
      @media only screen and (max-width: 767px) {
        .m18__right__image {
          width: 100px;
          height: 100px; } }
    @media only screen and (max-width: 767px) {
      .m18__right__content {
        width: calc(100% - 110px); } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m18__right__content {
        padding-left: 24px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m18__right__content {
        padding-left: 24px; } }
    .m18__right__content h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.33333em;
      letter-spacing: 0.05px;
      color: #fff;
      margin-bottom: 5px; }
      @media only screen and (max-width: 767px) {
        .m18__right__content h4 {
          font-size: 22px;
          line-height: 1.45455em; } }
    .m18__right__content p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #fff;
      margin-bottom: 2px; }
    .m18__right__content a {
      display: block;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #fff; }
  .m18.gray {
    background-color: #f1f1f1; }
    .m18.gray h3,
    .m18.gray h4,
    .m18.gray p,
    .m18.gray a {
      color: #000 !important; }
    .m18.gray h5 {
      color: rgba(0, 0, 0, 0.6); }

.m19.show-right-side {
  padding-top: 50px; }
  .m19.show-right-side .m19-quote {
    padding-left: 170px; }
    @media only screen and (max-width: 767px) {
      .m19.show-right-side .m19-quote {
        padding-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m19.show-right-side .m19-quote {
        padding-left: 50px; } }
    .m19.show-right-side .m19-quote-left {
      width: 760px; }
      @media only screen and (max-width: 767px) {
        .m19.show-right-side .m19-quote-left {
          width: 100%; } }
    .m19.show-right-side .m19-quote-text {
      max-width: 600px;
      width: 100%; }
      @media only screen and (max-width: 767px) {
        .m19.show-right-side .m19-quote-text {
          max-width: 100%; } }
      .m19.show-right-side .m19-quote-text:after {
        bottom: 35px;
        right: -53px; }
        @media only screen and (max-width: 767px) {
          .m19.show-right-side .m19-quote-text:after {
            top: -30px;
            bottom: unset; } }
    .m19.show-right-side .m19-quote-author {
      bottom: 0; }
      .m19.show-right-side .m19-quote-author p {
        left: 0; }

.m19-quote {
  position: relative;
  padding: 20px 0 40px 0; }
  .m19-quote > .row {
    align-items: flex-end;
    display: flex; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m19-quote > .row {
        align-items: inherit;
        display: inherit; } }
    @media only screen and (max-width: 767px) {
      .m19-quote > .row {
        align-items: inherit;
        display: inherit; } }
  .m19-quote-text {
    position: relative;
    max-width: 572px;
    text-align: justify; }
    .m19-quote-text:after {
      content: '';
      width: 37px;
      height: 20px;
      display: inline-block;
      background: url("./images/quotationsmarks.svg") no-repeat center;
      background-size: cover;
      position: absolute;
      right: -40px;
      bottom: 10px; }
    .m19-quote-text p {
      max-width: 100%;
      font-size: 22px;
      font-weight: normal;
      font-style: italic;
      line-height: 1.63636em;
      padding: 0; }
    @media only screen and (max-width: 767px) {
      .m19-quote-text {
        max-width: 100%; }
        .m19-quote-text:after {
          right: inherit;
          bottom: inherit;
          top: -20px;
          left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m19-quote-text {
        max-width: 100%; }
        .m19-quote-text:after {
          right: inherit;
          bottom: inherit;
          top: -20px;
          left: 0; } }
  .m19-quote-author {
    position: relative;
    bottom: -30px; }
    .m19-quote-author p {
      max-width: 100%;
      text-align: left;
      margin: 0;
      left: -30px;
      position: relative;
      padding: 0 !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em; }
    @media only screen and (max-width: 767px) {
      .m19-quote-author {
        bottom: 0; }
        .m19-quote-author p {
          left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m19-quote-author {
        bottom: 0; }
        .m19-quote-author p {
          left: 0; } }

.m20 {
  margin: 30px 0; }
  .m20 .slick-slide {
    width: 100%;
    max-width: 777px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m20 .slick-slide {
        max-width: 400px; } }
    @media only screen and (max-width: 767px) {
      .m20 .slick-slide {
        max-width: 320px; } }
    .m20 .slick-slide p {
      margin: 16px 0 !important;
      padding: 0 !important; }
      @media only screen and (max-width: 767px) {
        .m20 .slick-slide p {
          max-width: 247px; } }
  .m20 .slick-slide {
    margin-right: 100px;
    opacity: 0.4; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m20 .slick-slide {
        margin-right: 16px; } }
    @media only screen and (max-width: 767px) {
      .m20 .slick-slide {
        margin-right: 16px; } }
  .m20 .slick-slide__title-wrapper {
    display: flex;
    justify-content: space-between; }
  .m20 .slick-slide.slick-current {
    opacity: 1; }
  .m20 .slick-slide img {
    width: 100%;
    height: 544px;
    object-fit: cover; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m20 .slick-slide img {
        max-height: 260px; } }
    @media only screen and (max-width: 767px) {
      .m20 .slick-slide img {
        max-height: 210px; } }
  .m20 .slick-slide__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857em;
    color: #505050;
    margin-top: 7px;
    margin-bottom: 0; }
  .m20 .slick-prev,
  .m20 .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -25px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m20 .slick-prev:before,
    .m20 .slick-next:before {
      display: none; }
  .m20 .slick-prev {
    left: 0;
    transform: rotate(180deg); }
    .m20 .slick-prev:hover {
      transform: rotate(180deg) scale(1.1); }
  .m20 .slick-next {
    left: 40px; }
    .m20 .slick-next:hover {
      transform: scale(1.1); }
  .m20 .slick-disabled {
    opacity: 0.3; }

.m21 {
  padding-top: 100px;
  padding-bottom: 150px; }
  @media only screen and (max-width: 767px) {
    .m21 {
      padding-top: 0;
      padding-bottom: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m21 {
      padding-top: 0;
      padding-bottom: 120px; } }

@keyframes m21FadeEffect {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .m21__top {
    max-width: 670px;
    margin-bottom: 60px; }
    .m21__top h3 {
      font-family: 'Noah Head';
      font-size: 32px;
      line-height: 1.3125em;
      font-weight: 600;
      color: #000;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        .m21__top h3 {
          font-size: 26px;
          line-height: 1.23077em; } }
    .m21__top p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #000; }
      @media only screen and (max-width: 767px) {
        .m21__top p {
          font-weight: 400; } }
  .m21 .gform_wrapper {
    width: 100%;
    float: left; }
  .m21 .gform_body {
    float: left;
    width: 100%; }
  .m21 .gfield_required {
    display: none; }
  .m21 .gfield_label {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33333em;
    letter-spacing: 0.05px;
    color: #000000; }
  .m21 .gfield_radio {
    display: flex; }
    .m21 .gfield_radio input[type='radio']:checked + label:after {
      opacity: 1; }
    .m21 .gfield_radio label {
      display: flex;
      align-items: center;
      position: relative; }
      .m21 .gfield_radio label:before {
        content: '';
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #000000;
        margin-right: 9px; }
      .m21 .gfield_radio label:after {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #000;
        opacity: 0;
        position: absolute;
        left: 5px; }
    .m21 .gfield_radio li {
      position: relative;
      margin-top: 34px;
      margin-right: 50px; }
      .m21 .gfield_radio li:last-child {
        margin-right: 0; }
    .m21 .gfield_radio input {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
  .m21 .gform_ajax_spinner {
    display: none; }
  .m21 .gfield {
    width: 100%;
    float: left;
    margin-bottom: 30px; }
    .m21 .gfield input {
      border: 0;
      outline: none;
      width: 100% !important;
      border-bottom: 1px solid #959595;
      color: #959595;
      font-size: 16px;
      font-weight: 500;
      padding: 0 !important;
      padding-bottom: 2px !important;
      line-height: 1.5em; }
      .m21 .gfield input::-webkit-input-placeholder {
        color: #959595;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500; }
      .m21 .gfield input:-moz-placeholder {
        color: #959595;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500; }
      .m21 .gfield input::-moz-placeholder {
        color: #959595;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500; }
      .m21 .gfield input:-ms-input-placeholder {
        color: #959595;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500; }
    .m21 .gfield input[type='checkbox'] {
      width: initial !important;
      margin-top: 12px !important; }
    .m21 .gfield select {
      border: 0;
      outline: none;
      width: 100% !important;
      border-bottom: 1px solid #959595;
      color: #959595;
      font-size: 16px;
      font-weight: 500;
      padding: 0 !important;
      padding-bottom: 6px !important; }
      .m21 .gfield select option {
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        padding: 0 !important;
        margin-left: 0 !important; }
    .m21 .gfield textarea {
      border: 0;
      outline: none;
      width: 100%;
      height: 252px !important;
      font-size: 16px;
      font-weight: 500;
      color: #959595;
      border-bottom: 1px solid #959595;
      padding: 0; }
      .m21 .gfield textarea::-webkit-input-placeholder {
        font-weight: 500;
        font-size: 16px;
        color: #959595; }
      .m21 .gfield textarea:-moz-placeholder {
        font-weight: 500;
        font-size: 16px;
        color: #959595; }
      .m21 .gfield textarea::-moz-placeholder {
        font-weight: 500;
        font-size: 16px;
        color: #959595; }
      .m21 .gfield textarea:-ms-input-placeholder {
        font-weight: 500;
        font-size: 16px;
        color: #959595; }
      @media only screen and (max-width: 767px) {
        .m21 .gfield textarea {
          height: 120px !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m21 .gfield textarea {
          height: 120px !important; } }
    .m21 .gfield .gform_button_select_files {
      position: relative;
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #959595;
      border-radius: 8px;
      background-color: transparent;
      font-size: 0; }
    .m21 .gfield .gform_drop_instructions {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
      font-size: 16px;
      line-height: 1.5em;
      font-weight: 500;
      color: #959595;
      white-space: nowrap;
      background-image: url("./images/upload_icon.svg");
      background-position: left;
      background-size: 21px;
      background-repeat: no-repeat;
      padding-left: 36px; }
  .m21 .screen-reader-text {
    display: none; }
  .m21 .gfield_description:not(.validation_message) {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #000000;
    max-width: 540px;
    margin-top: 5px; }
  .m21 .validation_message {
    bottom: calc(-100% + 5px); }
  .m21 .ginput_container_fileupload {
    margin-bottom: 32px;
    margin-top: 20px; }
  .m21 .gfield.gfield_container_textearea_holder {
    float: none;
    width: 50%;
    display: inline-block;
    padding-left: 16px; }
    @media only screen and (max-width: 767px) {
      .m21 .gfield.gfield_container_textearea_holder {
        width: 100%;
        padding-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m21 .gfield.gfield_container_textearea_holder {
        width: 100%;
        padding-left: 0; } }
    .m21 .gfield.gfield_container_textearea_holder .validation_message {
      bottom: -20px;
      padding-left: 16px; }
      @media only screen and (max-width: 767px) {
        .m21 .gfield.gfield_container_textearea_holder .validation_message {
          padding-left: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m21 .gfield.gfield_container_textearea_holder .validation_message {
          padding-left: 0; } }
  .m21 .gfield.gfield_container_file_holder {
    margin-bottom: 0; }
    .m21 .gfield.gfield_container_file_holder .validation_message {
      bottom: 8px; }
  .m21 .gfield_container_radio_holder .validation_message {
    bottom: -22px; }
  .m21 li.gfield:not(.gfield_container_radio_holder):not(.gfield_container_textearea_holder):not(.gfield_container_file_holder) {
    float: left;
    width: 50%;
    display: inline-block;
    clear: left; }
    @media only screen and (max-width: 767px) {
      .m21 li.gfield:not(.gfield_container_radio_holder):not(.gfield_container_textearea_holder):not(.gfield_container_file_holder) {
        width: 100%;
        padding-right: 0 !important; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m21 li.gfield:not(.gfield_container_radio_holder):not(.gfield_container_textearea_holder):not(.gfield_container_file_holder) {
        width: 100%;
        padding-right: 0 !important; } }
  .m21 .m21-tab-content--1 textarea {
    height: 197px !important; }
    @media only screen and (max-width: 767px) {
      .m21 .m21-tab-content--1 textarea {
        height: 120px !important; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m21 .m21-tab-content--1 textarea {
        height: 120px !important; } }
  .m21 .gfield_error textarea,
  .m21 .gfield_error select,
  .m21 .gfield_error input {
    border-color: #ea0f0f !important; }
  .m21-text,
  .m21 .m21-content-holder p,
  .m21 .m21-content-holder li {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #000000; }
  .m21 .m21-content-holder p {
    margin-bottom: 30px; }
  .m21-text-lg {
    font-size: 18px;
    line-height: 1.33333em;
    letter-spacing: 0.05px;
    color: #000000;
    font-weight: 500; }
  .m21-mb-30 {
    margin-bottom: 30px; }
  .m21-link,
  .m21 .m21-content-holder a {
    font-weight: 600;
    color: #2a6ebb; }
    .m21-link:hover,
    .m21 .m21-content-holder a:hover {
      color: #2363ac; }
  .m21-title {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 32px;
    color: #000000;
    line-height: 1.3125em; }
    @media only screen and (max-width: 767px) {
      .m21-title {
        font-size: 22px;
        line-height: 1.36364em;
        margin-bottom: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m21-title {
        font-size: 22px;
        line-height: 1.36364em;
        margin-bottom: 14px; } }
  .m21 .m21-content-holder ul {
    padding-left: 30px; }
    .m21 .m21-content-holder ul li {
      margin-bottom: 5px; }
    .m21 .m21-content-holder ul li::before {
      content: '\2022';
      font-weight: 800;
      display: inline-block;
      width: 1em;
      color: #2a6ebb; }
  .m21-tab-img {
    width: 312px;
    height: 214px;
    object-fit: contain;
    margin: 60px auto;
    display: flex; }
  .m21-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px; }
    @media only screen and (max-width: 767px) {
      .m21-tabs {
        width: 100%;
        overflow-x: auto;
        margin-bottom: 30px; } }
    .m21-tabs-tab {
      flex: 1;
      font-size: 24px;
      line-height: 1.41667em;
      letter-spacing: 0.05px;
      font-weight: 400;
      color: #1b1b1b;
      cursor: pointer;
      padding-bottom: 12px;
      border-bottom: 2px solid #707070; }
      .m21-tabs-tab:first-child {
        margin-right: 16px; }
      .m21-tabs-tab:hover {
        color: #707070; }
      .m21-tabs-tab[data-active='true'] {
        border-bottom-color: #ffca00; }
      @media only screen and (max-width: 767px) {
        .m21-tabs-tab {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.875em;
          margin-right: 16px !important;
          white-space: nowrap; }
          .m21-tabs-tab:first-child {
            flex: 2; }
          .m21-tabs-tab:last-child {
            margin-right: 0 !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m21-tabs-tab {
          font-size: 22px;
          line-height: 1.36364em; } }
  .m21-tab-content {
    animation: m21FadeEffect 1s;
    display: none; }
    .m21-tab-content[data-active='true'] {
      display: inherit; }
    .m21-tab-content--1 .m21-content-holder ul, .m21-tab-content--2 .m21-content-holder ul {
      margin-bottom: 30px; }
    .m21-tab-content--1 .form-textarea {
      min-height: 251px; }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m21-tab-content--1 .form-textarea {
          min-height: 130px !important; } }
    .m21-tab-content--2 .m21-request > .m21-title {
      margin-bottom: 78px; }
    .m21-tab-content--2 .form-textarea {
      min-height: 198px; }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m21-tab-content--2 .form-textarea {
          min-height: 130px !important; } }
  .m21-request {
    margin-top: 100px; }
    @media only screen and (max-width: 767px) {
      .m21-request {
        margin-top: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m21-request {
        margin-top: 0; } }
    .m21-request .m21-title {
      margin-bottom: 25px; }
    .m21-request__info {
      max-width: 278px; }
      .m21-request__info p:first-child {
        margin-bottom: 50px; }
      .m21-request__info p:nth-child(2) {
        max-width: 217px; }
        @media only screen and (max-width: 767px) {
          .m21-request__info p:nth-child(2) {
            max-width: 260px; } }
      .m21-request__info p:nth-child(3) {
        margin-bottom: 20px;
        margin-top: 20px; }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m21-request__info {
          max-width: 400px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 767px) {
        .m21-request__info {
          max-width: 100%;
          margin-bottom: 50px; } }
  .m21-therapy-request-form-radios {
    display: flex;
    margin-bottom: 38px; }
    .m21-therapy-request-form-radios label:first-child {
      margin-right: 50px; }
  .m21-therapy-request-form .form-group {
    margin-bottom: 30px; }
  .m21 .file-uploader {
    margin-top: 22px; }
  .m21 .gform_footer {
    position: relative;
    float: left;
    width: 100%; }
  .m21 .m31-button-reset {
    position: absolute;
    right: 180px;
    top: 20px; }
    @media only screen and (max-width: 767px) {
      .m21 .m31-button-reset {
        top: 25px; } }
  .m21 .gform_button, .m21-button {
    margin-top: 10px;
    float: right;
    padding: 10px 20px 8px 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #2a6ebb;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    display: block;
    cursor: pointer;
    outline: none; }
    @media only screen and (max-width: 767px) {
      .m21 .gform_button, .m21-button {
        margin-top: 15px; } }
    .m21 .gform_button:before, .m21-button:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url("./images/icon-arrow-right-white.svg") no-repeat center;
      margin-right: 16px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m21 .gform_button:hover, .m21-button:hover {
      background-color: #2a6ebb;
      color: #fff; }
      .m21 .gform_button:hover:before, .m21-button:hover:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
  .m21-submit-button {
    display: flex;
    justify-content: flex-end; }
  .m21 .form-form {
    display: none; }

.m22 {
  width: 100%;
  padding: 150px 0px;
  background-color: #f5f5f5; }
  @media only screen and (max-width: 767px) {
    .m22 {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m22 {
      padding: 120px 0px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m22 {
      padding: 120px 0px; } }
  .m22__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m22__top {
        flex-direction: column; } }
    @media only screen and (max-width: 767px) {
      .m22__top {
        flex-direction: column; } }
    .m22__top__left {
      max-width: 70%; }
    .m22__top h3 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-bottom: 21px; }
      @media only screen and (max-width: 767px) {
        .m22__top h3 {
          font-size: 30px;
          line-height: 1.4em;
          margin-bottom: 21px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m22__top h3 {
          font-size: 32px;
          line-height: 1.3125em;
          letter-spacing: 0.01px;
          margin-bottom: 21px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m22__top h3 {
          font-size: 32px;
          line-height: 1.3125em;
          letter-spacing: 0.01px;
          margin-bottom: 21px; } }
    .m22__top p {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.66667em;
      letter-spacing: 0.5px;
      color: #000; }
      @media only screen and (max-width: 767px) {
        .m22__top p {
          font-size: 12px;
          line-height: 1.33333em;
          letter-spacing: 0.15px;
          margin-bottom: 15px; } }
  .m22__wrapper {
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 54px; }
    @media only screen and (max-width: 767px) {
      .m22__wrapper {
        flex-direction: column-reverse;
        margin-top: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m22__wrapper {
        flex-direction: column-reverse;
        margin-top: 50px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m22__wrapper {
        flex-direction: column-reverse;
        margin-top: 50px; } }
  .m22__content {
    width: calc(100% - 670px);
    padding-right: 40px;
    padding-top: 80px; }
    @media only screen and (max-width: 767px) {
      .m22__content {
        width: 100%;
        padding-right: 0;
        padding-top: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m22__content {
        width: 100%;
        padding-right: 0;
        padding-top: 50px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m22__content {
        width: 100%;
        padding-right: 0;
        padding-top: 50px; } }
    .m22__content h3 {
      font-family: 'Noah Head';
      font-weight: 500;
      font-size: 26px;
      line-height: 1.46154em;
      color: #000000;
      margin-bottom: 26px; }
      @media only screen and (max-width: 767px) {
        .m22__content h3 {
          font-size: 24px;
          line-height: 1.41667em;
          margin-bottom: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m22__content h3 {
          font-size: 24px;
          line-height: 1.41667em;
          margin-bottom: 18px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m22__content h3 {
          font-size: 24px;
          line-height: 1.41667em;
          margin-bottom: 18px; } }
    .m22__content p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #000;
      margin-bottom: 62px; }
      @media only screen and (max-width: 767px) {
        .m22__content p {
          margin-bottom: 33px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m22__content p {
          margin-bottom: 43px; } }
      @media only screen and (min-width: 991px) and (max-width: 1199px) {
        .m22__content p {
          margin-bottom: 43px; } }
    .m22__content__bottom h4 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 18px;
      line-height: 1.33333em;
      margin-bottom: 6px; }
    .m22__content__bottom h5 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42857em;
      color: #505050; }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m22__content__bottom h5 {
          font-size: 16px;
          line-height: 1.5em; } }
  .m22__image {
    width: 670px;
    height: 470px; }
    @media only screen and (max-width: 767px) {
      .m22__image {
        width: 100%;
        height: 225px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m22__image {
        width: 100%;
        height: 407px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m22__image {
        width: 100%;
        height: 407px; } }

.m24 {
  width: 100%;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m24 {
      padding: 0 0 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m24 {
      padding: 0 0 120px; } }
  .m24__inner {
    width: 100%;
    display: flex; }
  .m24__left {
    width: 670px;
    padding-left: 40px; }
    @media only screen and (max-width: 767px) {
      .m24__left {
        width: 100%;
        padding-left: 0;
        padding-top: 140px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m24__left {
        width: 100%;
        padding-left: 0;
        padding-top: 50px; } }
  .m24__item {
    width: 100%;
    padding-top: 40px;
    margin-bottom: 96px; }
    @media only screen and (max-width: 767px) {
      .m24__item {
        padding-top: 50px;
        margin-bottom: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m24__item {
        padding-top: 120px;
        margin-bottom: 0; } }
    .m24__item__inner {
      width: 100%;
      position: relative;
      padding: 58px 55px 54px 98px;
      border-left: 5px solid #0d1e5a;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); }
      @media only screen and (max-width: 767px) {
        .m24__item__inner {
          border-radius: 10px;
          padding: 43px 32px 58px 21px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m24__item__inner {
          border-radius: 10px;
          padding: 43px 32px 58px 21px; } }
    .m24__item:last-of-type {
      margin-bottom: 0; }
    .m24__item__number {
      width: 80px;
      height: 80px;
      position: absolute;
      left: -40px;
      top: -40px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 2px solid #0d1e5a;
      border-radius: 100%;
      font-family: 'Noah Head';
      font-size: 26px;
      font-weight: 500;
      color: #0d1e5a; }
      @media only screen and (max-width: 767px) {
        .m24__item__number {
          width: 40px;
          height: 40px;
          left: 21px;
          top: 43px;
          font-size: 18px;
          padding-top: 2px;
          font-weight: 600; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m24__item__number {
          width: 60px;
          height: 60px;
          left: -30px;
          top: -30px;
          font-size: 24px; } }
    .m24__item__top {
      width: 100%;
      margin-bottom: 40px;
      padding-right: 115px; }
      @media only screen and (max-width: 767px) {
        .m24__item__top {
          padding-right: 0; } }
      .m24__item__top h3 {
        font-family: 'Noah Head';
        font-weight: 800;
        font-size: 26px;
        line-height: 1.46154em;
        color: #000;
        margin-bottom: 24px; }
        @media only screen and (max-width: 767px) {
          .m24__item__top h3 {
            min-height: 40px;
            font-size: 24px;
            line-height: 1.41667em;
            word-break: break-all;
            margin-bottom: 19px;
            padding-left: 54px; } }
      .m24__item__top p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 0; }
    .m24__item ul {
      width: 100%; }
      .m24__item ul li {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000;
        position: relative;
        padding-left: 14px; }
        .m24__item ul li:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: #2a6ebb;
          border-radius: 100%;
          position: absolute;
          top: 8px;
          left: 0; }
    .m24__item__bottom {
      width: 100%; }
      .m24__item__bottom h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42857em;
        color: #505050;
        margin-bottom: 8px; }
    .m24__item__box {
      width: 100%;
      display: flex;
      border-top: 1px solid #0d1e5a;
      padding-top: 16px; }
      .m24__item__box__image {
        width: 100px;
        padding-right: 16px; }
        @media only screen and (max-width: 767px) {
          .m24__item__box__image {
            height: 40px;
            width: auto; } }
        .m24__item__box__image img {
          float: right;
          width: auto;
          object-fit: contain; }
          @media only screen and (max-width: 767px) {
            .m24__item__box__image img {
              max-height: 100%; } }
      .m24__item__box__content {
        width: calc(100% - 100px); }
        @media only screen and (max-width: 767px) {
          .m24__item__box__content {
            width: calc(100% - 40px); } }
        .m24__item__box__content h4 {
          font-size: 22px;
          font-weight: 500;
          line-height: 1.45455em;
          color: #1b1b1b;
          margin-bottom: 8px; }
  .m24__right {
    width: calc(100% - 670px); }
    @media only screen and (max-width: 767px) {
      .m24__right {
        width: 100%;
        margin-left: -100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m24__right {
        width: 100%;
        margin-left: -100%; } }
    .m24__right ul {
      float: right;
      width: 280px;
      padding: 32px 24px 40px 24px;
      background-color: #f1f1f1;
      position: -webkit-sticky;
      position: sticky;
      top: 130px; }
      @media only screen and (max-width: 767px) {
        .m24__right ul {
          width: calc(100% + 30px);
          margin-right: -16px;
          display: flex;
          padding: 0px 16px;
          overflow-x: scroll;
          z-index: 4;
          top: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m24__right ul {
          width: 100vw;
          margin-right: -42px;
          display: flex;
          padding: 0px 42px;
          overflow-x: scroll;
          z-index: 4;
          top: 0; } }
      .m24__right ul:after {
        content: '';
        background-color: #f1f1f1;
        height: 100%;
        width: 100vw !important;
        position: absolute;
        left: 100%;
        max-width: none;
        z-index: 3;
        top: 0; }
        @media only screen and (max-width: 767px) {
          .m24__right ul:after {
            display: none; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m24__right ul:after {
            display: none; } }
      .m24__right ul li {
        width: 100%;
        display: flex;
        position: relative;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33333em;
        color: #2a6ebb; }
        @media only screen and (max-width: 767px) {
          .m24__right ul li {
            margin-bottom: 0;
            padding: 30px 0 37px 0;
            margin-right: 48px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m24__right ul li {
            margin-bottom: 0;
            padding: 30px 0 37px 0;
            margin-right: 48px; } }
        .m24__right ul li:last-child {
          margin-bottom: 0; }
        .m24__right ul li:hover {
          color: #0d1e5a; }
        .m24__right ul li:before {
          content: '';
          width: 8px;
          height: 40px;
          background-color: #ffca00;
          position: absolute;
          left: -27px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          @media only screen and (max-width: 767px) {
            .m24__right ul li:before {
              height: 8px;
              width: 100%;
              top: unset;
              bottom: -4px;
              left: 0; } }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m24__right ul li:before {
              height: 8px;
              width: 100%;
              top: unset;
              bottom: -4px;
              left: 0; } }
        .m24__right ul li.is--active {
          color: #000000; }
          .m24__right ul li.is--active:before {
            opacity: 1; }
        .m24__right ul li a {
          color: inherit;
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          @media only screen and (max-width: 767px) {
            .m24__right ul li a {
              white-space: nowrap; } }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m24__right ul li a {
              white-space: nowrap; } }

.m25 {
  width: 100%;
  background-color: #f1f1f1;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m25 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m25 {
      padding: 120px 0; } }
  .m25__content {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .m25__content {
        margin-bottom: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m25__content {
        margin-bottom: 50px; } }
    .m25__content h3 {
      font-family: 'Noah Head';
      font-size: 26px;
      line-height: 1.46154em;
      font-weight: 500;
      color: #000;
      margin-bottom: 8px; }
    .m25__content p {
      font-size: 16px;
      line-height: 1.5em;
      font-weight: 400;
      color: #000;
      padding-right: 220px; }
      @media only screen and (max-width: 767px) {
        .m25__content p {
          padding-right: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m25__content p {
          padding-right: 156px; } }
  .m25__list {
    width: 100%; }
    .m25__list li {
      width: 100%;
      margin-bottom: 24px; }
      .m25__list li:last-child {
        margin-bottom: 0; }
      .m25__list li a {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500;
        color: #2a6ebb; }
        .m25__list li a span {
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          @media only screen and (max-width: 767px) {
            .m25__list li a span {
              padding-right: 90px; } }
        .m25__list li a:hover span {
          text-decoration: underline; }
      .m25__list li p {
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500;
        color: #000;
        margin-top: 5px; }
    .m25__list img {
      width: 28px;
      object-fit: contain;
      margin-right: 16px;
      margin-top: 6px; }
    .m25__list span:nth-of-type(2) {
      margin-left: 10px;
      margin-right: 10px; }

.m26 {
  width: 100%;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m26 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m26 {
      padding: 120px 0; } }
  @media only screen and (min-width: 1200px) {
    .m26 .column-6:nth-child(2n + 1) {
      clear: left; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m26 .column-6:nth-child(2n + 1) {
      clear: left; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m26 .column-6:nth-child(2n + 1) {
      clear: left; } }
  @media only screen and (min-width: 1200px) {
    .m26 .column-mob-12:nth-last-of-type(-n + 2) .m26_team {
      margin-bottom: 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m26 .column-mob-12:nth-last-of-type(-n + 2) .m26_team {
      margin-bottom: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m26 .column-mob-12:nth-last-of-type(-n + 2) .m26_team {
      margin-bottom: 0; } }
  .m26 .column-mob-12:last-of-type .m26_team {
    margin-bottom: 0; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m26 .column-mob-12:last-of-type .m26_team {
        margin-bottom: 0; } }
  .m26_team {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 114px; }
    @media only screen and (max-width: 767px) {
      .m26_team {
        margin-bottom: 100px;
        align-items: flex-start; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m26_team {
        margin-bottom: 80px;
        align-items: flex-start;
        flex-direction: column; } }
    .m26_team__image {
      width: 240px;
      height: 312px; }
      @media only screen and (max-width: 767px) {
        .m26_team__image {
          width: 160px;
          height: 210px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m26_team__image {
          width: 190px;
          height: 250px;
          margin-bottom: 16px; } }
    .m26_team__content {
      width: calc(100% - 240px);
      padding-left: 24px; }
      @media only screen and (max-width: 767px) {
        .m26_team__content {
          width: calc(100% - 160px);
          padding-left: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m26_team__content {
          width: 100%;
          padding-left: 0; } }
      .m26_team__content h3 {
        font-size: 18px;
        font-weight: 800;
        line-height: 1.33333em;
        letter-spacing: 0.05px;
        color: #000; }
      .m26_team__content p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42857em;
        color: #505050;
        margin-top: 8px; }
      .m26_team__content ul {
        width: 100%;
        margin-top: 30px; }
        @media only screen and (max-width: 767px) {
          .m26_team__content ul {
            margin-top: 18px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m26_team__content ul {
            margin-top: 20px; } }
        .m26_team__content ul li {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.5em;
          letter-spacing: 0.01px;
          color: #000;
          margin-bottom: 3px; }
          .m26_team__content ul li b {
            font-weight: 800; }
          .m26_team__content ul li a {
            color: #2a6ebb;
            transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
            .m26_team__content ul li a:hover {
              color: #2a6ebb; }
      .m26_team__content .text-link {
        margin-top: 30px; }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m26_team__content .text-link {
            margin-top: 12px; } }

.m27 {
  width: 100%; }
  .m27_banner {
    width: 100%;
    height: 470px;
    background-size: cover;
    background-position: center;
    background-color: #2a6ebb;
    display: flex;
    align-items: flex-end;
    padding-bottom: 58px; }
    @media only screen and (max-width: 767px) {
      .m27_banner {
        height: 400px;
        padding-bottom: 134px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m27_banner {
        height: 470px;
        padding-bottom: 142px; } }
    .m27_banner__inner {
      width: 100%;
      max-width: 474px; }
    .m27_banner span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33333em;
      line-height: 0.15px;
      color: #fff; }
    .m27_banner h1 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 32px;
      line-height: 1.3125em;
      color: #fff;
      margin-top: 10px; }
      @media only screen and (max-width: 767px) {
        .m27_banner h1 {
          font-size: 26px;
          line-height: 1.46154em; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m27_banner h1 {
          font-size: 26px;
          line-height: 1.46154em; } }
    .m27_banner p {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.66667em;
      letter-spacing: 0.05px;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        .m27_banner p {
          font-size: 22px;
          line-height: 1.45455em; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m27_banner p {
          line-height: 1.58333em; } }
  .m27_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 767px) {
      .m27_bottom {
        flex-direction: column-reverse; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m27_bottom {
        flex-direction: column-reverse; } }
  .m27_breadcrumb ul {
    display: flex;
    margin-top: 35px; }
    @media only screen and (max-width: 767px) {
      .m27_breadcrumb ul {
        margin-bottom: 60px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m27_breadcrumb ul {
        margin-bottom: 60px; } }
  .m27_breadcrumb li {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.01px;
    padding-right: 17px;
    background-image: url("./images/icon-arrow-right.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 7px 12px;
    margin-right: 8px; }
    .m27_breadcrumb li:last-child {
      background-image: none;
      margin-right: 0;
      padding-right: 0; }
    .m27_breadcrumb li a {
      color: #2a6ebb;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m27_breadcrumb li a:hover {
        color: #0d1e5a; }
  .m27_box {
    width: 280px;
    margin-top: -180px; }
    @media only screen and (max-width: 767px) {
      .m27_box {
        width: 256px;
        margin: 0 auto;
        margin-top: -65px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m27_box {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: -100px; } }
    .m27_box__thumb {
      width: 100%;
      height: 360px; }
      @media only screen and (max-width: 767px) {
        .m27_box__thumb {
          height: 330px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m27_box__thumb {
          width: 192px;
          height: 246px; } }
    .m27_box__content {
      width: 100%;
      padding: 32px 24px;
      background-color: #f1f1f1; }
      @media only screen and (max-width: 767px) {
        .m27_box__content {
          padding: 24px 16px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m27_box__content {
          padding: 26px ​41px 20px 24px; } }
      .m27_box__content h4 {
        font-size: 18px;
        line-height: 1.33333em;
        font-weight: 500;
        color: #000;
        margin-bottom: 6px; }
      .m27_box__content h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000; }
      .m27_box__content ul {
        width: 100%;
        margin-bottom: 8px; }
        .m27_box__content ul li {
          font-size: 16px;
          line-height: 1.5em;
          font-weight: 500;
          color: #000;
          position: relative;
          padding-left: 14px; }
          .m27_box__content ul li:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #2a6ebb;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0; }

.m28 {
  width: 100%;
  padding-bottom: 150px;
  margin-top: -250px; }
  @media only screen and (max-width: 767px) {
    .m28 {
      padding-bottom: 100px;
      margin-top: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m28 {
      padding-bottom: 120px;
      margin-top: 0; } }
  .m28_content {
    width: 100%;
    max-width: 770px;
    padding-left: 100px;
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .m28_content {
        padding-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m28_content {
        padding-left: 0; } }
  .m28_block_wrapper {
    width: 100%;
    margin-bottom: 60px; }
    .m28_block_wrapper h3 {
      font-size: 22px;
      line-height: 1.45455em;
      letter-spacing: 0.05px;
      font-weight: 500;
      color: #000;
      padding-left: 99px; }
      @media only screen and (max-width: 767px) {
        .m28_block_wrapper h3 {
          padding-left: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m28_block_wrapper h3 {
          padding-left: 0; } }
  .m28_block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 24px; }
    @media only screen and (max-width: 767px) {
      .m28_block {
        margin-top: 16px;
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m28_block {
        margin-top: 16px; } }
    .m28_block__left {
      width: 100px; }
      @media only screen and (max-width: 767px) {
        .m28_block__left {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m28_block__left {
          width: 100%; } }
      .m28_block__left span {
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500;
        color: #000; }
    .m28_block__right {
      width: calc(100% - 100px); }
      @media only screen and (max-width: 767px) {
        .m28_block__right {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m28_block__right {
          width: 100%; } }
      .m28_block__right p {
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500;
        color: #000; }
  .m28_arrow {
    width: 36px;
    height: 36px;
    border: 1px solid #2a6ebb;
    border-radius: 36px;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center; }
  .m28_windows {
    width: 25px;
    height: 25px;
    display: flex;
    background: url("./images/icon-windows.svg") no-repeat center center; }
  .m28_footer {
    width: 100%;
    border-top: 2px solid #dcdcdc;
    padding-top: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px; }
    .m28_footer h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      letter-spacing: 0.3px;
      color: #2a6ebb;
      margin-bottom: 4px; }
    .m28_footer p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42857em;
      color: #505050;
      max-width: 200px; }
    .m28_footer__left {
      width: 40%; }
      @media only screen and (max-width: 767px) {
        .m28_footer__left {
          width: 48px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m28_footer__left {
          width: 48px; } }
      .m28_footer__left .m28_arrow {
        transform: rotate(180deg);
        margin-right: 12px; }
      .m28_footer__left a {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 767px) {
          .m28_footer__left a div {
            display: none; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m28_footer__left a div {
            display: none; } }
    .m28_footer__center a {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .m28_footer__center h4 {
      text-transform: uppercase; }
    .m28_footer__right {
      width: 40%; }
      @media only screen and (max-width: 767px) {
        .m28_footer__right {
          width: 48px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m28_footer__right {
          width: 48px; } }
      .m28_footer__right .m28_arrow {
        margin-left: 12px; }
      .m28_footer__right a {
        display: flex;
        justify-content: flex-end;
        text-align: right; }
        @media only screen and (max-width: 767px) {
          .m28_footer__right a div {
            display: none; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m28_footer__right a div {
            display: none; } }

.m29 {
  width: 100%;
  background-color: #f5f5f5;
  padding: 150px 0;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .m29 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m29 {
      padding: 120px 0; } }
  .m29 .container {
    position: static; }
  .m29 .posts-holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m29 .posts-holder {
        grid-template-columns: 1fr 1fr; } }
    @media only screen and (max-width: 767px) {
      .m29 .posts-holder {
        grid-template-columns: 1fr; } }
  .m29_wrapper {
    width: 100%;
    margin-top: 50px; }
    @media only screen and (max-width: 767px) {
      .m29_wrapper {
        margin-top: 40px; } }
    .m29_wrapper .filters {
      margin-bottom: 22px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m29_wrapper .row {
        display: flex;
        flex-wrap: wrap; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m29_wrapper .row {
        display: flex;
        flex-wrap: wrap; } }
    @media only screen and (min-width: 1200px) {
      .m29_wrapper .column-4:nth-child(3n + 1) {
        clear: left; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m29_wrapper .column-tab-6:nth-child(2n + 1) {
        clear: left; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m29_wrapper .column-tab-6:nth-child(3n + 1) {
        clear: left; } }
  .m29_box {
    width: 100%;
    margin-bottom: 32px;
    height: calc(100% - 32px); }
    @media only screen and (max-width: 767px) {
      .m29_box {
        margin-bottom: 24px;
        height: calc(100% - 24px); } }
    .m29_box a {
      width: 100%;
      height: 100%;
      min-height: 252px;
      display: block;
      background-color: #fff;
      border-radius: 8px;
      padding: 32px 24px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m29_box a:hover {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3); }
      @media only screen and (max-width: 340px) {
        .m29_box a {
          padding: 32px 18px; } }
    .m29_box span {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5em;
      color: #959595;
      letter-spacing: -0.21px;
      margin-right: 13px; }
      .m29_box span:last-child {
        padding-left: 40px;
        border-left: 1px solid #959595;
        color: #2a6ebb;
        background: url("./images/icon-category.svg") no-repeat 15px center;
        background-size: 14px; }
    .m29_box h4 {
      font-weight: 800;
      font-size: 22px;
      line-height: 1.45455em;
      letter-spacing: 0.05px;
      color: #000;
      margin-top: 30px;
      margin-bottom: 0; }
    .m29_box p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5em;
      color: #000;
      margin-top: 9px;
      margin-bottom: 0; }
  .m29 .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px; }
    .m29 .btn_link_with_arrow:hover:before {
      margin-right: 12px; }
    .m29 .btn_link_with_arrow:before {
      transform: rotate(90deg); }

.m30 {
  width: 100%;
  padding: 150px 0;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .m30 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m30 {
      padding: 120px 0; } }
  .m30 .container {
    position: static; }
  .m30_wrapper {
    width: 100%;
    margin-top: 50px; }
    @media only screen and (max-width: 767px) {
      .m30_wrapper {
        margin-top: 40px; } }
    .m30_wrapper .filters {
      margin-bottom: 22px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m30_wrapper .column-tab-6:nth-child(2n + 1) {
        clear: left; } }
  .m30_disabled {
    width: 100%;
    max-width: 770px;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.66667em;
    letter-spacing: 0.05px;
    color: #000;
    margin-top: -30px; }
    @media only screen and (max-width: 767px) {
      .m30_disabled {
        font-size: 20px;
        line-height: 1.6em;
        margin-top: -20px; } }
  .m30_box {
    width: 100%;
    display: flex;
    padding-top: 50px;
    margin-bottom: 50px;
    border-top: 1px solid #dcdcdc; }
    @media only screen and (max-width: 767px) {
      .m30_box {
        padding-top: 30px;
        margin-bottom: 20px;
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m30_box {
        padding-top: 40px;
        margin-bottom: 40px; } }
    .m30_box__left {
      width: 280px;
      display: flex;
      flex-direction: column; }
      @media only screen and (max-width: 767px) {
        .m30_box__left {
          width: 100%;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m30_box__left {
          width: 190px; } }
      .m30_box__left span:first-child {
        font-family: 'Noah Head';
        font-size: 40px;
        line-height: 1.35em;
        font-weight: 800;
        letter-spacing: -0.1px;
        color: #2a6ebb;
        margin-bottom: 8px; }
        @media only screen and (max-width: 767px) {
          .m30_box__left span:first-child {
            font-size: 30px;
            line-height: 1.1em;
            margin-bottom: 5px; } }
      .m30_box__left span:last-child {
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500;
        letter-spacing: -0.1px;
        color: #000; }
        @media only screen and (max-width: 767px) {
          .m30_box__left span:last-child {
            letter-spacing: -0.02px; } }
    .m30_box__right {
      width: calc(100% - 280px);
      min-height: 210px;
      padding-left: 16px;
      padding-right: 190px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media only screen and (max-width: 767px) {
        .m30_box__right {
          width: 100%;
          min-height: 320px;
          padding-left: 0;
          padding-right: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m30_box__right {
          width: calc(100% - 190px);
          padding-right: 0; } }
      .m30_box__right h2 {
        font-family: 'Noah Head';
        font-size: 26px;
        line-height: 1.46154em;
        font-weight: 500;
        color: #000;
        margin-bottom: 25px; }
        @media only screen and (max-width: 767px) {
          .m30_box__right h2 {
            font-size: 24px;
            line-height: 1.41667em;
            margin-bottom: 15px; } }
      .m30_box__right p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5em;
        color: #000;
        margin-bottom: 20px; }
        @media only screen and (max-width: 767px) {
          .m30_box__right p {
            margin-bottom: 20px; } }
    .m30_box__bottom {
      display: flex; }
      @media only screen and (max-width: 767px) {
        .m30_box__bottom {
          flex-wrap: wrap; } }
      .m30_box__bottom span {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        padding-right: 16px;
        border-right: 1px solid #959595;
        margin-left: 16px; }
        @media only screen and (max-width: 767px) {
          .m30_box__bottom span {
            margin-bottom: 10px; } }
        .m30_box__bottom span:first-child {
          margin-left: 0; }
          @media only screen and (max-width: 767px) {
            .m30_box__bottom span:first-child {
              width: 100%;
              border-right: 0;
              padding-right: 0; } }
        @media only screen and (max-width: 767px) {
          .m30_box__bottom span:nth-child(2n) {
            margin-left: 0; } }
        .m30_box__bottom span:last-child {
          border-right: 0;
          padding-right: 0; }
  .m30 .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px; }
    .m30 .btn_link_with_arrow:hover:before {
      margin-right: 12px; }
    .m30 .btn_link_with_arrow:before {
      transform: rotate(90deg); }

.m31 {
  margin: 130px auto; }
  @media only screen and (max-width: 767px) {
    .m31 {
      margin: 100px auto; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m31 {
      margin: 120px auto; } }
  .m31-form-wrapper {
    display: inline-block;
    padding: 70px 90px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 767px) {
      .m31-form-wrapper {
        padding: 100px 16px; } }
  .m31-title {
    margin-bottom: 35px;
    font-family: 'Noah Head';
    font-weight: bold;
    font-size: 26px;
    line-height: 1.46154em; }
  .m31-left {
    padding-right: 30px; }
    @media only screen and (max-width: 767px) {
      .m31-left {
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m31-left {
        padding-right: 0; } }
    .m31-left p {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5em; }
  .m31-right {
    padding-left: 30px; }
    @media only screen and (max-width: 767px) {
      .m31-right {
        padding-left: 0;
        margin-top: 60px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m31-right {
        padding-left: 0;
        margin-top: 60px; } }
  .m31 .gfield_label {
    display: none; }
  .m31 .gfield,
  .m31 .form-group {
    margin-bottom: 30px; }
    .m31 .gfield:last-child,
    .m31 .form-group:last-child {
      margin-bottom: 24px; }
  .m31 .ginput_container .gfield input,
  .m31 .form-input {
    color: #0d1e5a !important; }
  .m31 .gform_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .m31 .validation_error {
    display: none; }
  .m31 .gform_confirmation_message {
    display: none; }
  .m31-button-reset {
    cursor: pointer; }
  .m31-alert {
    color: #0d1e5a;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 1.5em; }
  .m31-button,
  .m31 .gform_button {
    margin-left: 24px;
    float: left;
    padding: 8px 20px 10px 15px;
    border: 1px solid #fff;
    border-radius: 22px;
    background-color: #2a6ebb;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    display: block; }
    .m31-button--outlined,
    .m31 .gform_button--outlined {
      margin-left: 0;
      margin-top: 30px;
      border-color: #2a6ebb;
      background-color: transparent;
      color: #2a6ebb; }
      .m31-button--outlined:before,
      .m31 .gform_button--outlined:before {
        background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }
      .m31-button--outlined:hover,
      .m31 .gform_button--outlined:hover {
        color: #2a6ebb;
        background: transparent; }
        .m31-button--outlined:hover:before,
        .m31 .gform_button--outlined:hover:before {
          margin-right: 11px;
          margin-left: 5px;
          background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }
    .m31-button:before,
    .m31 .gform_button:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url("./images/icon-arrow-right-white.svg") no-repeat center;
      margin-right: 16px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m31-button:hover,
    .m31 .gform_button:hover {
      background-color: #2a6ebb;
      color: #fff; }
      .m31-button:hover:before,
      .m31 .gform_button:hover:before {
        margin-right: 11px;
        margin-left: 5px;
        background: url("./images/icon-arrow-right-white.svg") no-repeat center; }

.m32 {
  overflow: hidden;
  padding: 150px 0; }
  .m32-text {
    font-size: 18px;
    letter-spacing: 0.05;
    line-height: 1.33333em;
    font-weight: bold; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m32 .container {
      margin-left: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .m32 .container {
      margin-left: 0 !important; } }
  .m32-slider {
    max-width: 1440px;
    width: 100vw;
    margin-top: 23px; }
    .m32-slider .slick-slide {
      margin-right: 50px; }

.m33 {
  width: 100%;
  padding: 150px 0;
  background-color: #f1f1f1; }
  @media only screen and (max-width: 767px) {
    .m33 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m33 {
      padding: 120px 0; } }
  .m33_top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px; }
    @media only screen and (max-width: 767px) {
      .m33_top {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m33_top {
        flex-direction: column; } }
    .m33_top__left {
      width: 710px; }
      @media only screen and (max-width: 767px) {
        .m33_top__left {
          width: 100%;
          margin-bottom: 24px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m33_top__left {
          width: 100%;
          margin-bottom: 24px; } }
    .m33_top h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.33333em;
      letter-spacing: 0.05px;
      color: #000;
      margin-bottom: 10px; }
    .m33_top p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.33333em;
      color: #000; }
  .m33_quote {
    width: 100%;
    padding-left: 100px; }
    @media only screen and (max-width: 767px) {
      .m33_quote {
        padding-left: 54px; } }
    .m33_quote__text {
      position: relative;
      max-width: 670px;
      text-align: justify; }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m33_quote__text {
          max-width: 400px; } }
      .m33_quote__text:after {
        content: '';
        width: 37px;
        height: 20px;
        display: inline-block;
        background: url("./images/quotationsmarks.svg") no-repeat center;
        background-size: cover;
        position: absolute;
        top: 10px;
        left: -55px; }
      .m33_quote__text p {
        max-width: 100%;
        font-size: 22px;
        font-weight: normal;
        font-style: italic;
        line-height: 1.63636em;
        padding: 0; }
        @media only screen and (max-width: 767px) {
          .m33_quote__text p {
            font-size: 20px;
            line-height: 1.4em; } }
  .m33_author {
    max-width: 320px;
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center; }
    .m33_author__info {
      width: calc(100% - 120px);
      padding-right: 40px; }
      .m33_author__info p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
        color: #000; }
        .m33_author__info p:last-child {
          margin-bottom: 0; }
    .m33_author__avatar {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden; }

.m34 {
  width: 100%;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m34 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m34 {
      padding: 120px 0; } }
  .m34 .main_title p {
    font-size: 25px;
    line-height: 1.52em;
    color: #000;
    font-weight: 500;
    margin-top: 64px; }
    @media only screen and (max-width: 767px) {
      .m34 .main_title p {
        font-size: 24px;
        line-height: 1.41667em;
        margin-top: 55px; } }
  .m34_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px; }
    @media only screen and (max-width: 767px) {
      .m34_wrapper {
        flex-direction: column;
        margin-top: 24px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m34_wrapper {
        flex-direction: column; } }
  .m34_left {
    width: 265px;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 500;
    color: #000; }
    @media only screen and (max-width: 767px) {
      .m34_left {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m34_left {
        width: 500px; } }
    .m34_left p {
      margin-bottom: 16px; }
      .m34_left p:first-child {
        margin-bottom: 40px; }
      .m34_left p:last-child {
        margin-bottom: 0; }
    .m34_left a {
      display: inline-block;
      color: #2a6ebb;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m34_left a:hover {
        color: #0d1e5a; }
  .m34_right {
    width: calc(100% - 265px);
    padding-left: 115px; }
    @media only screen and (max-width: 767px) {
      .m34_right {
        width: 100%;
        padding-left: 0;
        margin-top: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m34_right {
        width: 100%;
        padding-left: 0;
        margin-top: 50px; } }
    @media only screen and (max-width: 767px) {
      .m34_right .gform_body {
        margin-top: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m34_right .gform_body {
        margin-top: 0; } }
    .m34_right .gform_wrapper {
      margin-bottom: 0; }
    .m34_right .gform_heading {
      display: none; }
    .m34_right .gfield_label {
      display: none; }
    .m34_right .validation_error {
      display: none; }
    .m34_right .ginput_container .validation_message {
      display: none; }
    .m34_right .gform_ajax_spinner {
      display: none; }
    .m34_right .validation_message {
      padding: 0 8px;
      margin-top: 2px; }
      @media only screen and (max-width: 767px) {
        .m34_right .validation_message {
          padding: 0; } }
    .m34_right .gfield {
      padding: 0 8px;
      margin-bottom: 29px; }
      @media only screen and (max-width: 767px) {
        .m34_right .gfield {
          padding: 0; } }
    .m34_right .gfield {
      float: left;
      width: 50%;
      display: inline-block;
      clear: left; }
      @media only screen and (max-width: 767px) {
        .m34_right .gfield {
          width: 100%;
          padding-right: 0 !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m34_right .gfield {
          width: 100%;
          padding-right: 0 !important; } }
    .m34_right li {
      margin-top: 0 !important; }
      .m34_right li:last-child {
        float: none;
        width: 50%;
        display: inline-block;
        padding-right: 0 !important; }
        @media only screen and (max-width: 767px) {
          .m34_right li:last-child {
            width: 100%; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m34_right li:last-child {
            width: 100%; } }
      .m34_right li .gfield_required {
        display: none; }
      .m34_right li input {
        border: 0;
        outline: none;
        width: 100% !important;
        border-bottom: 1px solid #959595;
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        padding: 0 !important;
        padding-bottom: 2px !important; }
        .m34_right li input::-webkit-input-placeholder {
          color: #959595;
          font-size: 16px;
          font-weight: 500; }
        .m34_right li input:-moz-placeholder {
          color: #959595;
          font-size: 16px;
          font-weight: 500; }
        .m34_right li input::-moz-placeholder {
          color: #959595;
          font-size: 16px;
          font-weight: 500; }
        .m34_right li input:-ms-input-placeholder {
          color: #959595;
          font-size: 16px;
          font-weight: 500; }
      .m34_right li input[type="checkbox"] {
        width: initial !important; }
      .m34_right li select {
        border: 0;
        outline: none;
        width: 100% !important;
        border-bottom: 1px solid #959595;
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        padding: 0 !important;
        padding-bottom: 6px !important; }
        .m34_right li select option {
          color: #959595;
          font-size: 16px;
          font-weight: 500;
          padding: 0 !important;
          margin-left: 0 !important; }
      .m34_right li textarea {
        border: 0;
        outline: none;
        width: 100%;
        height: 194px !important;
        font-size: 16px;
        font-weight: 500;
        color: #959595;
        border-bottom: 1px solid #959595;
        padding: 0; }
        .m34_right li textarea::-webkit-input-placeholder {
          font-weight: 500;
          font-size: 16px;
          color: #959595; }
        .m34_right li textarea:-moz-placeholder {
          font-weight: 500;
          font-size: 16px;
          color: #959595; }
        .m34_right li textarea::-moz-placeholder {
          font-weight: 500;
          font-size: 16px;
          color: #959595; }
        .m34_right li textarea:-ms-input-placeholder {
          font-weight: 500;
          font-size: 16px;
          color: #959595; }
        @media only screen and (max-width: 767px) {
          .m34_right li textarea {
            height: 120px !important; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m34_right li textarea {
            height: 120px !important; } }
    .m34_right .gform_footer {
      display: flex !important;
      justify-content: flex-end !important;
      padding: 0 !important;
      margin: 0 !important; }
    .m34_right .gform_button {
      margin-top: 0 !important;
      float: right;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 9px;
      padding-left: 43px;
      border: 1px solid #2a6ebb;
      border-radius: 22px;
      background: url("./images/icon-arrow-right-white.svg") no-repeat 15px center;
      background-size: 14px;
      background-color: #2a6ebb;
      font-weight: 800;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.3px;
      color: #fff;
      text-transform: uppercase;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
      outline: none;
      margin-right: 0 !important;
      cursor: pointer; }
      .m34_right .gform_button:hover {
        background-color: #fff;
        color: #2a6ebb;
        background-image: url("./images/icon-arrow-right-blue.svg"); }
      @media only screen and (max-width: 767px) {
        .m34_right .gform_button {
          width: auto !important;
          margin-bottom: 0 !important;
          line-height: 1.5em !important;
          border-radius: 23px !important; } }
  .m34 .m31-button-reset {
    display: flex;
    align-items: center;
    margin-right: 24px;
    display: none; }

.m35 {
  width: 100%;
  padding: 150px 0;
  background-color: #f1f1f1; }
  @media only screen and (max-width: 767px) {
    .m35 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m35 {
      padding: 120px 0; } }
  @media only screen and (min-width: 1200px) {
    .m35 .row {
      display: flex;
      align-items: flex-end; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m35 .row {
      display: flex;
      align-items: flex-end; } }
  .m35_content {
    width: 100%;
    padding-right: 110px; }
    @media only screen and (max-width: 767px) {
      .m35_content {
        padding-right: 0; } }
    @media only screen and (max-width: 767px) {
      .m35_content__left {
        margin-bottom: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m35_content__left {
        margin-bottom: 60px; } }
  .m35 h3 {
    font-family: 'Noah Head';
    font-size: 26px;
    font-weight: 500;
    line-height: 1.46154em;
    color: #000;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .m35 h3 {
        font-size: 24px;
        line-height: 1.41667em;
        margin-bottom: 15px; } }
    .m35 h3.heading {
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-bottom: 24px; }
      @media only screen and (max-width: 767px) {
        .m35 h3.heading {
          font-size: 30px;
          line-height: 1.4em; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m35 h3.heading {
          font-size: 27px;
          line-height: 1.55556em; } }
  .m35 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #000;
    margin-bottom: 25px;
    padding-right: 100px; }
    @media only screen and (max-width: 767px) {
      .m35 p {
        padding-right: 0; } }
  .m35_social {
    width: 100%;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m35_social {
        flex-direction: column; } }
    .m35_social li {
      margin-right: 50px; }
      @media only screen and (max-width: 767px) {
        .m35_social li {
          margin-right: 0;
          margin-top: 7px; } }
    .m35_social a {
      font-size: 20px;
      line-height: 1.7em;
      font-weight: 500;
      color: #2a6ebb;
      display: flex;
      align-items: center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m35_social a:hover {
        color: #0d1e5a; }
    .m35_social img {
      width: 14px;
      max-height: 14px;
      object-fit: contain;
      margin-right: 14px; }

.m36 {
  width: 100%; }
  .m36 .page_banner__thumb {
    width: 100%;
    height: 470px;
    background-size: cover;
    background-position: center;
    background-color: #2a6ebb; }
    @media only screen and (max-width: 767px) {
      .m36 .page_banner__thumb {
        height: 230px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m36 .page_banner__thumb {
        height: 320px; } }
  .m36 .page_banner__bottom {
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 767px) {
      .m36 .page_banner__bottom {
        flex-direction: column-reverse; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m36 .page_banner__bottom {
        flex-direction: column-reverse; } }
  .m36 .page_banner__box {
    width: 100%;
    max-width: 512px;
    display: block;
    margin-left: auto;
    margin-right: 0;
    background-color: #2a6ebb;
    padding: 40px 52px 32px 40px;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .m36 .page_banner__box {
        max-width: 100%;
        padding: 50px 18px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m36 .page_banner__box {
        max-width: 100%;
        padding: 50px 76px; } }
    .m36 .page_banner__box h5 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33333em;
      letter-spacing: 1.15px;
      margin-bottom: 15px; }
    .m36 .page_banner__box h3 {
      font-weight: normal;
      font-size: 22px;
      line-height: 1.54545em;
      letter-spacing: 0.05px;
      margin-bottom: 7px; }
      @media only screen and (max-width: 767px) {
        .m36 .page_banner__box h3 {
          font-size: 20px;
          line-height: 1.4em;
          margin-bottom: 15px; } }
    .m36 .page_banner__box h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42857em;
      margin-bottom: 0; }
  .m36 .page_banner__left {
    width: 380px; }
    @media only screen and (max-width: 767px) {
      .m36 .page_banner__left {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m36 .page_banner__left {
        width: 100%; } }
    .m36 .page_banner__left h1 {
      hyphens: auto; }
  .m36 .page_banner__right {
    width: calc(100% - 380px);
    padding-left: 16px;
    padding-right: 170px; }
    @media only screen and (max-width: 767px) {
      .m36 .page_banner__right {
        width: 100%;
        padding-left: 0;
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m36 .page_banner__right {
        width: 100%;
        padding-left: 0;
        padding-right: 0; } }
  .m36 .page_banner__content {
    margin-top: 146px;
    margin-bottom: 0;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m36 .page_banner__content {
        flex-direction: column;
        margin-top: 70px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m36 .page_banner__content {
        flex-direction: column;
        margin-top: 120px; } }
    .m36 .page_banner__content h1 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 48px;
      line-height: 1.29167em;
      letter-spacing: -0.1px;
      color: #1b1b1b;
      margin-bottom: 10px;
      overflow-wrap: break-word; }
      @media only screen and (max-width: 767px) {
        .m36 .page_banner__content h1 {
          font-size: 36px;
          line-height: 1.05556em;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m36 .page_banner__content h1 {
          font-size: 40px;
          line-height: 1.55em;
          margin-bottom: 20px; } }
    .m36 .page_banner__content a {
      font-weight: 600;
      color: #2a6ebb;
      position: relative; }
      .m36 .page_banner__content a:before {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #2a6ebb;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m36 .page_banner__content a:hover:before {
        width: 100%; }
    .m36 .page_banner__content .excerpt p {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.66667em;
      letter-spacing: 0.05px;
      color: #000;
      margin-top: 0; }
      @media only screen and (max-width: 767px) {
        .m36 .page_banner__content .excerpt p {
          font-size: 22px;
          line-height: 1.45455em; } }
    .m36 .page_banner__content p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5em;
      color: #000000;
      margin-top: 25px; }

.m37 {
  width: 100%;
  padding-top: 30px; }
  @media only screen and (max-width: 767px) {
    .m37 {
      padding-top: 0; } }
  .m37_item {
    width: 100%;
    padding: 150px 0;
    border-bottom: 1px solid #959595; }
    .m37_item:first-child {
      padding-top: 0; }
    .m37_item:last-child {
      border-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .m37_item {
        padding: 80px 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m37_item {
        padding: 120px 0; } }
    .m37_item h3 {
      font-family: 'Noah Head';
      font-size: 40px;
      line-height: 1.35em;
      font-weight: 700;
      letter-spacing: -0.1px;
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .m37_item h3 {
          font-size: 30px;
          line-height: 1.4em;
          margin-bottom: 20px; } }
    .m37_item__bottom {
      width: 100%;
      display: flex; }
      @media only screen and (max-width: 767px) {
        .m37_item__bottom {
          flex-direction: column-reverse; } }
    .m37_item__content {
      width: 850px; }
      @media only screen and (max-width: 767px) {
        .m37_item__content {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m37_item__content {
          width: 460px; } }
      .m37_item__content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
        letter-spacing: 0.001em;
        color: #000;
        margin-bottom: 16px; }
      .m37_item__content a {
        font-size: inherit;
        font-weight: 500;
        color: #2a6ebb;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
        .m37_item__content a:hover {
          color: #0d1e5a; }
    .m37_item__excerpt p {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.66667em;
      color: #000;
      letter-spacing: 0.05px;
      margin-bottom: 16px; }
    .m37_item__image {
      width: calc(100% - 850px);
      padding-left: 35px; }
      @media only screen and (max-width: 767px) {
        .m37_item__image {
          width: 100%;
          padding-left: 0;
          margin-bottom: 20px;
          text-align: center; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m37_item__image {
          width: calc(100% - 460px);
          padding-left: 30px; } }
      .m37_item__image img {
        float: left; }
        @media only screen and (max-width: 767px) {
          .m37_item__image img {
            float: none; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m37_item__image img {
            float: right; } }
    .m37_item__buttons {
      display: inline-block; }
      @media only screen and (max-width: 767px) {
        .m37_item__buttons {
          margin-top: 15px; } }
      .m37_item__buttons .m37_button {
        margin-top: 35px;
        margin-right: 16px;
        float: left;
        padding: 8px 20px 10px 15px;
        border: 1px solid #fff;
        border-radius: 22px;
        background-color: #2a6ebb;
        font-weight: 800;
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.3px;
        color: #fff;
        text-transform: uppercase;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
        display: block; }
        @media only screen and (max-width: 767px) {
          .m37_item__buttons .m37_button {
            margin-top: 15px; } }
        .m37_item__buttons .m37_button:before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 12px;
          background: url("./images/icon-arrow-right-white.svg") no-repeat center;
          margin-right: 16px;
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
        .m37_item__buttons .m37_button:hover {
          background-color: #2a6ebb;
          color: #fff; }
          .m37_item__buttons .m37_button:hover:before {
            margin-right: 11px;
            margin-left: 5px;
            background: url("./images/icon-arrow-right-white.svg") no-repeat center; }
      .m37_item__buttons .m37_button--outlined {
        border-color: #2a6ebb;
        background-color: transparent;
        color: #2a6ebb; }
        .m37_item__buttons .m37_button--outlined:before {
          background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }
        .m37_item__buttons .m37_button--outlined:hover {
          color: #2a6ebb;
          background: transparent; }
          .m37_item__buttons .m37_button--outlined:hover:before {
            margin-right: 11px;
            margin-left: 5px;
            background: url("./images/icon-arrow-right-blue.svg") no-repeat center; }

.m38-banner {
  width: 100%;
  height: 470px;
  background-color: #2a6ebb;
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px; }
  .m38-banner h1 {
    font-family: 'Noah Head';
    max-width: 572px;
    font-size: 32px;
    font-weight: 800;
    line-height: 1.3125em;
    color: #fff; }

.m38-content {
  padding-top: 123px; }
  .m38-content__left-mobile {
    display: none !important;
    max-width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m38-content__left-mobile {
        display: block !important;
        padding: 0 !important; } }
    @media only screen and (max-width: 767px) {
      .m38-content__left-mobile {
        padding: 0 !important;
        display: block !important; } }
    @media only screen and (max-width: 767px) {
      .m38-content__left-mobile .m38-txt-blue.m38-left-external {
        display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m38-content__left {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .m38-content__left {
      display: none; } }
  .m38-content__left h1 {
    font-family: 'Noah Head';
    max-width: 572px;
    font-size: 38px;
    font-weight: 800;
    line-height: 1.42105em;
    letter-spacing: -0.1px;
    color: #2a6ebb; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m38-content__left h1 {
        font-size: 32px;
        line-height: 1.3125em; } }
    @media only screen and (max-width: 767px) {
      .m38-content__left h1 br {
        display: none; } }
  .m38-content__left img {
    position: relative;
    margin-right: 5px; }
  .m38-content__right {
    position: relative;
    max-width: 700px;
    margin-left: 100px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m38-content__right {
        margin-left: 0;
        padding: 0 !important; } }
    @media only screen and (max-width: 767px) {
      .m38-content__right {
        margin-left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .m38-left-external {
    display: none; } }

@media only screen and (max-width: 767px) {
  .m38-left-external {
    display: none; } }

.m38-link {
  display: flex;
  align-items: center; }

.m38-year {
  margin-top: 6px;
  margin-bottom: 30px; }

.m38-txt-blue {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.1px;
  color: #2a6ebb;
  display: flex;
  align-items: center; }

.m38-txt-grey {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5em;
  letter-spacing: -0.1px;
  color: #000;
  font-weight: 500; }

.m38-right-top {
  margin-bottom: 15px; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m38-right-top {
      display: flex;
      justify-content: space-between; } }
  @media only screen and (max-width: 767px) {
    .m38-right-top {
      display: flex;
      justify-content: space-between;
      flex-direction: column; } }
  .m38-right-top ul {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m38-right-top ul {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 231px; } }
    @media only screen and (max-width: 767px) {
      .m38-right-top ul {
        flex-wrap: wrap;
        justify-content: flex-start; }
        .m38-right-top ul .m38-page-external {
          width: 100%; } }
    .m38-right-top ul li::after {
      content: '';
      width: 1px;
      height: 14px;
      background: #d8d8d8;
      display: inline-block;
      margin-left: 30px; }
    .m38-right-top ul li:last-child::after, .m38-right-top ul li:nth-last-child(2)::after {
      display: none; }
    .m38-right-top ul li.m38-event-link-mobile {
      display: none; }
      .m38-right-top ul li.m38-event-link-mobile img {
        position: relative;
        bottom: -5px;
        margin-right: 3px; }
        @media only screen and (max-width: 767px) {
          .m38-right-top ul li.m38-event-link-mobile img {
            bottom: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m38-right-top ul li.m38-event-link-mobile {
          display: block;
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .m38-right-top ul li.m38-event-link-mobile {
          width: 100%;
          display: block; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m38-right-top ul li:first-child {
        width: 100%; }
        .m38-right-top ul li:first-child::after {
          display: none; }
      .m38-right-top ul li:nth-child(3) {
        margin-left: 20px; }
        .m38-right-top ul li:nth-child(3)::after {
          display: none; } }
    @media only screen and (max-width: 767px) {
      .m38-right-top ul li {
        margin-bottom: 10px; }
      .m38-right-top ul li:first-child {
        width: 100%; }
        .m38-right-top ul li:first-child::after {
          display: none; }
      .m38-right-top ul li:nth-child(3) {
        margin-left: 20px; }
        .m38-right-top ul li:nth-child(3)::after {
          display: none; }
      .m38-right-top ul li:nth-child(4) {
        width: 100%; } }

.m38-page-external img {
  margin-right: 4px; }

.m38-arrow {
  cursor: pointer;
  position: absolute;
  transform: rotate(93deg);
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 1px solid #2a6ebb;
  border-radius: 32px;
  bottom: 0px;
  z-index: 2;
  right: 0;
  top: unset;
  background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m38-arrow {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .m38-arrow {
      display: none; } }
  .m38-arrow:before {
    display: none; }

.m39 {
  width: 100%;
  padding: 50px 0; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m39 {
      padding: 70px 0; } }
  .m39_wrapper {
    width: 100%;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m39_wrapper {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m39_wrapper {
        flex-direction: column;
        align-items: flex-end; } }
  .m39_left {
    width: calc(100% - 768px);
    padding-right: 70px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .m39_left {
        width: 100%;
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m39_left {
        width: 322px;
        padding-right: 120px; } }
    .m39_left:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #0d1e5a;
      position: absolute;
      top: 152px;
      left: 0; }
      @media only screen and (max-width: 767px) {
        .m39_left:before {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m39_left:before {
          width: 1px;
          height: 100%;
          top: 21px;
          left: -20px; } }
    .m39_left__top {
      width: 100%;
      margin-bottom: 35px;
      margin-top: 35px; }
      @media only screen and (max-width: 767px) {
        .m39_left__top {
          margin-top: 0;
          margin-bottom: 24px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m39_left__top {
          margin-top: 15px; } }
      .m39_left__top p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42857em;
        color: #505050; }
      .m39_left__top span {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5em;
        letter-spacing: 0.1px;
        padding-left: 30px;
        margin-top: 10px;
        position: relative; }
        @media only screen and (max-width: 767px) {
          .m39_left__top span {
            display: none; } }
        .m39_left__top span:before {
          content: '';
          width: 11px;
          height: 11px;
          border-radius: 100%;
          background-color: #ffca00;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%); }
    @media only screen and (max-width: 767px) {
      .m39_left__bottom {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m39_left__bottom {
        display: none; } }
  .m39_right {
    width: 768px; }
    @media only screen and (max-width: 767px) {
      .m39_right {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m39_right {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m39_right img {
        width: 100%; } }

.m40 {
  width: 100%;
  padding: 80px 0 150px; }
  @media only screen and (max-width: 767px) {
    .m40 {
      padding: 50px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m40 {
      padding: 50px 0 120px; } }
  .m40_wrapper {
    width: 100%;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m40_wrapper {
        flex-direction: column-reverse; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m40_wrapper {
        flex-direction: column-reverse; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m40_wrapper {
        flex-direction: column-reverse; } }
  .m40_left {
    width: calc(100% - 840px);
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .m40_left {
        width: 100%;
        flex-direction: column-reverse;
        padding-right: 0;
        margin-top: 25px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m40_left {
        width: 100%;
        flex-direction: row-reverse;
        padding-right: 0;
        margin-top: 25px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m40_left {
        width: 100%;
        flex-direction: row-reverse;
        padding-right: 0;
        margin-top: 25px; } }
    .m40_left p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42857em;
      color: #505050; }
    .m40_left__image {
      width: 100%;
      margin-bottom: 35px; }
  .m40_right {
    width: 840px; }
    @media only screen and (max-width: 767px) {
      .m40_right {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m40_right {
        width: 100%; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m40_right {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m40_right img {
        width: 100%; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m40_right img {
        width: 100%; } }

.m41 {
  width: 100%; }
  .m41 .page_banner__thumb {
    width: 100%;
    height: 470px;
    background-size: cover;
    background-position: center;
    background-color: #2a6ebb; }
    @media only screen and (max-width: 767px) {
      .m41 .page_banner__thumb {
        height: 230px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m41 .page_banner__thumb {
        height: 320px; } }
  .m41 .page_banner__bottom {
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 767px) {
      .m41 .page_banner__bottom {
        flex-direction: column-reverse; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m41 .page_banner__bottom {
        flex-direction: column-reverse; } }
  .m41 .page_banner__left {
    width: calc(100% - 770px);
    padding-right: 16px; }
    @media only screen and (max-width: 767px) {
      .m41 .page_banner__left {
        width: 100%;
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m41 .page_banner__left {
        width: 100%;
        padding-right: 0; } }
  .m41 .page_banner__right {
    width: 770px; }
    @media only screen and (max-width: 767px) {
      .m41 .page_banner__right {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m41 .page_banner__right {
        width: 100%; } }
  .m41 .page_banner__content {
    margin-top: 150px;
    margin-bottom: 0;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .m41 .page_banner__content {
        flex-direction: column;
        margin-top: 100px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m41 .page_banner__content {
        flex-direction: column;
        margin-top: 120px; } }
    .m41 .page_banner__content h1 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 48px;
      line-height: 1.29167em;
      letter-spacing: -0.1px;
      color: #1b1b1b;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        .m41 .page_banner__content h1 {
          font-size: 36px;
          line-height: 1.05556em;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m41 .page_banner__content h1 {
          font-size: 40px;
          line-height: 1.55em;
          margin-bottom: 20px; } }
    .m41 .page_banner__content a {
      font-weight: inherit;
      color: #2a6ebb;
      position: relative; }
      .m41 .page_banner__content a:before {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #2a6ebb;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m41 .page_banner__content a:hover:before {
        width: 100%; }
    .m41 .page_banner__content p {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.7em;
      color: #000000;
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .m41 .page_banner__content p {
          font-size: 18px;
          line-height: 1.66667em; } }

.m42 {
  width: 100%; }
  .m42_wrapper {
    max-width: 770px;
    width: 100%;
    margin-left: auto; }

body.single .m42_wrapper {
  max-width: 892px;
  padding-right: 205px; }
  @media only screen and (max-width: 767px) {
    body.single .m42_wrapper {
      padding-right: 0; } }

.m43 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 35px;
  margin-bottom: 110px; }
  @media only screen and (max-width: 767px) {
    .m43 {
      margin-bottom: 90px; } }
  .m43_wrapper {
    max-width: 770px;
    width: 100%;
    margin-left: auto; }
  .m43 p {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 500;
    color: #000000; }
  .m43__right {
    width: calc(100% - 450px); }
    @media only screen and (max-width: 767px) {
      .m43__right {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m43__right {
        width: 80%; } }
    .m43__right.m43-slider {
      margin-right: -50%;
      width: 100vw !important;
      position: relative;
      margin-left: -50%;
      left: 50%;
      max-width: none;
      padding-bottom: 24px; }
    .m43__right .slick-slide {
      width: 760px;
      outline: 0;
      margin-right: 15px; }
      @media only screen and (max-width: 767px) {
        .m43__right .slick-slide {
          width: 320px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m43__right .slick-slide {
          width: 500px;
          margin-right: 23px; } }
    .m43__right img {
      width: 100%;
      height: 480px;
      object-fit: cover; }
      @media only screen and (max-width: 767px) {
        .m43__right img {
          height: 190px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m43__right img {
          height: 295px; } }
    .m43__right__bottom {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5em;
      color: #747474;
      margin-top: 15px;
      justify-content: space-between; }
      .m43__right__bottom span {
        font-weight: 500;
        white-space: nowrap;
        margin-left: 15px;
        color: #0d1e5a; }
    .m43__right .slick-prev,
    .m43__right .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      bottom: 57px;
      z-index: 2;
      transform: none;
      top: unset;
      background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m43__right .slick-prev:before,
      .m43__right .slick-next:before {
        display: none; }
      @media only screen and (max-width: 767px) {
        .m43__right .slick-prev,
        .m43__right .slick-next {
          display: none !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m43__right .slick-prev,
        .m43__right .slick-next {
          display: none !important; } }
    .m43__right .slick-dotted.slick-slider {
      margin-bottom: 0; }
    .m43__right .slick-prev {
      left: -92px;
      transform: rotate(180deg); }
      .m43__right .slick-prev:hover {
        transform: rotate(180deg) scale(1.1); }
    .m43__right .slick-next {
      left: -48px; }
      .m43__right .slick-next:hover {
        transform: scale(1.1); }
    .m43__right .slick-disabled {
      opacity: 0.3; }

.single-events .m43 {
  margin-top: 120px; }
  @media only screen and (max-width: 767px) {
    .single-events .m43 {
      margin-top: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .single-events .m43 {
      margin-top: 80px; } }

.single-events .m43__right.m43-slider {
  padding-bottom: 60px; }
  @media only screen and (max-width: 767px) {
    .single-events .m43__right.m43-slider {
      padding-bottom: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .single-events .m43__right.m43-slider {
      padding-bottom: 0; } }

.single-events .m43_wrapper {
  max-width: 890px; }

.single-events .slick-prev,
.single-events .slick-next {
  bottom: 0; }

.single-events .slick-prev {
  left: 0; }

.single-events .slick-next {
  left: 40px; }

.m44 {
  margin-bottom: 150px; }
  @media only screen and (max-width: 767px) {
    .m44 {
      margin-bottom: 120px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m44 {
      margin-bottom: 100px; } }
  .m44 h2 {
    margin-top: 98px;
    font-family: 'Noah Head';
    font-weight: bold;
    font-size: 26px;
    line-height: 1.46154em;
    margin-bottom: 17px; }
    @media only screen and (max-width: 767px) {
      .m44 h2 {
        margin-top: 18px;
        font-size: 24px;
        line-height: 1.41667em;
        margin-bottom: 17px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m44 h2 {
        margin-top: 21px;
        margin-bottom: 22px; } }
    .m44 h2:first-child {
      margin-top: 150px; }
      @media only screen and (max-width: 767px) {
        .m44 h2:first-child {
          margin-top: 120px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m44 h2:first-child {
          margin-top: 100px; } }
  .m44 h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 1.7em; }
  .m44 p {
    font-family: 'Noah Text';
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7em;
    margin-bottom: 21px; }
    .m44 p:last-child {
      margin-bottom: 0; }
  .m44 a {
    font-weight: bold;
    color: #2a6ebb; }
    .m44 a:hover {
      color: #0d1e5a; }

.m45 {
  width: 100%;
  margin-top: -46px;
  padding: 0;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  @media only screen and (max-width: 767px) {
    .m45 {
      padding: 0;
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m45 {
      padding: 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m45 {
      padding: 120px 0; } }

.therapy_slider {
  margin-top: 73px; }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .therapy_slider {
      margin-top: 50px; } }

.m51_slider_nav {
  max-width: 1440px;
  margin: 0 auto; }
  .m51_slider_nav .slick-list {
    margin: 0 -8px; }
  .m51_slider_nav .m51_slider_nav_item__thumb:focus {
    outline: 0; }
  .m51_slider_nav .slick-prev,
  .m51_slider_nav .slick-next {
    width: 32px;
    height: 32px;
    border: 1px solid #2a6ebb;
    border-radius: 32px;
    bottom: -50px;
    z-index: 2;
    transform: none;
    top: unset;
    background: url("./images/icon-arrow-right-blue.svg") no-repeat center center;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .m51_slider_nav .slick-prev:before,
    .m51_slider_nav .slick-next:before {
      display: none; }
  .m51_slider_nav .slick-prev {
    left: 16px;
    transform: rotate(180deg); }
    @media only screen and (max-width: 767px) {
      .m51_slider_nav .slick-prev {
        left: 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m51_slider_nav .slick-prev {
        left: 32px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m51_slider_nav .slick-prev {
        left: 32px; } }
  .m51_slider_nav .slick-next {
    left: 59px; }
    @media only screen and (max-width: 767px) {
      .m51_slider_nav .slick-next {
        left: 59px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m51_slider_nav .slick-next {
        left: 75px; } }
    @media only screen and (min-width: 991px) and (max-width: 1199px) {
      .m51_slider_nav .slick-next {
        left: 75px; } }
    .m51_slider_nav .slick-next:hover {
      transform: scale(1.1); }
  .m51_slider_nav .slick-disabled {
    opacity: 0.3; }

.m46 {
  padding: 150px; }
  @media only screen and (max-width: 767px) {
    .m46 {
      padding: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m46 {
      padding: 120px; } }
  .m46 h1 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    line-height: 1.35em;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .m46 h1 {
        font-size: 30px;
        line-height: 1.4em;
        letter-spacing: -0.1px;
        margin-bottom: 32px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m46 h1 {
        font-size: 32px;
        line-height: 1.3125em;
        margin-bottom: 32px; } }
  .m46 p {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 35px; }
    @media only screen and (max-width: 767px) {
      .m46 p {
        letter-spacing: 2.5px;
        margin-bottom: 57px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m46 p {
        letter-spacing: 5px;
        margin-bottom: 26px; } }
  .m46 .block-of-links h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.45455em;
    margin-top: 87px;
    margin-bottom: 24px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m46 .block-of-links h3 {
        margin-top: 32px;
        margin-bottom: 24px; } }
  .m46 .block-of-links span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px; }
  .m46 .block-of-links .link_with_arrow {
    font-weight: 800;
    font-size: 20px;
    line-height: 2.1875em;
    letter-spacing: 0.3px;
    color: #2a6ebb;
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m46 .block-of-links .link_with_arrow {
        margin-bottom: 8px;
        line-height: 1.4em; } }
    .m46 .block-of-links .link_with_arrow:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      margin-left: 0;
      background: #2a6ebb no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
      margin-right: 16px; }
    @media only screen and (max-width: 767px) {
      .m46 .block-of-links .link_with_arrow {
        font-size: 15px;
        line-height: 2.2em; } }
    .m46 .block-of-links .link_with_arrow:hover {
      color: #2a6ebb; }
      .m46 .block-of-links .link_with_arrow:hover:after {
        margin-left: 16px; }
    .m46 .block-of-links .link_with_arrow:after {
      content: '';
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      margin-left: 12px;
      background: url("./images/icon-arrow-right.svg") no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m46 .block-of-links .link_with_arrow:after {
          height: 16px; } }

.m47 h3 {
  font-family: 'Noah Head';
  font-weight: 800;
  font-size: 40px;
  color: #1b1b1b;
  line-height: 1.35em;
  letter-spacing: -1px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .m47 h3 {
      font-size: 30px;
      line-height: 1.4em; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m47 h3 {
      font-size: 27px;
      line-height: 1.55556em; } }

.m47 ul li {
  font-weight: 500; }

.m47 p {
  font-weight: 500; }
  .m47 p.full {
    font-weight: normal;
    font-size: 24px;
    line-height: 1.66667em;
    letter-spacing: 0.5px;
    padding-right: 100px;
    margin-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .m47 p.full {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.45455em;
        letter-spacing: 0.25px;
        padding-right: 0;
        margin-bottom: 56px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m47 p.full {
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 56px; } }

.m47 ul {
  margin-bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .m47 ul {
      margin-bottom: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m47 ul {
      margin-bottom: 30px; } }
  .m47 ul:last-child {
    margin-bottom: 0; }

.m47 iframe {
  width: 100%;
  height: fit-content; }

.m48 {
  margin-top: 105px; }
  @media only screen and (max-width: 767px) {
    .m48 {
      margin-top: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m48 {
      margin-top: 40px; } }
  .m48 h4 {
    font-weight: 800;
    font-size: 22px;
    line-height: 1.45455em;
    letter-spacing: 0.05px;
    color: #1b1b1b;
    margin-bottom: 24px; }
  .m48 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #1b1b1b;
    margin-bottom: 24px; }
  .m48 .contact-panel {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 40px;
    margin-bottom: 40px;
    display: none; }
  .m48 span {
    display: block; }
    .m48 span.contact-label {
      margin-top: 9px; }
      @media only screen and (max-width: 767px) {
        .m48 span.contact-label {
          margin-top: 7px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m48 span.contact-label {
          margin-top: 7px; } }
  .m48 .link_with_arrow {
    font-weight: 500;
    font-size: 16px;
    line-height: 2.125em;
    color: #2a6ebb; }
    @media only screen and (max-width: 767px) {
      .m48 .link_with_arrow {
        font-size: 15px;
        line-height: 2.125em; } }
    .m48 .link_with_arrow:hover {
      color: #2a6ebb; }
      .m48 .link_with_arrow:hover:after {
        transform: rotate(45deg); }
    .m48 .link_with_arrow:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 6px;
      background: url("./images/icon-arrow-top-right.svg") no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .m48 .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px; }
    .m48 .btn_link_with_arrow:hover:before {
      margin-right: 12px; }
    .m48 .btn_link_with_arrow:before {
      transform: rotate(90deg); }
  .m48 #m54-content:last-child {
    border-bottom: none; }
  .m48 #loadMore {
    cursor: pointer; }

.m49 {
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m49 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m49 {
      padding: 120px 0; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m49 {
      padding: 120px 0; } }
  .m49 h1 {
    font-family: 'Noah Head';
    font-weight: 700;
    font-size: 48px;
    line-height: 1.29167em;
    letter-spacing: -0.1px;
    color: #1b1b1b;
    margin-bottom: 10px; }
    @media only screen and (max-width: 767px) {
      .m49 h1 {
        font-size: 36px;
        line-height: 1.05556em;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m49 h1 {
        font-size: 40px;
        line-height: 1.55em;
        margin-bottom: 20px; } }
  .m49 p {
    font-weight: normal;
    font-size: 24px;
    line-height: 1.33333em;
    letter-spacing: 0.417px;
    color: #000; }
    @media only screen and (max-width: 767px) {
      .m49 p {
        font-size: 22px;
        line-height: 1.45455em; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m49 p {
        line-height: 1.58333em; } }
    .m49 p:first-child {
      margin-bottom: 70px; }
      @media only screen and (max-width: 767px) {
        .m49 p:first-child {
          margin-bottom: 60px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m49 p:first-child {
          margin-bottom: 100px; } }

.m50 {
  background-color: #f1f1f1;
  padding: 150px 0; }
  @media only screen and (max-width: 767px) {
    .m50 {
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m50 {
      padding: 120px 0; } }
  .m50 h3 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 40px;
    color: #1b1b1b;
    line-height: 1.35em;
    letter-spacing: -1px;
    margin-bottom: 30px;
    max-width: 866px; }
    @media only screen and (max-width: 767px) {
      .m50 h3 {
        font-size: 30px;
        line-height: 1.4em; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m50 h3 {
        font-size: 27px;
        line-height: 1.55556em; } }
  .m50 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #1b1b1b;
    margin-bottom: 24px; }
  .m50 .publication-panel {
    border-bottom: 1px solid #dcdcdc;
    padding: 32px 0;
    display: none; }
    .m50 .publication-panel .item {
      display: inline-block; }
      .m50 .publication-panel .item.titel {
        max-width: 470px;
        width: 100%; }
        @media only screen and (max-width: 767px) {
          .m50 .publication-panel .item.titel {
            max-width: 100%; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m50 .publication-panel .item.titel {
            max-width: 100%; } }
        .m50 .publication-panel .item.titel a {
          color: #2a6ebb;
          font-weight: 500; }
      .m50 .publication-panel .item.autoren {
        float: right; }
      .m50 .publication-panel .item.jahr {
        float: right; }
      .m50 .publication-panel .item.forschungspartner {
        float: right; }
      .m50 .publication-panel .item div {
        display: inline-block; }
      .m50 .publication-panel .item.item-group {
        float: right; }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m50 .publication-panel .item.item-group {
            float: initial;
            margin-top: 16px; } }
        @media only screen and (max-width: 767px) {
          .m50 .publication-panel .item.item-group {
            float: initial;
            margin-top: 16px; } }
        .m50 .publication-panel .item.item-group .autoren {
          width: 180px; }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m50 .publication-panel .item.item-group .autoren {
              width: 100%; } }
          @media only screen and (max-width: 767px) {
            .m50 .publication-panel .item.item-group .autoren {
              width: 100%; } }
        .m50 .publication-panel .item.item-group .jahr {
          width: 80px; }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m50 .publication-panel .item.item-group .jahr {
              width: initial; } }
          @media only screen and (max-width: 767px) {
            .m50 .publication-panel .item.item-group .jahr {
              width: initial; } }
          .m50 .publication-panel .item.item-group .jahr span {
            display: none; }
            @media only screen and (min-width: 768px) and (max-width: 990px) {
              .m50 .publication-panel .item.item-group .jahr span {
                display: initial; } }
            @media only screen and (max-width: 767px) {
              .m50 .publication-panel .item.item-group .jahr span {
                display: initial; } }
        .m50 .publication-panel .item.item-group .forschungspartner {
          width: 180px;
          padding-right: 50px; }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m50 .publication-panel .item.item-group .forschungspartner {
              width: initial;
              padding: 0; } }
          @media only screen and (max-width: 767px) {
            .m50 .publication-panel .item.item-group .forschungspartner {
              width: initial;
              padding: 0; } }
    .m50 .publication-panel.publication-head {
      display: block;
      border: 0;
      padding: 30px 0 0;
      font-weight: 500; }
      @media only screen and (max-width: 767px) {
        .m50 .publication-panel.publication-head {
          display: none !important; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m50 .publication-panel.publication-head {
          display: none !important; } }
  .m50 span {
    display: block; }
    .m50 span.contact-label {
      margin-top: 9px; }
      @media only screen and (max-width: 767px) {
        .m50 span.contact-label {
          margin-top: 7px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m50 span.contact-label {
          margin-top: 7px; } }
  .m50 .link_with_arrow {
    font-weight: 500;
    font-size: 16px;
    line-height: 2.125em;
    color: #2a6ebb;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .m50 .link_with_arrow {
        font-size: 15px;
        line-height: 2.125em; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m50 .link_with_arrow {
        line-height: 24px; } }
    .m50 .link_with_arrow:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 36px;
      background: url("./images/icon-arrow-top-right.svg") no-repeat center center;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .m50 .btn_link_with_arrow {
    display: flex;
    justify-content: center;
    margin-top: 35px; }
    .m50 .btn_link_with_arrow:hover:before {
      margin-right: 12px; }
    .m50 .btn_link_with_arrow:before {
      transform: rotate(90deg); }
  .m50 #m54-content:last-child {
    border-bottom: none; }
  .m50 .load-more {
    cursor: pointer; }

.m51 {
  width: 100%;
  padding: 150px 0px;
  background: #f5f5f5; }
  @media only screen and (max-width: 767px) {
    .m51 {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m51 {
      padding: 120px 0px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .m51 {
      padding: 120px 0px; } }
  .m51__inner {
    width: 100%;
    display: flex; }
  .m51__left {
    width: 768px;
    padding-left: 40px; }
    @media only screen and (max-width: 767px) {
      .m51__left {
        width: 100%;
        padding-left: 0;
        padding-top: 140px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m51__left {
        width: 100%;
        padding-left: 0;
        padding-top: 50px; } }
  .m51__right {
    width: calc(100% - 768px); }
    @media only screen and (max-width: 767px) {
      .m51__right {
        width: 100%;
        margin-left: -100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m51__right {
        width: 100%;
        margin-left: -100%; } }
    .m51__right ul {
      float: right;
      width: 280px;
      padding: 32px 24px 40px 24px;
      background-color: #f1f1f1;
      position: -webkit-sticky;
      position: sticky;
      top: 130px; }
      @media only screen and (max-width: 767px) {
        .m51__right ul {
          width: calc(100% + 30px);
          margin-right: -16px;
          display: flex;
          padding: 0px 16px;
          overflow-x: scroll;
          z-index: 4;
          top: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m51__right ul {
          width: 100vw;
          margin-right: -42px;
          display: flex;
          padding: 0px 42px;
          overflow-x: scroll;
          z-index: 4;
          top: 0; } }
      .m51__right ul:after {
        content: '';
        background-color: #f1f1f1;
        height: 100%;
        width: 100vw !important;
        position: absolute;
        left: 100%;
        max-width: none;
        z-index: 3;
        top: 0; }
        @media only screen and (max-width: 767px) {
          .m51__right ul:after {
            display: none; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m51__right ul:after {
            display: none; } }
      .m51__right ul li {
        width: 100%;
        display: flex;
        position: relative;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33333em;
        color: #2a6ebb; }
        @media only screen and (max-width: 767px) {
          .m51__right ul li {
            margin-bottom: 0;
            padding: 30px 0 37px 0;
            margin-right: 48px; } }
        @media only screen and (min-width: 768px) and (max-width: 990px) {
          .m51__right ul li {
            margin-bottom: 0;
            padding: 30px 0 37px 0;
            margin-right: 48px; } }
        .m51__right ul li:last-child {
          margin-bottom: 0; }
        .m51__right ul li:hover {
          color: #0d1e5a; }
        .m51__right ul li:before {
          content: '';
          width: 8px;
          height: 100%;
          background-color: #ffca00;
          position: absolute;
          left: -27px;
          top: 0;
          opacity: 0;
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          @media only screen and (max-width: 767px) {
            .m51__right ul li:before {
              height: 8px;
              width: 100%;
              top: unset;
              bottom: -4px;
              left: 0; } }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m51__right ul li:before {
              height: 8px;
              width: 100%;
              top: unset;
              bottom: -4px;
              left: 0; } }
        .m51__right ul li.is--active {
          color: #000000; }
          .m51__right ul li.is--active:before {
            opacity: 1; }
        .m51__right ul li a {
          color: inherit;
          transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
          @media only screen and (max-width: 767px) {
            .m51__right ul li a {
              white-space: nowrap; } }
          @media only screen and (min-width: 768px) and (max-width: 990px) {
            .m51__right ul li a {
              white-space: nowrap; } }
  .m51 .faq-category {
    margin-bottom: 150px; }
  .m51-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 78px; }
    @media only screen and (max-width: 767px) {
      .m51-head {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .m51-head {
        flex-direction: column; } }
    .m51-head__left h2 {
      font-family: 'Noah Head';
      font-weight: 800;
      font-size: 40px;
      color: #1b1b1b;
      line-height: 1.35em;
      letter-spacing: -1px;
      margin-top: 7px; }
      @media only screen and (max-width: 767px) {
        .m51-head__left h2 {
          font-size: 27px;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m51-head__left h2 {
          font-size: 32px;
          margin-bottom: 20px; } }
    .m51-head__left span {
      font-size: 12px;
      line-height: 1.33333em;
      font-weight: 500;
      color: #747474;
      letter-spacing: 1.5px;
      text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .m51 .accordion {
      margin-bottom: 75px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .m51 .accordion {
      margin-bottom: 75px; } }
  .m51 .accordion h2 {
    font-family: 'Noah Head';
    font-weight: 800;
    font-size: 26px;
    line-height: 1.46154em;
    color: #000;
    padding-bottom: 26px;
    border-bottom: 1px solid #dcdcdc; }
    @media only screen and (max-width: 767px) {
      .m51 .accordion h2 {
        font-size: 20px; } }
  .m51 .accordion .accordion-section-title {
    display: block;
    width: 100%;
    font-weight: 800;
    font-size: 22px;
    line-height: 1.63636em;
    font-weight: bold;
    color: #1b1b1b;
    padding: 27px 0;
    border-bottom: 1px solid #dcdcdc;
    background: url("./images/icon-down-arrow.svg") no-repeat right;
    background-size: 22px;
    padding-right: 40px;
    padding-right: 40px; }
    @media only screen and (max-width: 767px) {
      .m51 .accordion .accordion-section-title {
        font-size: 18px;
        line-height: 1.3em;
        padding: 25px 0;
        padding-right: 40px;
        background: url("./images/icon-down-arrow.svg") no-repeat right 28px;
        background-size: 22px; } }
    .m51 .accordion .accordion-section-title:last-child {
      border-bottom: 0 !important; }
    .m51 .accordion .accordion-section-title.active {
      font-weight: 500;
      background: url("./images/icon-up-arrow.svg") no-repeat right;
      background-size: 22px;
      border-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .m51 .accordion .accordion-section-title.active {
          background: url("./images/icon-up-arrow.svg") no-repeat right 28px;
          background-size: 22px; } }
  .m51 .accordion .accordion-section-content {
    padding-bottom: 28px;
    display: none;
    border-bottom: 1px solid #dcdcdc; }
    .m51 .accordion .accordion-section-content:first-child {
      display: block; }
    .m51 .accordion .accordion-section-content:last-child {
      border-bottom: 0; }
    .m51 .accordion .accordion-section-content p {
      font-weight: 500;
      color: #1b1b1b;
      font-size: 16px;
      line-height: 1.5em; }
      @media only screen and (max-width: 767px) {
        .m51 .accordion .accordion-section-content p {
          font-family: 'Noah Text'; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .m51 .accordion .accordion-section-content p {
          font-size: 18px; } }
      .m51 .accordion .accordion-section-content p a {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5em;
        color: #2a6ebb; }
        @media only screen and (max-width: 767px) {
          .m51 .accordion .accordion-section-content p a {
            font-family: 'Noah Text';
            font-weight: 800; } }
  .m51 p.excerpt {
    max-width: 866px;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.66667em;
    letter-spacing: 0.05px;
    color: #000;
    margin-top: 24px; }
    @media only screen and (max-width: 767px) {
      .m51 p.excerpt {
        font-size: 22px;
        line-height: 1.45455em; } }
  .m51 .btn_link_with_arrow {
    margin-top: 20px; }
    .m51 .btn_link_with_arrow:before {
      transform: rotate(90deg); }

@media only screen and (max-width: 767px) {
  .m82 .row {
    margin-left: 0;
    margin-right: 0; } }

.m52 iframe {
  height: 800px; }
  @media (max-width: 700px) {
    .m52 iframe {
      height: 1000px; } }
  .m52 iframe.iframe-content {
    height: 2000px; }
    @media (max-width: 700px) {
      .m52 iframe.iframe-content {
        height: 2600px; } }
  .m52 iframe.iframe-content2 {
    height: 1300px; }
    @media (max-width: 700px) {
      .m52 iframe.iframe-content2 {
        height: 1500px; } }
