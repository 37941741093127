.m42 {
  width: 100%;
  &_wrapper {
    max-width: 770px;
    width: 100%;
    margin-left: auto;
  }
}
body.single {
  .m42_wrapper {
    max-width: 892px;
    padding-right: 205px;
    @include mobile {
      padding-right: 0;
    }
  }
}
