.m25 {
  width: 100%;
  background-color: #f1f1f1;
  padding: $vs-d 0;
  @include mobile {
    padding: $vs-m 0;
  }
  @include tablet {
    padding: $vs-t 0;
  }
  &__content {
    width: 100%;
    @include mobile {
      margin-bottom: 50px;
    }
    @include tablet {
      margin-bottom: 50px;
    }
    h3 {
      font-family: 'Noah Head';
      font-size: 26px;
      line-height: em(38, 26);
      font-weight: 500;
      color: #000;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      line-height: em(24, 16);
      font-weight: 400;
      color: #000;
      padding-right: 220px;
      @include mobile {
        padding-right: 0;
      }
      @include tablet {
        padding-right: 156px;
      }
    }
  }
  &__list {
    width: 100%;
    li {
      width: 100%;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: em(24, 16);
        font-weight: 500;
        color: #2a6ebb;
        span {
          // border-bottom: 1px solid transparent;
          transition: $transition1;
          @include mobile {
            padding-right: 90px;
          }
        }
        &:hover {
          span {
            text-decoration: underline;
            // border-bottom-color: #2a6ebb;
          }
        }
      }
      p {
        font-size: 16px;
        line-height: em(24, 16);
        font-weight: 500;
        color: #000;
        margin-top: 5px;
      }
    }
    img {
      width: 28px;
      object-fit: contain;
      margin-right: 16px;
      margin-top: 6px;
    }
    span {
      &:nth-of-type(2) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
