.m12 {
  width: 100%;
  padding: $vs-d 0;
  &.gray_background {
    background: #F1F1F1;
  }
  @include mobile {
    padding: $vs-m 0;
    overflow: hidden;
  }
  @include tablet {
    padding: $vs-t 0;
    overflow: hidden;
  }
  &__inner {
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
  }
  &__left {
    width: 485px;
    min-width: 485px;
    padding-right: 35px;
    margin-top: 40px;
    @include mobile {
      width: 100%;
      padding-right: 0;
      margin-top: 35px;
      min-width: 100%;
    }
    @include tablet {
      width: 80%;
      padding-right: 0;
      margin-top: 35px;
      min-width: 80%;
    }
    a {
      font-weight: 800;
      font-size: 15px;
      line-height: em(16, 15);
      letter-spacing: 1.33px;
      color: #2a6ebb;
      text-transform: uppercase;
      border-bottom: 2px solid #2a6ebb;
      margin-top: 50px;
      display: inline-block;
      transition: $transition1;
      &:hover {
        color: #0d1e5a;
        border-color: #0d1e5a;
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: em(24, 16);
      color: #000;
      &:not(.excerpt) {
        padding-right: 10px;
      }
      &.excerpt {
        font-weight: normal;
        font-size: 24px;
        line-height: em(40, 24);
        letter-spacing: 0.5px;
        margin-bottom: 40px;
        @include mobile {
          font-size: 22px;
          line-height: em(32, 22);
        }
      }
    }
  }
  &__right {
    width: calc(100% - 450px);
    margin-top: 40px;
    @include mobile {
      width: 100%;
      margin-top: 32px;
    }
    @include tablet {
      width: 80%;
      margin-top: 55px;
    }
    &.m12-slider {
      margin-right: -50%;
      width: 100vw !important;
      position: relative;
      margin-left: -50%;
      left: 50%;
      max-width: none;
      padding-bottom: 56px;
      @include mobile {
        padding-bottom: 0;
      }
      @include tablet {
        padding-bottom: 0;
      }
    }
    .slick-slide {
      width: 700px;
      outline: 0;
      margin-right: 15px;
      opacity: 0.4;
      &.slick-current {
        opacity: 1;
      }
      @include mobile {
        width: 320px;
      }
      @include tablet {
        width: 500px;
        margin-right: 23px;
      }
    }
    img {
      width: 100%;
      height: 480px;
      object-fit: cover;
      @include mobile {
        height: 190px;
      }
      @include tablet {
        height: 295px;
      }
    }
    &__bottom {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      line-height: em(18, 12);
      color: #000000;
      margin-top: 15px;
      span {
        color: #0d1e5a;
        white-space: nowrap;
        margin-right: 15px;
      }
    }
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;
      border: 1px solid #2a6ebb;
      border-radius: 32px;
      bottom: 0;
      z-index: 2;
      transform: none;
      top: unset;
      background: url('./images/icon-arrow-right-blue.svg') no-repeat center
        center;
      transition: $transition1;
      &:before {
        display: none;
      }
      @include mobile {
        display: none !important;
      }
      @include tablet {
        display: none !important;
      }
    }
    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }
    .slick-prev {
      left: 0;
      transform: rotate(180deg);
      &:hover {
        transform: rotate(180deg) scale(1.1);
      }
    }
    .slick-next {
      left: 40px;

      &:hover {
        transform: scale(1.1);
      }
    }
    .slick-disabled {
      opacity: 0.3;
    }
  }
}
